import {
  BLM_METRICS_DATABASE_NAME,
  FACTSHEET_RISK_METRICS_DATABASE_NAME,
  METRICS_DATABASE_NAME,
  MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME,
} from '@/utils/metrics';
import { Region } from '@/constants/Region';
import { StyleConstants } from '@/constants/StyleConstants';
import { Currency } from '@/constants/Currency';
import { AssetClassConstants } from '@/constants/AssetClassConstants';
import { FactorConstants } from '@/constants/FactorConstants';
import { ReturnTypeConstants } from '@/constants/ReturnTypeConstants';
import { ReturnCategoryConstants } from '@/constants/ReturnCategoryConstants';
import { TimeSeriesEntityTypeConstants } from '@/constants/TimeSeriesEntityTypeConstants';
import { TrackTypeConstants } from '@/constants/TrackTypeConstants';
import { StrategyBenchmarkDTO, PortfolioBenchmarkDTO } from '@/api-v2/web/strategies';

export interface ITrack {
  monthlyRtnDates: string[];
  monthlyRtn: number[];
  monthlyLnRtn: number[];

  yearlyRtnDates: string[];
  yearlyRtn: number[];
  yearlyLnRtn: number[];

  timeseriesDates: string[];
  timeseriesEquityVariation: number[];
  timeseriesRollingDrawDowns: number[];
}

export interface IStrategyPrice {
  date: string;
  price: number;
}

export interface IBenchmark {
  code: string;
  name: string;
}

export interface IPrivateFundBenchmarks {
  primary_prospectus_benchmarks?: IPrivateFundBenchmark[];
  secondary_prospectus_benchmarks?: IPrivateFundBenchmark[];
}

export interface IPrivateFundBenchmark {
  id: string;
  name: string;
  weight: number;
}

/**
 * Used by Analytics Store and old Portfolio Tree APIs (deprecated).
 */
export interface IStrategy {
  id: string;
  code: string;
  type: TimeSeriesEntityTypeConstants;
  shortName: string;
  assetClass: AssetClassConstants;
  currency: Currency;
  region: Region;
  factor: FactorConstants;
  longName: string;
  returnType: ReturnTypeConstants | null;
  returnCategory: ReturnCategoryConstants | null;
  style: StyleConstants | null;
  volTarget: string;
  providerId: string | null;
  provider: string;
  indexSource: string;
  isSuspended: boolean;
  trackType: TrackTypeConstants;

  /**
   * Inception date is what we call the 'live date' on the platform
   */
  inceptionDate: string | null;

  /**
   * History start date is the very beginning of any data we have on an index
   */
  historyStartDate: string | null;
  /**
   * Effective history start date is the very beginning of any data we have on an index based on the fx conversion
   */
  effectiveHistoryStartDate?: string | null;

  // Thematics
  theme?: string | null;
  esg?: string | null;

  // Identifiers
  internalReference?: string | null;
  isin?: string[];
  morningStarId?: string[];
  ticker?: string[];
  ric?: string[];
  sedol?: string[];
}

export const isStrategyBenchmark = (
  item: StrategyBenchmarkDTO | PortfolioBenchmarkDTO,
): item is StrategyBenchmarkDTO => {
  return item.type === 'timeseries';
};

export interface IStrategyTrack {
  id: string;
  code: string;
  type: TimeSeriesEntityTypeConstants;
  shortName: string;
  longName: string;
  historyStartDate: string | null;
  inceptionDate: string | null;
  metrics: { [period: string]: IMetric } | null;
  tracks: { [period: string]: ITrack } | null;
}

export enum ATTRIBUTE_DATABASE_NAME {
  AssetClass = 'assetClass',
  Code = 'code',
  Currency = 'currency',
  Factor = 'factor',
  HistoryStartDate = 'historyStartDate',
  Provider = 'provider',
  IndexSource = 'indexSource',
  InceptionDate = 'inceptionDate',
  IntradayType = 'intradayType',
  ReturnCategory = 'returnCategory',
  Region = 'region',
  ReturnType = 'returnType',
  ShortName = 'shortName',
  Source = 'source',
  Style = 'style',
  Type = 'type',
  VolTarget = 'volTarget',
  Weighting = 'weighting',

  Domicile = 'domicile',
  FundLegalStructure = 'legalStructure',
  ISIN = 'isin',
  ManagerNames = 'managerNames',
  IndexFund = 'indexFund',
  UCITS = 'ucits',
  Exchanges = 'exchanges',
  Benchmarks = 'benchmarks',
  DividendDistributionFrequency = 'dividendFrequency',
  GlobalCategory = 'globalCat',
  ManagementFee = 'managementFee',
  PerformanceFee = 'performanceFee',
  ETF = 'etf',
  ETN = 'etn',
  SyntheticReplication = 'syntheticReplication',
  ProviderCompany = 'providerCompany',
  PrivateFundType = 'privateFundType',

  // User-tracks
  PrivateTrackIdentifier = 'privateTrackIdentifier',
  InternalReference = 'internalReference',

  // Thematics
  Theme = 'theme',
  ESG = 'esg',
  IsFlagship = 'isFlagship',

  // Equities
  /**
   * The actual full BBG ticker, currently used only for equities.
   */
  SEDOL = 'sedol',
  Ticker = 'ticker',
  RIC = 'ric',
  MorningstarId = 'morningstarId',

  // Stock
  Country = 'country',
  Exchange = 'exchange',
  ESGRating = 'esgRating',
  Sector = 'sector',
}

export enum ATTRIBUTE_PROPER_NAME {
  AssetClass = 'Asset Class',
  Code = 'Index Code',
  Currency = 'Currency',
  Factor = 'Factor',
  HistoryStartDate = 'History Start Date',
  Provider = 'Index Provider',
  IndexSource = 'Index Source',
  InceptionDate = 'Live Date',
  ReturnCategory = 'Net Return',
  Region = 'Region',
  ReturnType = 'Return Type',
  ShortName = 'Name',
  Source = 'Source',
  Style = 'Style',
  Type = 'Type',
  VolTarget = 'Vol Target',
  Weighting = 'Weighting',

  Domicile = 'Domicile',
  FundLegalStructure = 'Fund Legal Structure',
  ISIN = 'ISIN',
  ManagerNames = 'Tenured Manager Name',
  IndexFund = 'Index Fund',
  UCITS = 'UCITS',
  Exchanges = 'Exchange',
  Benchmarks = 'Benchmarks',
  DividendDistributionFrequency = 'Dividend Distribution Frequency',
  ShareClassType = 'Share Class Type',
  GlobalCategory = 'Fund Category',
  ManagementFee = 'Management Fee',
  PerformanceFee = 'Performance Fee',
  BroadCategoryGroup = 'Broad Category Group',
  ETF = 'ETF',
  ETN = 'ETN',
  SyntheticReplication = 'Synthetic Replication',
  ProviderCompany = 'Provider Company',
  AdvisoryCompany = 'Advisory Company',
  CustodianCompany = 'Custodian Company',
  Ticker = 'Ticker',
  SEDOL = 'SEDOL',
  Sector = 'Sector',
  IsFlagship = 'Flagship',
}

export interface RegimeMetric {
  /**
   * Name of the regime
   */
  name: string;
  noElements: number;
  dayPassed: number;
  [METRICS_DATABASE_NAME.ReturnCumulative]?: number;
  [METRICS_DATABASE_NAME.Return]?: number;
  [METRICS_DATABASE_NAME.Volatility]?: number;
  [METRICS_DATABASE_NAME.Sharpe]?: number;
  [METRICS_DATABASE_NAME.Skew]?: number;
  [METRICS_DATABASE_NAME.Kurtosis]?: number;
  [METRICS_DATABASE_NAME.Calmar]?: number;
  [METRICS_DATABASE_NAME.Omega]?: number;
  [METRICS_DATABASE_NAME.Sortino]?: number;
  [METRICS_DATABASE_NAME.Var]?: number;
  [METRICS_DATABASE_NAME.Cvar]?: number;
  [METRICS_DATABASE_NAME.Mvar]?: number;
  [METRICS_DATABASE_NAME.Var99]?: number;
  [METRICS_DATABASE_NAME.Cvar99]?: number;
  [METRICS_DATABASE_NAME.Mvar99]?: number;
  [METRICS_DATABASE_NAME.JarqueBera]?: number;
  [METRICS_DATABASE_NAME.AvgDD]?: number;
  [METRICS_DATABASE_NAME.MaxDD]?: number;
  [METRICS_DATABASE_NAME.PercentPosWeeks]?: number;
  [METRICS_DATABASE_NAME.PercentPosMonths]?: number;
  [METRICS_DATABASE_NAME.InfoRatio]?: number;
  [METRICS_DATABASE_NAME.Autocorrelation]?: number;

  [MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME.MonthlyKurtosis]?: number;
  [MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME.MonthlyReturnPA]?: number;
  [MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME.MonthlySkew]?: number;
  [MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME.MonthlyVolatility]?: number;
}

export interface IMetric {
  [METRICS_DATABASE_NAME.ReturnCumulative]?: number;
  [METRICS_DATABASE_NAME.Return]?: number;
  [METRICS_DATABASE_NAME.Volatility]?: number;
  [METRICS_DATABASE_NAME.Sharpe]?: number;
  [METRICS_DATABASE_NAME.Skew]?: number;
  [METRICS_DATABASE_NAME.Kurtosis]?: number;
  [METRICS_DATABASE_NAME.Calmar]?: number;
  [METRICS_DATABASE_NAME.Omega]?: number;
  [METRICS_DATABASE_NAME.Sortino]?: number;
  [METRICS_DATABASE_NAME.Var]?: number;
  [METRICS_DATABASE_NAME.Cvar]?: number;
  [METRICS_DATABASE_NAME.Mvar]?: number;
  [METRICS_DATABASE_NAME.Var99]?: number;
  [METRICS_DATABASE_NAME.Cvar99]?: number;
  [METRICS_DATABASE_NAME.Mvar99]?: number;
  [METRICS_DATABASE_NAME.JarqueBera]?: number;
  [METRICS_DATABASE_NAME.AvgDD]?: number;
  [METRICS_DATABASE_NAME.MaxDD]?: number;
  [METRICS_DATABASE_NAME.PercentPosWeeks]?: number;
  [METRICS_DATABASE_NAME.PercentPosMonths]?: number;
  [METRICS_DATABASE_NAME.InfoRatio]?: number;
  [METRICS_DATABASE_NAME.Autocorrelation]?: number;

  [BLM_METRICS_DATABASE_NAME.InfoRatio]?: number;
  [BLM_METRICS_DATABASE_NAME.Return]?: number;
  [BLM_METRICS_DATABASE_NAME.Sharpe]?: number;
  [BLM_METRICS_DATABASE_NAME.Volatility]?: number;

  [FACTSHEET_RISK_METRICS_DATABASE_NAME.WinLossRatio]?: number;
  [FACTSHEET_RISK_METRICS_DATABASE_NAME.WinRate]?: number;

  /**
   * For metrics UpHitRate, DownHitRate, UpsideCapture, DownsideCapture, currently it'll be list of single value, so we need to extract the value here
   */
  [FACTSHEET_RISK_METRICS_DATABASE_NAME.UpHitRate]?: number[];
  [FACTSHEET_RISK_METRICS_DATABASE_NAME.DownHitRate]?: number[];
  [FACTSHEET_RISK_METRICS_DATABASE_NAME.UpsideCapture]?: number[];
  [FACTSHEET_RISK_METRICS_DATABASE_NAME.DownsideCapture]?: number[];

  [MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME.MonthlyKurtosis]?: number;
  [MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME.MonthlyReturnPA]?: number;
  [MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME.MonthlySkew]?: number;
  [MONTHLY_RETURN_DISTRIBUTION_METRICS_DATABASE_NAME.MonthlyVolatility]?: number;

  regimeMetric?: RegimeMetric[];
}

export interface MonthlyReturnObject {
  year: number;
  [year: number]: number;
  months: {
    '01': number;
    '02': number;
    '03': number;
    '04': number;
    '05': number;
    '06': number;
    '07': number;
    '08': number;
    '09': number;
    '10': number;
    '11': number;
    '12': number;
  };
  monthsArray: number[];
}
