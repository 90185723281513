import { portfolioPages, RouteName, navPages, strategyPages } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { IUserInfoResponse } from '@/api-v2/web/user';
import {
  PORTFOLIO_CONSTRUCTION_CORRELATION,
  PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER,
  PORTFOLIO_CONSTRUCTION_PERF_ATTRIB,
  PORTFOLIO_CONSTRUCTION_RECONCILIATION,
  PORTFOLIO_CONSTRUCTION_SIMULATION,
} from '@/types/analytics/PortfolioConstruction';
import { computed, Ref } from 'vue';
import { Route } from 'vue-router';
import { PerformancePageType } from '@/constants/PerformancePageType';
import { FACTOR_DECOMPOSITION_ROLLING } from '@/types/analytics/FactorDecomposition';

export type AnalyticsRoute =
  | RouteName.PORTFOLIO_CONSTRUCTION
  | RouteName.CLUSTERING_ANALYSIS
  | RouteName.FACTOR_DECOMPOSITION_PORTFOLIO
  | RouteName.FACTOR_DECOMPOSITION_STRATEGY
  | RouteName.CONSTITUENT_RISK_PORTFOLIO
  | RouteName.CONSTITUENT_RISK_STRATEGY;

export const isAnalyticsRoute = (route: string): route is AnalyticsRoute => {
  return [
    RouteName.ANALYTICS,
    RouteName.PORTFOLIO_CONSTRUCTION,
    RouteName.CLUSTERING_ANALYSIS,
    RouteName.FACTOR_DECOMPOSITION_PORTFOLIO,
    RouteName.FACTOR_DECOMPOSITION_STRATEGY,
    RouteName.CONSTITUENT_RISK_PORTFOLIO,
    RouteName.CONSTITUENT_RISK_STRATEGY,
  ].includes(route as RouteName);
};

const routeToPermissions = {
  [RouteName.PORTFOLIO_CONSTRUCTION]: [UserPermission.PORTFOLIO],
  [RouteName.CLUSTERING_ANALYSIS]: [UserPermission.PCA],
  [RouteName.FACTOR_DECOMPOSITION_PORTFOLIO]: [UserPermission.REGRESSION],
  [RouteName.FACTOR_DECOMPOSITION_STRATEGY]: [UserPermission.REGRESSION],
  [RouteName.CONSTITUENT_RISK_PORTFOLIO]: [UserPermission.CONSTITUENT],
  [RouteName.CONSTITUENT_RISK_STRATEGY]: [UserPermission.CONSTITUENT],
};

/**
 * If `testRoute` is supplied and the user has permission to that route, this function will return `testRoute`
 *
 * If `testRoute` is not supplied, then iterate through a user's permissions and return the RouteName of the first
 * available analysis step to which the user has permission
 */
export const hasAnalyticsPermission = ({
  user,
  testRoute,
  currentRoute,
}: {
  user: IUserInfoResponse;
  testRoute?: AnalyticsRoute;
  currentRoute?: Route;
}): AnalyticsRoute | undefined => {
  if (testRoute && user.permission.some((permission) => routeToPermissions[testRoute].includes(permission))) {
    return testRoute;
  }

  if (user.permission.includes(UserPermission.PORTFOLIO)) {
    return RouteName.PORTFOLIO_CONSTRUCTION;
  }

  if (user.permission.includes(UserPermission.PCA)) {
    return RouteName.CLUSTERING_ANALYSIS;
  }

  if (user.permission.includes(UserPermission.REGRESSION)) {
    // handle Strategy cases first
    if (testRoute === RouteName.FACTOR_DECOMPOSITION_STRATEGY) {
      return RouteName.FACTOR_DECOMPOSITION_STRATEGY;
    }

    if (currentRoute && currentRoute.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY) {
      return RouteName.FACTOR_DECOMPOSITION_STRATEGY;
    }

    // otherwise is Portfolio case
    return RouteName.FACTOR_DECOMPOSITION_PORTFOLIO;
  }

  if (user.permission.includes(UserPermission.CONSTITUENT)) {
    if (currentRoute && currentRoute.name === RouteName.CONSTITUENT_RISK_STRATEGY) {
      return RouteName.CONSTITUENT_RISK_STRATEGY;
    }

    return RouteName.CONSTITUENT_RISK_PORTFOLIO;
  }
};

export default function (route: Ref<Route>) {
  const isPortfolioTreeUrl = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_CONSTRUCTION ||
      route.value.name === RouteName.FACTOR_DECOMPOSITION ||
      route.value.name === RouteName.PORTFOLIO_FACTSHEET ||
      route.value.name === RouteName.PORTFOLIO_FACTSHEET_PDF ||
      route.value.name === RouteName.TEST_PDF_PORTFOLIO ||
      route.value.name === RouteName.NO_CODE_DASHBOARD,
  );

  const onFactsheetPage = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_FACTSHEET ||
      route.value.name === RouteName.PORTFOLIO_FACTSHEET_PDF ||
      route.value.name === RouteName.STRATEGY_FACTSHEET ||
      route.value.name === RouteName.STRATEGY_FACTSHEET_PDF,
  );

  const isPortfolioFactsheet = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_FACTSHEET ||
      route.value.name === RouteName.PORTFOLIO_FACTSHEET_PDF ||
      route.value.name === RouteName.NO_CODE_DASHBOARD,
  );

  const isStrategyFactsheet = computed(
    () => route.value.name === RouteName.STRATEGY_FACTSHEET || route.value.name === RouteName.STRATEGY_FACTSHEET_PDF,
  );

  const isPortfolioConstruction = computed(() => route.value.name === RouteName.PORTFOLIO_CONSTRUCTION);

  const isPortfolioPage = computed(
    () => route.value.name !== undefined && portfolioPages.has(route.value.name as RouteName),
  );

  const isStrategyPage = computed(
    () => route.value.name !== undefined && strategyPages.has(route.value.name as RouteName),
  );

  const isNavPages = computed(() => route.value.name !== undefined && navPages.has(route.value.name as RouteName));

  const isOnFactorDecomposition = computed(
    () =>
      route.value.name === RouteName.FACTOR_DECOMPOSITION_PORTFOLIO ||
      route.value.name === RouteName.FACTOR_DECOMPOSITION_PORTFOLIO_PDF ||
      route.value.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY ||
      route.value.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY_PDF,
  );

  const isOnPortfolioFactorDecomposition = computed(
    () =>
      route.value.name === RouteName.FACTOR_DECOMPOSITION_PORTFOLIO ||
      route.value.name === RouteName.FACTOR_DECOMPOSITION_PORTFOLIO_PDF,
  );

  const isOnStrategyFactorDecomposition = computed(
    () =>
      route.value.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY ||
      route.value.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY_PDF,
  );

  const isOnFactorDecompositionRolling = computed(
    () =>
      (route.value.name === RouteName.FACTOR_DECOMPOSITION_PORTFOLIO ||
        route.value.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY) &&
      route.value.params.substep === FACTOR_DECOMPOSITION_ROLLING.path,
  );

  const isOnPca = computed(() => route.value.name === RouteName.CLUSTERING_ANALYSIS);

  const isOnSimulation = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_CONSTRUCTION &&
      route.value.params.substep === PORTFOLIO_CONSTRUCTION_SIMULATION.path,
  );
  const isOnEfficientFrontier = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_CONSTRUCTION &&
      route.value.params.substep === PORTFOLIO_CONSTRUCTION_EFFICIENT_FRONTIER.path,
  );
  const isOnPortfolioPerformanceContribution = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_CONSTRUCTION &&
      route.value.params.substep === PORTFOLIO_CONSTRUCTION_PERF_ATTRIB.path,
  );
  const isOnCorrelation = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_CONSTRUCTION &&
      route.value.params.substep === PORTFOLIO_CONSTRUCTION_CORRELATION.path,
  );

  const isOnStrategyPerformanceContribution = computed(() => route.value.name === RouteName.PERFORMANCE_ATTRIBUTION);

  const isOnConstituentRisk = computed(
    () =>
      route.value.name === RouteName.CONSTITUENT_RISK_PORTFOLIO ||
      route.value.name === RouteName.CONSTITUENT_RISK_STRATEGY,
  );

  const isOnPortfolioConstituentRisk = computed(
    () =>
      route.value.name === RouteName.CONSTITUENT_RISK_PORTFOLIO ||
      route.value.name === RouteName.CONSTITUENT_RISK_PORTFOLIO_PDF,
  );

  const isOnStrategyConstituentRisk = computed(
    () =>
      route.value.name === RouteName.CONSTITUENT_RISK_STRATEGY ||
      route.value.name === RouteName.CONSTITUENT_RISK_STRATEGY_PDF,
  );

  const isOnReconciliation = computed(
    () =>
      route.value.name === RouteName.PERFORMANCE_ATTRIBUTION &&
      route.value.params.substep === PerformancePageType.RECONCILIATION,
  );

  const isOnPortfolioConstructionReconciliation = computed(
    () =>
      route.value.name === RouteName.PORTFOLIO_CONSTRUCTION &&
      route.value.params.substep === PORTFOLIO_CONSTRUCTION_RECONCILIATION.path,
  );

  /**
   * It will return true if the user is on the Combined Module PDF page (portfolio or strategy)
   */
  const isOnCombinedModulePDF = computed(
    () => route.value.name === RouteName.TEST_PDF_PORTFOLIO || route.value.name === RouteName.TEST_PDF_STRATEGY,
  );

  const isOnStrategyCombinedModulePDF = computed(() => route.value.name === RouteName.TEST_PDF_STRATEGY);

  const isOnPosition = computed(() => route.value.name === RouteName.POSITION);

  return {
    isPortfolioTreeUrl,
    onFactsheetPage,
    isPortfolioConstruction,
    isOnFactorDecomposition,
    isOnPortfolioFactorDecomposition,
    isOnStrategyFactorDecomposition,
    isOnFactorDecompositionRolling,
    isOnPca,
    isOnPortfolioPerformanceContribution,
    isOnStrategyPerformanceContribution,
    isOnCorrelation,
    isPortfolioPage,
    isOnSimulation,
    isOnEfficientFrontier,
    isOnConstituentRisk,
    isOnStrategyConstituentRisk,
    isOnPortfolioConstituentRisk,
    isOnReconciliation,
    isNavPages,
    isStrategyPage,
    isPortfolioFactsheet,
    isStrategyFactsheet,
    isOnPortfolioConstructionReconciliation,
    isOnCombinedModulePDF,
    isOnStrategyCombinedModulePDF,
    isOnPosition,
  };
}
