// Utils Should not include a computed, just functions
import { useConstituentRiskAnalysisSteps } from '@/composables/useAnalysisSteps';
import { useConstituentRiskUtilities } from '@/composables/useConstituentRiskUtilities';
import { ExposureConstants, FilterConstants, RiskTableTabs, RiskTabs } from '@/types/analytics/ConstituentRisk';
import AnalyticsStore from '@/store/modules/AnalyticsStore';
import { computed } from 'vue';
import usePortfolioTree from '@/composables/usePortfolioTree';
import { Currency, getCurrencySymbol } from '@/constants/Currency';
import { useRiskPreferences } from '@/composables/useRiskPreferences';
import usePinned from '@/composables/usePinned';
import { findTreeComponentByStrategyCode } from './portfolioTree';
import { SettingsModule } from '@/store/modules/settings';

/**
 * Date marking the beginning of RPI data.
 * This will change as the Data Team parses past dates.
 */
export const RPI_START_DATE = '2024-03-01';

export const getSegmentLayers = () => {
  const { preferences } = useRiskPreferences();
  const { activeAnalysisSubstep } = useConstituentRiskAnalysisSteps();

  return computed(() => {
    const activeSubstep = activeAnalysisSubstep.value?.path;

    if (activeSubstep === RiskTabs.COMMODITY) {
      if (
        preferences.value.commodity.tableMetricName === RiskTableTabs.COMMODITY_DELTA ||
        preferences.value.commodity.tableMetricName === RiskTableTabs.COMMODITY_GAMMA
      ) {
        return preferences.value.commodity.tableLayers;
      }
      if (preferences.value.commodity.tableMetricName === RiskTableTabs.SPOT_VOL_LADDER) {
        return preferences.value.commodity.ladderTableLayers;
      }
    }

    if (
      activeSubstep === RiskTabs.INTEREST_RATE &&
      preferences.value['interest-rate'].tableMetricName === RiskTableTabs.DV01_TENOR
    ) {
      return preferences.value['interest-rate'].tableLayers;
    }
    if (
      (activeSubstep === RiskTabs.INTEREST_RATE &&
        preferences.value['interest-rate'].tableMetricName === RiskTableTabs.SPOT_VOL_NORMAL_LADDER) ||
      preferences.value['interest-rate'].tableMetricName === RiskTableTabs.SPOT_VOL_BS_LADDER
    ) {
      return preferences.value['interest-rate'].ladderTableLayers;
    }
    if (activeSubstep === RiskTabs.CREDIT && preferences.value.credit.tableMetricName === RiskTableTabs.CS01_TENOR) {
      return preferences.value.credit.tableLayers;
    }
    if (
      activeSubstep === RiskTabs.EQUITY &&
      preferences.value.equity.tableMetricName === RiskTableTabs.SPOT_VOL_LADDER
    ) {
      return preferences.value.equity.ladderTableLayers;
    }
    if (activeSubstep === RiskTabs.FX && preferences.value.fx.tableMetricName === RiskTableTabs.FX_DELTA) {
      return preferences.value.fx.tableLayers;
    }
    if (activeSubstep === RiskTabs.FX && preferences.value.fx.tableMetricName === RiskTableTabs.SPOT_VOL_LADDER) {
      return preferences.value.fx.ladderTableLayers;
    }
    return preferences.value.options.tableLayers;
  });
};

/**
 * Formats a large number to make it more shorter and easier to read.
 * @param value Currency value to be formatted
 */
export const formatLongNumber = (value: number, ccy: Currency = Currency.USD): string => {
  let valToUse: string = value.toString();
  const suffixes = ['', ' k', ' M', ' B', ' T'];
  let suffixNum = 0;
  let shortValue = value;
  if (value >= 1000 || value <= -1000) {
    const stringValue = Math.round(Math.abs(value)).toString();

    // Counts how many groups of three digits in the value to determine which suffix to use
    suffixNum = Math.floor((stringValue.length - 1) / 3);

    // For loop to increase the precision of the output
    for (let precision = 3; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision));
      const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');

      if (dotLessShortValue.length <= 3) {
        break;
      }
    }
  }
  if (shortValue % 1 !== 0) shortValue = parseFloat(shortValue.toFixed(AnalyticsStore.numDecimalPoints));
  valToUse = shortValue.toString() + suffixes[suffixNum];

  return `${getCurrencySymbol(ccy)} ` + valToUse;
};

/**
 * Handles the pluralization of the unit basis point based on the value.
 *
 * @param value Basis point value to be formatted
 * @param unitOnly If true, only the unit will be returned
 * @returns the appropriate basis point unit (plural or singular)
 */
export const formatBasisPoints = (value: string, unitOnly: boolean = false): string => {
  let unit = 'bps';
  const singular = ['1', '+1', '-1', '0', '', '-'];
  if (singular.includes(value)) {
    unit = 'bp';
  }
  return unitOnly ? unit : `${value} ${unit}`;
};

export const useGetHistoricalTrackColor = () => {
  const { masterPortfolioTree, portfolioName } = usePortfolioTree();
  const { colorMap } = usePinned();
  const { getTrackSegment } = useConstituentRiskUtilities();

  const trackSegment = computed(() => getTrackSegment());
  const assetClassColors = computed(() => SettingsModule.colors.assetClass || {});
  const factorColors = computed(() => SettingsModule.colors.factor || {});
  const pinColors = computed(() => SettingsModule.colors.pin || {});

  return (name: string, idx: number): string => {
    if (name === portfolioName.value || name === ExposureConstants.NET || name === 'Subtotal' || name === 'Strategy')
      return SettingsModule.portfolioColor;
    if (colorMap.value[name]) return colorMap.value[name].color;
    if (masterPortfolioTree.value) {
      const component = findTreeComponentByStrategyCode(masterPortfolioTree.value.portfolioTree, name);
      if (component && colorMap.value[component.portfolioTreeId])
        return colorMap.value[component.portfolioTreeId].color;
    }

    if (trackSegment.value === FilterConstants.ASSET_CLASS) {
      if (assetClassColors.value[name]) return assetClassColors.value[name];
    }

    if (trackSegment.value === FilterConstants.FACTOR) {
      if (factorColors.value[name]) return factorColors.value[name];
    }

    if (trackSegment.value !== FilterConstants.PORTFOLIO && trackSegment.value !== FilterConstants.STRATEGY) {
      const pins = Object.keys(pinColors.value);
      if (idx < pins.length - 1 && pinColors.value[pins[idx]]) return pinColors.value[pins[idx]];
    }

    return SettingsModule.defaultChildColor;
  };
};
