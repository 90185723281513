import { RouteName } from '@/constants/RouteName';

/**
 * Set of special auth-related routes.
 *
 * The type includes `| null | undefined` to satisfies typescript's RouteName.
 * In runtime, `.has(null)` must return `false`.
 */
export const SKIP_AUTH_ROUTES = new Set<string | null | undefined>([
  RouteName.LOGIN, // Allow admin to login without that modal
  RouteName.LOGOUT, // This will get redirected away like immediately
  RouteName.UNAUTHORIZED,
]);
