import { TableLayerConstants } from '@/types/analytics/ConstituentRisk';
import { createGlobalState } from '@vueuse/shared';
import { ref } from 'vue';
import { DEFAULT_POSITION_MODE_COLUMNS } from './useConstituentPositionTableUtilities';

export const getPositionDate = createGlobalState(() => {
  return ref<string | null>(null);
});

export const getPositionSegmentLayers = createGlobalState(() => {
  return ref<TableLayerConstants[]>([]);
});

export const getPositionCustomCols = createGlobalState(() => {
  return ref<string[]>(DEFAULT_POSITION_MODE_COLUMNS);
});
