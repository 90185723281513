import request from '@/api-v2/request';
import { ISettingRecord } from './types';

export const getColors = (): Promise<ISettingRecord[]> =>
  request({
    url: `/settings/colors`,
    method: 'get',
  }).then((o) => o.data);

export const getLiveDateHSBC = (): Promise<{ value: string }> =>
  request({
    url: `/settings/hsbc-pdf-live-date`,
    method: 'get',
  }).then((o) => o.data);

export const getRiskFreeRateTicker = (): Promise<Record<string, string>> =>
  request({
    url: `/settings/risk-free-rate-ticker`,
    method: 'get',
  }).then((o) => o.data);
