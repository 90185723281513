import { useUserQuery } from '@/composables/queries/useUserQueries';
import { computed, watch } from 'vue';
import axios from 'axios';
import { useRouteRef, useRouter } from '@/composables/useRouter';
import { RouteName } from '@/constants/RouteName';

/**
 * Get the user's authentication status
 */
export enum AuthenticationStatus {
  /**
   * Still sending request
   */
  PENDING = 'pending',

  /**
   * This session is authenticated
   */
  SUCCESS = 'success',

  /**
   * 401 error. Should redirect to login page.
   */
  UNAUTH = 'unauth',

  /**
   * 403 error. The only case this could happen is the user is blocked.
   *
   * AuthProvider will handle redirect for this case
   */
  BLOCKED = 'blocked',

  /**
   * 5xx internal server
   *
   * AuthProvider will handle redirect for this case
   */
  ERR = '5xx error',
}

/**
 * Check if the current session is authenticated.
 *
 * This depends on `useUserQuery` to check if we are authenticated.
 */
export function useIsAuthenticated() {
  const user = useUserQuery();

  /**
   * Get the query's latest error
   *
   * This will only return the `query.error.value` if `errorUpdatedAt` is larger
   * than `dataUpdatedAt`. Basically this means we want the latest's request
   * error, if any.
   */
  const latestErr = computed(() => {
    const err = user.error.value;
    if (err == null) {
      return null;
    }

    if (user.errorUpdatedAt.value > user.dataUpdatedAt.value) {
      return err;
    }

    return null;
  });

  return computed((): AuthenticationStatus => {
    // We don't know if we are authenticated if is still loading
    if (user.isLoading.value) {
      return AuthenticationStatus.PENDING;
    }

    const err = latestErr.value;
    if (err == null) {
      // We are good.
      return AuthenticationStatus.SUCCESS;
    }

    // Check for 401/403 error
    if (axios.isAxiosError(err)) {
      const status = err.response?.status;
      if (status === 401) {
        return AuthenticationStatus.UNAUTH;
      }

      // The only reason for 403 (forbidden) resp is the user is blocked.
      if (status === 403) {
        return AuthenticationStatus.BLOCKED;
      }

      // We only handle these two. Others should be generic err. Falling through
    }

    // Maybe we got a 500, or browser request got blocked.
    // Whatever just try to handle it as our fault.
    return AuthenticationStatus.ERR;
  });
}

/**
 * Call the given callback when we detect the session is unauthenticated.
 *
 * If the session is authenticated, this will call `router.replace` to HOME
 * route.
 * Basically what most login page needs to do.
 */
export function whenUnauthenticatedOrHome(cb: () => void) {
  const router = useRouter();

  const isAuthenticated = useIsAuthenticated();
  watch(
    isAuthenticated,
    (value) => {
      switch (value) {
        case AuthenticationStatus.SUCCESS:
          router.replace({
            name: RouteName.HOME,
          });
          break;
        case AuthenticationStatus.UNAUTH:
          cb();
      }
    },
    { immediate: true },
  );
}

/**
 * Redirect to unauthorized page if the user is blocked or we have trouble
 * getting the user's info.
 *
 * This should be called in all auth root implementations. Whitelabels should
 * probably handle this the same way as core.
 */
export function useBlockedRedirect() {
  const router = useRouter();
  const route = useRouteRef();
  const authenticated = useIsAuthenticated();

  watch(
    authenticated,
    () => {
      // Already in unauthorized page so skipping the unnecessary redirect
      if (route.value.name === RouteName.UNAUTHORIZED) {
        return;
      }

      switch (authenticated.value) {
        case AuthenticationStatus.BLOCKED:
        case AuthenticationStatus.ERR:
          router.push({
            name: RouteName.UNAUTHORIZED,
          });
      }
    },
    { immediate: true },
  );
}
