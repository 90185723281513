import { ClientConstants } from '@/constants/ClientConstants';
import { computed, ComputedRef, Ref, ref } from 'vue';

const shouldEmulateProduction = ref(false);
const isActuallyProduction = import.meta.env.PROD && import.meta.env.VITE_STAGE === 'prod';
const isProduction = computed(() => isActuallyProduction || shouldEmulateProduction.value);
const isLocal = window.location.href.includes('localhost');
const isHSBCEnvironment = import.meta.env.VITE_CLIENT === ClientConstants.HSBC;
const isCoreEnvironment = import.meta.env.VITE_CLIENT === ClientConstants.PREMIALAB;
const isBofAEnvironment = import.meta.env.VITE_CLIENT === ClientConstants.BOFA;
const isBarclaysEnvironment = import.meta.env.VITE_CLIENT === ClientConstants.BARCLAYS;

/**
 * This is different from `isActuallyProduction` and `isProduction`.
 *
 * `isActuallyProduction` is true only on prod and running as a production build, i.e. when deployed to AWS.
 *
 * `isProduction` is true on prod and running as a production build, or if the emulate production flag is flipped on.
 *
 * When comparing team IDs, always use the checks isProdStage and isUatStage instead of `isProduction` /
 * `isActuallyProduction`.
 */
const isProdStage = import.meta.env.VITE_STAGE === 'prod';
const isUatStage = import.meta.env.VITE_STAGE === 'uat';

/**
 * Check if we are running in prerender.io crawler
 * See https://docs.prerender.io/docs/33-overview-of-prerender-crawlers
 */
const isPrerender = window.navigator.userAgent.includes('Prerender (+https://github.com/prerender/prerender)');

/**
 * In general, you should NOT BE USING THIS FUNCTION.
 * We are using this to selectively show portfolio tree ids on local (but not Cypress)
 * so that we may more conveniently troubleshoot portfolio tree id problems.
 * Everything else should remain the same in the test environment, otherwise it rather defeats
 * the purpose of testing in general...
 */
export function getIsCypress() {
  return window.Cypress;
}

export default function (): {
  shouldEmulateProduction: Ref<boolean>;
  isProduction: ComputedRef<boolean>;
  isActuallyProduction: boolean;
  isLocal: boolean;
  isPrerender: boolean;
  isHSBCEnvironment: boolean;
  isCoreEnvironment: boolean;
  isBofAEnvironment: boolean;
  isBarclaysEnvironment: boolean;
  isProdStage: boolean;
  isUatStage: boolean;
} {
  return {
    shouldEmulateProduction,
    isProduction,
    isActuallyProduction,
    isLocal,
    isPrerender,
    isHSBCEnvironment,
    isCoreEnvironment,
    isBofAEnvironment,
    isBarclaysEnvironment,
    isProdStage,
    isUatStage,
  };
}
