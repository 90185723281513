export default function () {
  /**
   * Until the portfolio tree is restructured so that a portfolio tree may have both risk data
   * and a custom weighting, we are implementing a hacky map such that if Simplex users
   * are on their risk portfolio and go to Portfolio Construction, they will see their
   * custom weighting portfolio. And vice versa, if they click on Risk.
   */
  const hackyPortfolioMap: Record<string, { nonRisk: string; risk: string }> = {
    'cross-asset-basket-strategy': {
      nonRisk: 'cross-asset-basket-strategy-wnwWU',
      risk: 'cross-asset-basket-strategy-iaYSy',
    },
    'simplex-multi-carry-strategy': {
      nonRisk: 'simplex-multi-carry-strategy-22f01f01',
      risk: 'simplex-multi-carry-strategy-d969f54c',
    },
  };

  const allTextBeforeLastHyphenInclusive = /.*-/;
  const getSlugBeforeLastHyphen = (slug?: string) => {
    const arr = slug?.match(allTextBeforeLastHyphenInclusive);
    if (arr && arr.length) {
      return arr[0].substring(0, arr[0].length - 1);
    }
    return null;
  };

  return {
    hackyPortfolioMap,
    getSlugBeforeLastHyphen,
  };
}
