import { AppMain } from '@/layout/components/index';
import { RouteConfig } from 'vue-router';
import { RouteName } from '@/constants/RouteName';

export const NoCodeDashboardRouter: RouteConfig[] = [
  {
    path: '/no-code-dashboard',
    name: RouteName.NO_CODE_DASHBOARD,
    components: {
      default: AppMain,
    },
    children: [
      {
        name: RouteName.NO_CODE_DASHBOARD,
        path: '',
        components: {
          default: () => import('@/no-code-dashboard/views/NoCodeDashboard.vue'),
          sidebar: () => import('@/layout/components/sidebar/ApplicationSidebar.vue'),
          'analysis-step-toolbar': () => import('@/no-code-dashboard/components/NoCodeDashboardToolbar.vue'),
        },
        meta: {
          title: 'No Code Dashboard',
          shouldApplicationSidebarBeShown: true,
          isMainContentContainerFluid: true,
          permission: [],
        },
      },
    ],
  },
];
