import { PerfContributionOptionsResponseDTO } from '@/api-v2/admin/perf-contribution/types';
import {
  getPerformanceContributionAvailable,
  getPerformanceContributionAvailableIndices,
  getPerformanceContributionConfig,
  getPerformanceContributionData,
  getPerformanceContributionDates,
  PerformanceContributionRequestDTO,
  PerformanceContributionResponseDTO,
} from '@/api-v2/web/performance-attribution';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { chainEnabled, unwrap } from '@/utils/queries';
import { useQuery } from '@tanstack/vue-query';
import { computed, Ref } from 'vue';
import useLoadingWhenEnabledQuery from './queries/useLoadingWhenEnabledQuery';
import ignoreRetryOn404 from './ignoreRetryOn404';
import { enforceMinimumDate } from '@/utils/dateUtils';

const keys = {
  all: () => [{ scope: 'performance-attribution' }] as const,
  data: (query: Ref<PerformanceContributionRequestDTO | undefined>) => [
    { ...keys.all()[0], entity: 'PerformanceContributionData', query },
  ],
  slug: (slug: Ref<string | null>) => [{ ...keys.all()[0], entity: 'PerformanceContributionDates', slug }],
  options: (slug: Ref<string | null>) => [{ ...keys.all()[0], entity: 'PerformanceContributionOptions', slug }],
  allOptions: () => [{ ...keys.all()[0], entity: 'allPerformanceContributionOptions' }],
  identifier: (slug: Ref<string | null>) => [{ ...keys.all()[0], entity: 'PerformanceContributionAvailable', slug }],
};

export function usePerformanceContributionAvailable(
  slug: Ref<string | null>,
  options: VQQueryOptions<boolean, Error> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!slug.value),
  );
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.identifier(slug),
      queryFn: () => getPerformanceContributionAvailable(unwrap(slug)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

export function usePerformanceContributionData(
  query: Ref<PerformanceContributionRequestDTO | undefined>,
  options: VQQueryOptions<PerformanceContributionResponseDTO, Error> = {},
) {
  const areDatesValid = enforceMinimumDate(query);

  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!query.value && !!query.value.slug),
    areDatesValid,
  );
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.data(query),
      queryFn: () => getPerformanceContributionData(unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

export function usePerformanceContributionDates(
  slug: Ref<string | null>,
  options: VQQueryOptions<string[], Error> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!slug.value),
  );
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.slug(slug),
      queryFn: () => getPerformanceContributionDates(unwrap(slug)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
      retry: ignoreRetryOn404,
    }),
    enabled,
  );
}

export function usePerformanceContributionOptions(
  slug: Ref<string | null>,
  options: VQQueryOptions<PerfContributionOptionsResponseDTO, Error> = {},
) {
  const enabled = chainEnabled(
    options.enabled,
    computed(() => !!slug.value),
  );
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.options(slug),
      queryFn: () => getPerformanceContributionConfig(unwrap(slug)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
      retry: ignoreRetryOn404,
    }),
    enabled,
  );
}

export function useAvailablePerformanceContributionIndicesByCodes(options: VQQueryOptions<string[], Error> = {}) {
  const enabled = chainEnabled(options.enabled);
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.allOptions(),
      queryFn: () => getPerformanceContributionAvailableIndices(),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}
