import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import { StyleConstants } from '@/constants/StyleConstants';
import { UniverseVisualizationType } from '@/constants/UniverseVisualizationType';
import { QuantSpacePeriod } from '@/types/discover';
import { LocalStorageEntities } from '@/constants/LocalStorageEntities';
import { useLocalStorage } from '@vueuse/core';

export enum UniverseColorType {
  ASSET_CLASS = 'colorAssetClass',
  TYPE = 'colorType',
  FACTOR = 'colorFactor',
}

type UniverseOptions = {
  visualizationType: UniverseVisualizationType;
  type: StyleConstants;
  color: UniverseColorType;
  period: QuantSpacePeriod;
};

const DEFAULT_UNIVERSE_OPTIONS: UniverseOptions = {
  visualizationType: UniverseVisualizationType.RISK_RETURN,
  type: StyleConstants.ALPHA,
  color: UniverseColorType.ASSET_CLASS,
  period: PeriodAbbrevEnum.FIVE_Y,
};

/**
 * Get or set the current universe settings.
 */
export function useUniverseOptions() {
  const universe = useLocalStorage<UniverseOptions>(LocalStorageEntities.UNIVERSE_OPTIONS, DEFAULT_UNIVERSE_OPTIONS);

  return {
    universe,
  };
}
