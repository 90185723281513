import { BofAAppMain } from '@/layout/components/index';
import { RouteConfig } from 'vue-router';
import { RouteName } from '@/constants/RouteName';
import { UserPermission } from '@/constants/UserPermission';
import { useHasPermission } from '@/composables/usePermission';

export const myLabChildrenRouter: RouteConfig[] = [
  {
    name: RouteName.DROP_ZONE,
    path: 'drop-zone',
    components: {
      default: () => import('@/views/my-lab/DropZone.vue'),
      'inner-sidebar': () => import('@/whitelabel/bofa/views/mylab/MyLabSidebar.vue'),
    },
    meta: {
      title: 'My Lab | Drop Zone',
      navbar: true,
      shouldMyLabSidebarBeShown: true,
      permission: [UserPermission.DROP_ZONE],
    },
    beforeEnter: (to, from, next) => {
      const isDropzoneAccessible = useHasPermission(UserPermission.DROP_ZONE);
      if (isDropzoneAccessible.value) {
        next();
        return;
      }

      next({ name: RouteName.UNAUTHORIZED });
    },
  },
];

export const MyLabRouter: RouteConfig[] = [
  {
    path: '/my-lab',
    name: RouteName.MY_LAB,
    components: {
      default: BofAAppMain,
    },
    beforeEnter: (to, from, next) => {
      if (to.name !== RouteName.MY_LAB) {
        next();
        return;
      }

      const isDropzoneAccessible = useHasPermission(UserPermission.DROP_ZONE);
      if (isDropzoneAccessible.value) {
        next({ name: RouteName.DROP_ZONE });
        return;
      }

      next({ name: RouteName.UNAUTHORIZED });
    },
    meta: {
      title: 'My Lab',
    },
    children: myLabChildrenRouter,
  },
];
