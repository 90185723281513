import { defineMutation } from './defineMutation';
import {
  RegimeAnalysisAttributeDTO,
  RegimeAnalysisAttributeResponseDTO,
  deleteRegime,
  getRegimes,
  postRegime,
  previewRegimeOnPortfolio,
  previewRegimeOnStrategy,
  updateRegime,
} from '@/api-v2/web/regime-analysis';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { useQuery } from '@tanstack/vue-query';
import { Ref, computed } from 'vue';
import { IPortfolioTreeTrackResponse } from '@/types/IPortfolioTreeTracksResponse';
import { chainEnabled, unwrap } from '@/utils/queries';
import { IPortfolioTreeTracksQuery } from '@/types/IPortfolioTreeTracksQuery';
import { enforceMinimumDate } from '@/utils/dateUtils';
import { ITrackQuery } from '@/types/ITrackQuery';
import { StrategyTrackResponseDTO } from '@/api-v2/web/strategies';
import { RegimeAnalysisMethodConstants } from '@/constants/RegimeAnalysisMethodConstants';

const keys = {
  all: () => [{ scope: 'regime-analysis' }] as const,
  list: () => [{ ...keys.all()[0], entity: 'list' }] as const,
  previewStrategy: (
    track: Ref<{ code: string; query: ITrackQuery } | null>,
    attribute: Ref<RegimeAnalysisAttributeDTO | null>,
  ) => [{ ...keys.all()[0], entity: 'preview-strategy', track, attribute }] as const,
  previewPortfolio: (
    track: Ref<{ slug: string; query: IPortfolioTreeTracksQuery } | null>,
    attribute: Ref<RegimeAnalysisAttributeDTO | null>,
  ) => [{ ...keys.all()[0], entity: 'preview-portfolio', track, attribute }] as const,
};

const select = {
  regimeSets: (data: RegimeAnalysisAttributeResponseDTO[]) => {
    return [...data].sort((a, b) => a.attribute.name.localeCompare(b.attribute.name));
  },
};

export const usePreviewRegimeOnStrategy = (
  track: Ref<{
    code: string;
    query: ITrackQuery;
  } | null>,
  attribute: Ref<RegimeAnalysisAttributeDTO | null>,
  options: VQQueryOptions<StrategyTrackResponseDTO> = {},
) => {
  return useQuery({
    queryKey: keys.previewStrategy(track, attribute),
    queryFn: ({ signal }) =>
      previewRegimeOnStrategy({
        code: unwrap(track.value).code,
        query: unwrap(track.value).query,
        attribute: unwrap(attribute),
        signal,
      }),
    ...options,
    /**
     * This data shouldn't be updated using a timeout.
     * The data only changes based on the parameters.
     */
    staleTime: Number.POSITIVE_INFINITY,
    enabled: chainEnabled(
      options.enabled,
      computed(
        () =>
          track.value !== null &&
          !!attribute.value &&
          attribute.value.regime.method !== RegimeAnalysisMethodConstants.DATE,
      ),
      enforceMinimumDate(track.value?.query),
    ),
  });
};

export const useGetPreviewStrategyKey = (
  track: Ref<{ code: string; query: ITrackQuery } | null>,
  attribute: Ref<RegimeAnalysisAttributeDTO | null>,
) => keys.previewStrategy(track, attribute);

export const usePreviewRegimeOnPortfolio = (
  track: Ref<{
    slug: string;
    query: IPortfolioTreeTracksQuery;
  } | null>,
  attribute: Ref<RegimeAnalysisAttributeDTO | null>,
  options: VQQueryOptions<IPortfolioTreeTrackResponse> = {},
) => {
  return useQuery({
    queryKey: keys.previewPortfolio(track, attribute),
    queryFn: () => previewRegimeOnPortfolio(unwrap(track.value).slug, unwrap(track.value).query, unwrap(attribute)),
    ...options,
    enabled: chainEnabled(
      options.enabled,
      computed(() => !!track.value && !!attribute.value),
      enforceMinimumDate(unwrap(track.value).query),
    ),
  });
};

/**
 * We only want to show the toast after both the mutation AND the query invalidation
 */
export const useCreateRegimeSet = defineMutation({
  mutationFn(options: { payload: RegimeAnalysisAttributeDTO }) {
    return postRegime(options.payload);
  },
  invalidateCache(client) {
    return client.invalidateQueries({ queryKey: keys.all() });
  },
});
export const useUpdateRegimeSet = defineMutation({
  mutationFn({ id, payload }: { id: string; payload: RegimeAnalysisAttributeDTO }) {
    return updateRegime(id, payload);
  },
  invalidateCache(client, { id }) {
    // invalidate other queries using this regimeId, but do not wait for them
    client.invalidateQueries({ queryKey: [{ regimeId: id }] });

    // only depend on the actual saving of the regime so the modal does not appear slow
    return client.invalidateQueries({ queryKey: keys.all() });
  },
});
export const useDeleteRegimeSet = defineMutation({
  mutationFn: (id: string) => deleteRegime(id),
  invalidateCache(client) {
    return client.invalidateQueries({ queryKey: keys.all() });
  },
});

export const useGetRegimeSets = (options: VQQueryOptions = {}) => {
  return useQuery({
    queryKey: keys.list(),
    queryFn: () => getRegimes(),
    ...options,
    select: select.regimeSets,
  });
};
