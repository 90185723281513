import { RouteName } from '@/constants/RouteName';
import { useRouter } from './useRouter';
import { useLastUsedPortfolioSlug } from './useCachedAnalysisParameters';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { useAllPortfoliosBySlug } from './queries/useDataDiscovery';
import { PortfolioType } from '@/types/portfolio';
import useGlobalEventBus from './useGlobalEventBus';
import { Emitter } from 'mitt';
import { ModalEvents, ModalIdParameter } from '@/types/Injectables';
import { PortfolioConstructionTabs } from '@/types/PortfolioConstructionTabs';
import { useFeatureFlag } from './useFeatureFlag';
import { computed } from 'vue';

/**
 * Get a function that redirect to the first available portfolio.
 * This function only works for the following portfolio types:
 * - Portfolio
 * - Equity Basket
 * - QIS Basket
 *
 * We assume the portfolio currently selected portfolio cannot be chosen.
 * So the first available portfolio will exclude it.
 * If there is no available portfolio, it will open the create empty portfolio modal.
 */
export default function useShowNextPortfolio(queryOptions: VQQueryOptions = {}): {
  /**
   * Redirect to the first available portfolio/basket
   */
  showNextPortfolio: () => void;
} {
  const router = useRouter();
  const { eventBus } = useGlobalEventBus();
  const { hasEquityBasketAccess, hasQISBasketAccess, hasPortfolioConstructionAccess } = useFeatureFlag();

  const availablePortfolioTypesToShowNext = computed(() => {
    const retval = [];

    if (hasPortfolioConstructionAccess.value) {
      retval.push(PortfolioType.PORTFOLIO);
    }

    if (hasEquityBasketAccess.value) {
      retval.push(PortfolioType.EQUITY_BASKET);
    }

    if (hasQISBasketAccess.value) {
      retval.push(PortfolioType.QIS_BASKET);
    }

    return retval;
  });

  const { portfolio: lastPortfolioSlug, saveLastUsedPortfolioSlug } = useLastUsedPortfolioSlug();

  const allPortfolioTreesBySlug = useAllPortfoliosBySlug(queryOptions);

  const showNextPortfolio = () => {
    const openPortfolio = (slug: string) => {
      // Reset last portfolio so that user won't get into deleted portfolio again
      saveLastUsedPortfolioSlug(slug);
      router.push({
        name: RouteName.PORTFOLIO_CONSTRUCTION,
        params: {
          indexUniqueIdentifier: slug,
          substep: PortfolioConstructionTabs.SIMULATION,
        },
      });
    };

    const portfolioToOpen = Array.from(allPortfolioTreesBySlug.data.value?.values() ?? []).find((portfolio) => {
      return (
        availablePortfolioTypesToShowNext.value.includes(portfolio.type) && portfolio.slug !== lastPortfolioSlug.value
      );
    });

    if (portfolioToOpen) {
      openPortfolio(portfolioToOpen.slug);
      return;
    }

    (eventBus as Emitter<ModalIdParameter>).emit(ModalEvents.OPEN_MODAL, {
      id: 'create-empty-portfolio-modal',
      shouldRedirectToUniverseOnCancel: true,
    });
  };

  return { showNextPortfolio };
}
