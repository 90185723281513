import { defineMutation } from './defineMutation';
import { FileService } from '@/api-v2/web/file';

const keys = {
  all: () => [{ scope: 'file-service' }] as const,
};

/**
 * Key for discovery page scope.
 * Copied from useDataDiscovery. We need to invalidate some of the cache here.
 */
function discoveryAllKey() {
  return [{ scope: 'discover' }] as const;
}

export const useDeletePortfoliosAndAssociatedFiles = defineMutation<
  string,
  Error,
  {
    portfolioId?: string;
    slug?: string;
    fileContributionId?: number;
  }[]
>({
  mutationFn: FileService.deletePortfoliosAndAssociatedFiles,
  invalidateCache(client) {
    return Promise.all([
      client.invalidateQueries({ queryKey: discoveryAllKey() }),
      client.invalidateQueries({ queryKey: keys.all() }),
    ]);
  },
});
