<!-- eslint-disable vue/no-mutating-props -->
<template>
  <span :class="value && allowRemoval ? 'd-flex' : ''">
    <StandardFormDropdown
      ref="dropdownButton"
      no-flip
      :disabled="disabled"
      :menu-class="{ 'w-table-max': true, shadow: true }"
      class="w-100"
      @show="onShow"
      @shown="onShown"
      @hide="onHide"
      @hidden="onHidden"
    >
      <template #button-content>
        {{ buttonContent }}
      </template>
      <b-dropdown-form>
        <b-form-group>
          <ClearableFormInput
            ref="searchBox"
            :search-query.sync="searchQuery"
            :placeholder="translate({ path: 'GLOBAL.PLACEHOLDERS.KEYWORD_SEARCH' })"
            :class-to-use="'placeholder-info'"
          />
          <div class="mt-3" />
          <IndexOptionsTable
            :shown="isVqEnabled"
            :query="searchQuery"
            :selected.sync="selectedIndexCode"
            :selected-type.sync="selectedIndexType"
            :selected-currency.sync="selectedIndexCurrency"
            :disabled-codes.sync="disabledCodes"
            :options="dsbOptions"
            :tab.sync="tabSelected"
            :is-vue-query-enabled="isVqEnabled"
          />
        </b-form-group>
      </b-dropdown-form>
    </StandardFormDropdown>
    <StandardButton
      v-if="value && allowRemoval"
      :disabled="disabled"
      :icon="'trash-alt'"
      variant="danger"
      class="ml-2"
      @click="clearSelection"
    />
  </span>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import useTranslation from '@/composables/useTranslation';
import { useAllPortfoliosBySlug, useAllStrategiesByCode } from '@/composables/queries/useDataDiscovery';
import { DataTypes } from '@/types/setting';
import { DSBOptions } from '@/types/DSBOptions';
import IndexOptionsTable from '@/components/index-search-box/IndexOptionsTable.vue';
import ClearableFormInput from '@/components/standard-components/ClearableFormInput.vue';
import { BvEvent } from 'bootstrap-vue';
import { onClickOutside, templateRef } from '@vueuse/core';
import useAppMetrics from '@/composables/useAppMetrics';
import { DEFAULT_OPTIONS } from '@/constants/DSBOptions';
import { AnalyticsTrackTypeConstants } from '@/types/AnalyticsTrackTypeConstants';
import { Currency } from '@/constants/Currency';

/**
 * This component differs from the IndexSearchBoxDropdown in that this component
 * displays the name of a single selected Index like a Select form input.
 * When you click on the select, it exposes a dropdown with a separate search box
 * and the IndexOptionsTable, etc.
 *
 * This component assumes that you may only select ONE index from the DSB.
 */
export default defineComponent({
  name: 'IndexSearchBoxSelect',
  components: {
    IndexOptionsTable,
    ClearableFormInput,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    /**
     * Controls the currently selected index code. This is available as `v-model` binding
     */
    value: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * This is used for timing how long the DSB takes to render. Supplying a value here
     * will send an event to Segment using this value as the name of the DSB control
     *
     * This property is not reactive.
     */
    timerId: {
      type: String,
      required: false,
    },
    /**
     * If true, disable all inputs in this component
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledCodes: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    buttonContentOverride: {
      type: String,
      required: false,
    },
    dsbOptions: {
      type: Object as PropType<DSBOptions>,
      required: false,
      default: () => DEFAULT_OPTIONS,
    },
    /**
     * Make this true in order to show the strategy code instead of the shortName.
     */
    shouldShowCodeInButton: {
      type: Boolean,
      default: false,
    },
    allowRemoval: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  emits: {
    input: (_newVal: string) => true,
    /**
     * When the value of the selected item changes, the type may be 'track' or 'portfolio'
     * Listen to the 'update-type' event to keep track of the item type
     */
    'update-type': (_newVal: AnalyticsTrackTypeConstants) => true,
    /**
     * When the value of the selected item changes, the currency may change
     * Listen to the 'update-currency' event to keep track of the item currency
     */
    'update-currency': (_newVal: Currency) => true,
    show: () => true,
    shown: () => true,
    hide: () => true,
    hidden: () => true,
  },
  setup(props, { emit }) {
    const { translate } = useTranslation();

    const searchQuery = ref('');

    const isVqEnabled = ref(false);

    const allPortfolioTreesBySlug = useAllPortfoliosBySlug({ enabled: isVqEnabled });
    const allStrategiesByCode = useAllStrategiesByCode({ enabled: isVqEnabled });

    /**
     * Temp value used by the dropdown. This is synced with the v-model value and
     * will update that value on the `hidden` event of the dropdown
     */
    const selectedIndexCode = ref('');
    /**
     * Keeps track of whether the `selectedIndexCode` is 'track' or 'portfolio'
     */
    const selectedIndexType = ref<AnalyticsTrackTypeConstants | undefined>();
    /**
     * Keeps track of the currency of the `selectedIndexCode`
     */
    const selectedIndexCurrency = ref<Currency | undefined>();

    const dropdownButton = templateRef<HTMLElement>('dropdownButton');
    const preventHide = ref(true);
    onClickOutside(dropdownButton, () => {
      preventHide.value = false;
    });

    /**
     * User friendly name of the selected index
     */
    const userFriendlyName = computed(() => {
      const portfolio = allPortfolioTreesBySlug.data.value?.get(props.value);
      if (portfolio) {
        return portfolio.name;
      }
      const strategy = allStrategiesByCode.data.value?.get(props.value);
      if (strategy) {
        return strategy.shortName || strategy.code;
      }
      return props.value;
    });

    const buttonContent = computed(() => {
      if (props.buttonContentOverride) return props.buttonContentOverride;

      if (props.shouldShowCodeInButton) return props.value || translate({ path: 'MODALS.EDIT_WEIGHTING.SELECT_ONE' });

      return userFriendlyName.value || translate({ path: 'MODALS.EDIT_WEIGHTING.SELECT_ONE' });
    });

    const clearSelection = () => {
      emit('input', '');
      selectedIndexCode.value = '';
    };

    const searchBox = ref<InstanceType<typeof ClearableFormInput> | null>(null);
    const tabSelected = ref<DataTypes | undefined>(undefined);
    watch(tabSelected, () => {
      searchBox.value?.focus();
    });

    const showDsbTimer = ref<number | null>(null);
    const { startTimer, endTimer, trackButtonClick } = useAppMetrics();

    const onShow = () => {
      if (props.timerId) showDsbTimer.value = startTimer();
      selectedIndexCode.value = props.value ?? '';
      emit('show');
    };

    const onShown = () => {
      isVqEnabled.value = true;
      preventHide.value = true;

      searchBox.value?.focus();

      if (showDsbTimer.value !== null && props.timerId) {
        const showDsb = endTimer(showDsbTimer.value, `show ${props.timerId}`);
        if (showDsb) {
          trackButtonClick(props.timerId, {
            timeElapsed: showDsb.timeElapsed,
          });
        }
      }
      emit('shown');
    };

    /**
     * Prevent the dropdown from closing if we have clicked inside the dropdown
     */
    const onHide = (bvEvent: BvEvent) => {
      if (preventHide.value) {
        bvEvent.preventDefault();
      }
    };

    const onHidden = () => {
      searchQuery.value = '';
      isVqEnabled.value = false;

      if (selectedIndexCode.value !== props.value) {
        emit('input', selectedIndexCode.value);
        if (selectedIndexType.value) emit('update-type', selectedIndexType.value);
        if (selectedIndexCurrency.value) emit('update-currency', selectedIndexCurrency.value);
      }

      selectedIndexCode.value = '';
      selectedIndexType.value = undefined;
      selectedIndexCurrency.value = undefined;

      emit('hidden');
    };

    return {
      searchQuery,
      tabSelected,
      translate,
      selectedIndexCode,
      selectedIndexType,
      selectedIndexCurrency,
      buttonContent,
      clearSelection,
      isVqEnabled,
      searchBox,

      onShow,
      onShown,
      onHide,
      onHidden,
    };
  },
});
</script>
