import { Ref } from 'vue';
import { MaybeRef } from '@vueuse/core';
import { useQuery } from '@tanstack/vue-query';
import {
  adminBatchUpdateStrategiesTeamIds,
  adminGetContributionSummary,
  adminGetMorningstarStaticData,
  adminGetPrivateTracks,
  adminGetPrivateTracksSearch,
  adminGetSignals,
  adminGetStrategies,
  adminGetStrategiesSearch,
  adminGetStrategy,
  adminGetStrategyPrice,
  adminNewStrategyAttachmentUpload,
  adminStrategyAttachmentUploadConfirm,
  adminUpdateStrategy,
  BatchUpdateStrategiesTeamIdsRequestDTO,
  SignalListItemResponseDTO,
  StrategyListItemResponseDTO,
  TimeSeriesStaticInfoRequest,
  TimeSeriesDetailResponseDTO,
  adminTriggerStrategyDelete,
  adminTriggerStrategyRecover,
  adminSuspendTrackSource,
  adminUnsuspendTrackSource,
  adminTriggerBuildTracks,
  ContributionSummaryDTO,
  adminUpdateStrategyNotes,
} from '@/api-v2/admin/strategies';
import { VQQueryOptions } from '@/types/VueQueryTypes';
import { defineMutation, useManagedMutation, VQMutationOptions } from './defineMutation';
import { MorningstarDataResponseDTO } from '@/types/admin/MorningstarDataResponseDTO';
import { TimeSeriesEntityTypeConstants } from '@/constants/TimeSeriesEntityTypeConstants';
import { sha256sum } from '@/utils/stream';
import { StrategyAttachmentType } from '@/types/StrategyAttachment';
import { UpdateStrategyNotesDTO } from '@/api-v2/admin/strategies/types/UpdateStrategyNotesDTO';

const keys = {
  all: () => [{ scope: 'admin-strategy-management' }] as const,
  strategies: (type: Ref<string>) => [{ ...keys.all()[0], entity: 'strategies', type }] as const,
  strategiesSearch: (query: Ref<string>, exactMatch: Ref<boolean>) =>
    [{ ...keys.all()[0], entity: 'strategies-search', query, exactMatch }] as const,
  privateTracks: (teamId: Ref<number>) => [{ ...keys.all()[0], entity: 'private-tracks', teamId }] as const,
  privateTracksSearch: (teamId: Ref<number>, query: Ref<string>) =>
    [{ ...keys.all()[0], entity: 'private-tracks-search', teamId, query }] as const,
  signals: () => [{ ...keys.all()[0], entity: 'signals' }] as const,
  strategy: (id: MaybeRef<string>) => [{ ...keys.all()[0], entity: 'private-track', id }] as const,
  prices: (id: Ref<string>, valueType: Ref<string | null>, exchangeId: Ref<string | null>) =>
    [{ ...keys.all()[0], entity: 'prices', id, valueType, exchangeId }] as const,
  mstarData: (mstarId: Ref<string>) => [{ ...keys.all()[0], entity: 'mstarData', mstarId }] as const,
  contribution: (id: Ref<string>, valueType: Ref<string | null>, exchangeId: Ref<string | null>) =>
    [{ ...keys.all()[0], entity: 'contribution', id, valueType, exchangeId }] as const,
};

/**
 * Get all strategies.
 */
export function useStrategies(
  type: Ref<TimeSeriesEntityTypeConstants>,
  options: VQQueryOptions<StrategyListItemResponseDTO[]> = {},
) {
  return useQuery({
    queryKey: keys.strategies(type),
    queryFn: ({ signal }) => adminGetStrategies(type.value, signal),
    ...options,
  });
}

/**
 * Get all strategies by search term.
 */
export function useStrategiesSearch(
  query: Ref<string>,
  exactMatch: Ref<boolean>,
  options: VQQueryOptions<StrategyListItemResponseDTO[]> = {},
) {
  return useQuery({
    queryKey: keys.strategiesSearch(query, exactMatch),
    queryFn: ({ signal }) => adminGetStrategiesSearch(query.value, exactMatch.value, signal),
    ...options,
  });
}

/**
 * Get all private tracks.
 */
export function usePrivateTracks(teamId: Ref<number>, options: VQQueryOptions<StrategyListItemResponseDTO[]> = {}) {
  return useQuery({
    queryKey: keys.privateTracks(teamId),
    queryFn: ({ signal }) => adminGetPrivateTracks(teamId.value, signal),
    ...options,
  });
}

/**
 * Get all private tracks by search term.
 */
export function usePrivateTracksSearch(
  teamId: Ref<number>,
  query: Ref<string>,
  options: VQQueryOptions<StrategyListItemResponseDTO[]> = {},
) {
  return useQuery({
    queryKey: keys.privateTracksSearch(teamId, query),
    queryFn: ({ signal }) => adminGetPrivateTracksSearch(teamId.value, query.value, signal),
    ...options,
  });
}

/**
 * Get all signals.
 */
export function useSignals(options: VQQueryOptions<SignalListItemResponseDTO[]> = {}) {
  return useQuery({
    queryKey: keys.signals(),
    queryFn: ({ signal }) => adminGetSignals(signal),
    ...options,
  });
}

/**
 * Get strategy / private track
 */
export function useStrategy(id: Ref<string>, options: VQQueryOptions<TimeSeriesDetailResponseDTO> = {}) {
  return useQuery({
    queryKey: keys.strategy(id),
    queryFn: ({ signal }) => adminGetStrategy(id.value, signal),
    ...options,
  });
}

export function useAdminGetStrategyPrice(
  id: Ref<string>,
  valueType: Ref<string | null>,
  exchangeId: Ref<string | null>,
  options: VQQueryOptions<{ date: string; value: number }[] | null> = {},
) {
  return useQuery({
    ...options,
    queryKey: keys.prices(id, valueType, exchangeId),
    queryFn: ({ signal }) => adminGetStrategyPrice(id.value, valueType.value, exchangeId.value, signal),
  });
}

/**
 * We want to set a stale time of 10 minutes to prevent the queries from refreshing too often. This is because the calls
 * to contributions is fairly heavy on the database.
 *
 * Also the contributions dialog is mainly read-only i.e. similar to metabase/dbeaver, and all actions invoked are
 * asynchronous, so there is no good need for the contributions dialog to be reactive.
 */
const STALE_TIME_TRACK_OR_CONTRIBUTION = 10 * 60_000; /* 10 minutes */

export function useAdminGetContributionSummary(
  id: Ref<string>,
  valueType: Ref<string | null>,
  exchangeId: Ref<string | null>,
  options: VQQueryOptions<ContributionSummaryDTO[]> = {},
) {
  return useQuery({
    ...options,
    queryKey: keys.contribution(id, valueType, exchangeId),
    queryFn: ({ signal }) => adminGetContributionSummary(id.value, valueType.value, exchangeId.value, signal),
    staleTime: STALE_TIME_TRACK_OR_CONTRIBUTION,
  });
}

/**
 * Batch Update Strategies team_ids field
 *
 * team_ids field represents the teams that have access to the strategy
 * Note that only PRIVATE_STRATEGY and THEMATIC can be updated
 * as all other strategies are publicly accessible
 */
export const useBatchUpdateStrategiesTeamIds = defineMutation({
  mutationFn: ({ body }: { body: BatchUpdateStrategiesTeamIdsRequestDTO }) => adminBatchUpdateStrategiesTeamIds(body),
  invalidateCache(client) {
    return client.invalidateQueries({ queryKey: keys.all() });
  },
});

export function useAdminGetMorningstarStaticData(
  mstarId: Ref<string>,
  options: VQQueryOptions<MorningstarDataResponseDTO> = {},
) {
  return useQuery({
    queryKey: keys.mstarData(mstarId),
    queryFn: ({ signal }) => adminGetMorningstarStaticData(mstarId.value, signal),
    ...options,
  });
}

/**
 * Update strategy
 */
export const useAdminUpdateStrategy = defineMutation({
  mutationFn: ({ id, body }: { id: string; body: TimeSeriesStaticInfoRequest }) => adminUpdateStrategy(id, body),
  invalidateCache(client) {
    return client.invalidateQueries({ queryKey: keys.all() });
  },
});

/**
 * Update strategy notes
 */
export const useAdminUpdateStrategyNotes = defineMutation({
  mutationFn: ({ id, body }: { id: string; body: UpdateStrategyNotesDTO }) => adminUpdateStrategyNotes(id, body),
  invalidateCache(client) {
    return client.invalidateQueries({ queryKey: keys.all() });
  },
});

export const useAdminUploadStrategyAttachment = defineMutation({
  async mutationFn(options: { file: File; id: string; assetType: StrategyAttachmentType }) {
    const { file, id, assetType } = options;

    // Step 1: Get the S3 upload URL
    const hash = await sha256sum(await file.arrayBuffer());
    const { signedUrl, ticket } = await adminNewStrategyAttachmentUpload({ contentLength: file.size, sha256sum: hash });

    // Step 2: Upload the file to S3 directly
    // Axios (the way we config it?) is trying so hard to make the request
    // application/json when it isn't. Fallback to use fetch here.
    const resp = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
    });
    if (resp.status >= 400) {
      throw new Error('Failed to upload file to S3');
    }

    // Step 3: Notify api we have uploaded file
    return await adminStrategyAttachmentUploadConfirm(id, { ticket, fileName: file.name, assetType });
  },
  invalidateCache(client) {
    // HACK: Not sure how to handle cross boundary query invalidation here.
    return client.invalidateQueries({ queryKey: [{ scope: 'strategies', entity: 'attachment' }] });
  },
});

/**
 * soft delete strategy / private track
 */
export function useDeleteStrategy(id: Ref<string>, options: VQMutationOptions<void, Error, void> = {}) {
  return useManagedMutation(
    {
      mutationFn: () => adminTriggerStrategyDelete(id.value),
      invalidateCache(client) {
        return client.invalidateQueries({ queryKey: keys.strategy(id) });
      },
    },
    options,
  );
}

/**
 * recover strategy / private track
 */
export function useRecoverStrategy(id: Ref<string>, options: VQMutationOptions<void, Error, void> = {}) {
  return useManagedMutation(
    {
      mutationFn: () => adminTriggerStrategyRecover(id.value),
      invalidateCache(client) {
        return client.invalidateQueries({ queryKey: keys.strategy(id) });
      },
    },
    options,
  );
}

/**
 * Suspend Track Source
 */
export const useSuspendTrackSource = defineMutation({
  mutationFn: ({ id, trackId }: { id: string; trackId: number }) => adminSuspendTrackSource(id, trackId),
  invalidateCache(client) {
    return client.invalidateQueries({ queryKey: keys.all() });
  },
});

/**
 * Unsuspend Track Source
 */
export const useUnsuspendTrackSource = defineMutation({
  mutationFn: ({ id, trackId }: { id: string; trackId: number }) => adminUnsuspendTrackSource(id, trackId),
  invalidateCache(client) {
    return client.invalidateQueries({ queryKey: keys.all() });
  },
});

/**
 * Trigger build tracks
 */
export const useTriggerBuildTracks = defineMutation({
  mutationFn: (id: string) => adminTriggerBuildTracks(id),
  invalidateCache() {
    // Invalidate nothing because `build-tracks` is async.
    return Promise.resolve();
  },
});
