import { Ref, computed, ref, watch } from 'vue';
import usePortfolioTree from './usePortfolioTree';
import { GetTracksDTO } from '@/api-v2/web/strategies/types/GetTracksDTO';
import { Currency } from '@/constants/Currency';
import { useFxConvertedStrategyPricesByDate } from '@/composables/queries/useStrategyData';
import { notNull } from '@/utils/notnull';
import { isPortfolioTreeStrategy, isPortfolioTreeSubportfolio } from '@/types/IPortfolioTree';
import { flatten, isItemInactive } from '@/utils/portfolioTree';
import { DateTime } from 'luxon';
import { convertToBusiness, createDate } from '@/utils/dateUtils';
import { Status } from '@/constants/Status';
import useRouteChecks from './useRouteChecks';
import { useRouteRef } from './useRouter';
import { getPositionDate } from './useConstituentPositionParams';
import { useTriangulationDate } from './useTriangulation';
import { uniq } from 'lodash';
import useFxConversion from './useFxConversion';

/**
 * TODO: WAA-11485 Refactor this composable to be a bit more generic and reusable.
 * params should pass strategyCode array so that we can get the tracks as appropriate
 * Basket logic should be moved to the calling component
 * Position logic should be moved to the calling component
 */
export function useFxConvertedStrategyTracksByDate(strategyCode: Ref<string | null> | null = null) {
  const route = useRouteRef();
  const { isOnConstituentRisk, isOnPosition } = useRouteChecks(route);
  const { masterPortfolioTree } = usePortfolioTree();
  const { toCurrency } = useFxConversion();

  /**
   * The flattenedActiveCodes in masterPortfolioTree.value.portfolioTree are generated by the API.
   * This may be empty if the user has added a strategy to a portfolio but has not yet clicked calculate or save.
   * However, triangulation requires the codes and prices for frontend calculations,
   * so we are generating the flattenedActiveCodes here.
   */
  const codesToUse = computed(() => {
    if (!masterPortfolioTree.value) return [strategyCode?.value].filter(notNull);

    const flattenTree = flatten(masterPortfolioTree.value.portfolioTree);

    // can't provide price if it is inactive
    const strategyCodes = flattenTree
      .filter(isPortfolioTreeStrategy)
      .filter((component) => {
        return !isItemInactive(component, isOnConstituentRisk.value) && component.suspended === Status.ACTIVE;
      })
      .map((component) => component.strategy?.code);

    const masterIndexCodes = flattenTree.filter(isPortfolioTreeSubportfolio).map((component) => component.reference);

    return uniq([...strategyCodes, ...masterIndexCodes].filter(notNull));
  });

  const previousDate = computed((): DateTime<true> => convertToBusiness(createDate().minus({ days: 1 })));
  const previousDateString = computed(() => previousDate.value.toISODate());

  const triangulationDate = useTriangulationDate();

  const positionDate = getPositionDate();

  const dateToUse = ref();

  watch(
    [isOnPosition, triangulationDate],
    ([isOnPositionNewVal, triangulationDateNewVal]) => {
      if (isOnPositionNewVal && positionDate.value) {
        dateToUse.value = positionDate.value;
        return;
      }
      dateToUse.value = triangulationDateNewVal ?? previousDateString.value;
    },
    { immediate: true },
  );

  const resetDateToUse = () => {
    dateToUse.value = previousDateString.value;
  };

  const query = computed((): GetTracksDTO => {
    return {
      analysisCodes: codesToUse.value,
      analysisDate: dateToUse.value,
      reportingCurrency: masterPortfolioTree.value?.portfolioTree?.toCurrency ?? toCurrency.value ?? Currency.USD,
    };
  });

  const fxConvertedPriceData = useFxConvertedStrategyPricesByDate(query);

  const itemOriginalStrategyPrice = computed(() => {
    if (!strategyCode?.value || !fxConvertedPriceData.data.value) return null;

    return fxConvertedPriceData.data.value.strategyNavMap[strategyCode.value]?.value ?? null;
  });

  const itemConvertedStrategyPrice = computed(() => {
    if (!strategyCode?.value || !fxConvertedPriceData.data.value) return null;

    return fxConvertedPriceData.data.value.strategyNavConvertedMap[strategyCode.value]?.value ?? null;
  });

  const isPriceDataLoading = computed(() => {
    if (!codesToUse.value.length) return false;
    return fxConvertedPriceData.isLoading.value;
  });

  return {
    fxConvertedPriceData,
    itemOriginalStrategyPrice,
    itemConvertedStrategyPrice,
    isPriceDataLoading,
    resetDateToUse,
    triangulationDate,
  };
}
