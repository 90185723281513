<template>
  <section
    class="app-main container-fluid px-0"
    :style="sectionStyle"
  >
    <router-view name="navbar" />
    <router-view name="sidebar" />
    <router-view
      v-show="!legacyApiPage"
      name="inner-sidebar"
    />
    <router-view name="right-sidebar" />
    <b-container
      :fluid="isMainContentFluid ? true : null"
      class="position-relative px-0"
      :style="mainPageStyle"
    >
      <b-overlay
        :show="isSystemLoading && !isPdf"
        style="z-index: 12"
        no-wrap
        no-center
      >
        <template #overlay>
          <b-spinner
            class="position-fixed"
            :style="loadingSpinnerStyle"
          />
        </template>
      </b-overlay>
      <div
        v-if="shouldShowToolbar || isLegacyLayout"
        class="sticky-top bg-white"
        :style="`top: ${fixedNavbarHeight}; padding-left: ${shouldShowPadding ? 30 : 0}px; padding-right: ${
          shouldShowPadding ? 30 : 0
        }px`"
      >
        <router-view
          :class="{ 'py-2pt5': !isLegacyLayout }"
          name="analysis-step-toolbar"
        />

        <hr
          v-if="!isLegacyLayout"
          class="hr-info my-0"
        />
      </div>
      <div class="position-relative">
        <transition
          name="fade-transform"
          mode="out-in"
          class="mt-3"
        >
          <div v-if="hasAdminConsoleToolbar">
            <router-view name="admin-console-toolbar" />

            <transition
              name="fade-transform"
              mode="out-in"
            >
              <router-view />
            </transition>
          </div>

          <router-view v-else />
        </transition>
      </div>
    </b-container>
    <SaveChangesModal />
    <TourGuide />
    <KnowledgeBaseSidebar />
  </section>
</template>

<script lang="ts">
import {
  AdminConsoleSidebarFixedWith,
  ApplicationSidebarWidth,
  MyLabSidebarFixedWidth,
} from '@/constants/SidebarWidths';
import { computed, defineComponent, watch } from 'vue';
import useSystemLoading, { useIsSystemLoading } from '@/composables/useSystemLoading';
import { BSpinner } from 'bootstrap-vue';
import { RouteName } from '@/constants/RouteName';
import { useRouteRef } from '@/composables/useRouter';
import { useLeftSidebar } from '@/composables/useLeftSidebar';
import SaveChangesModal from '@/views/modals/SaveChangesModal.vue';
import { notNull } from '@/utils/notnull';
import { intersection } from 'lodash';
import TourGuide from '@/layout/components/TourGuide.vue';
import { useMobileResponsiveUtilities } from '@/composables/useMobileResponsiveUtilities';
import { useIsPdf } from '@/composables/usePdf';
import KnowledgeBaseSidebar from '@/views/knowledge-base/KnowledgeBaseSidebar.vue';
import { useAdminStore } from '@/composables/useAdminStore';
import { useApplicationSidebar } from '@/composables/useApplicationSidebar';
import { SettingsModule } from '@/store/modules/settings';
import { useColors } from '@/composables/useSettings';

export default defineComponent({
  name: 'AppMain',
  components: {
    BSpinner,
    SaveChangesModal,
    TourGuide,
    KnowledgeBaseSidebar,
  },
  setup() {
    const route = useRouteRef();

    const colors = useColors();
    watch(
      colors.data,
      (newVal) => {
        if (!newVal) return;

        SettingsModule.SET_COLORS(newVal);
      },
      { immediate: true },
    );

    const { shouldShowApplicationSidebar: shouldApplicationSidebarBeShown } = useApplicationSidebar();
    const shouldAdminConsoleSidebarBeShown = computed(
      () => route.value.meta?.shouldAdminConsoleSidebarBeShown ?? false,
    );
    const shouldMyLabSidebarBeShown = computed(() => route.value.meta?.shouldMyLabSidebarBeShown ?? false);

    const { collapsedWidth, shouldBeShown: shouldNavigationPanelBeShown } = useLeftSidebar();
    const { isSidebarCollapsed: isAdminConsoleSidebarCollapsed } = useAdminStore();

    const leftSidebarFixedWidth = computed(() => {
      if (shouldMyLabSidebarBeShown.value) {
        return MyLabSidebarFixedWidth;
      }

      if (shouldAdminConsoleSidebarBeShown.value) {
        if (isAdminConsoleSidebarCollapsed.value) {
          return '24px';
        }
        return AdminConsoleSidebarFixedWith;
      }
      if (shouldApplicationSidebarBeShown.value && !shouldNavigationPanelBeShown.value) {
        return ApplicationSidebarWidth;
      }

      if (!shouldApplicationSidebarBeShown.value && shouldNavigationPanelBeShown.value) {
        return collapsedWidth.value;
      }
      if (!shouldApplicationSidebarBeShown.value && !shouldNavigationPanelBeShown.value) {
        // don't return just plain 0 because otherwise css calc gets mad at you
        return '0px';
      }
      return `calc(${collapsedWidth.value} + ${ApplicationSidebarWidth})`;
    });

    const isPdf = useIsPdf();

    const scrollbarWidth = computed((): number => SettingsModule.scrollbarWidth);

    /**
     * Used to temporarily disable the clicking on the sidebar, since the portfolio tree has not been integrated on this page
     * Currently want to have the portfolio sidebar loaded but not hidden.
     */
    const legacyApiPage = computed(() => {
      const allowedCurrentPage =
        route.value.name === RouteName.CLUSTERING_ANALYSIS ||
        route.value.name === RouteName.PORTFOLIO_FACTSHEET ||
        route.value.name === RouteName.STRATEGY_FACTSHEET ||
        route.value.name === RouteName.PERFORMANCE_ATTRIBUTION ||
        route.value.name === RouteName.POSITION;
      return allowedCurrentPage;
    });

    const nonZeroScrollbarWidth = computed(() => {
      return `calc(100vw - calc(${leftSidebarFixedWidth.value} + ${scrollbarWidth.value}px))`;
    });

    const width = computed(() =>
      scrollbarWidth.value !== 0 ? nonZeroScrollbarWidth.value : `calc(100vw - ${leftSidebarFixedWidth.value})`,
    );

    const mainPageStyle = computed(() => {
      if (
        (shouldApplicationSidebarBeShown.value ||
          shouldNavigationPanelBeShown.value ||
          shouldAdminConsoleSidebarBeShown.value ||
          shouldMyLabSidebarBeShown.value) &&
        leftSidebarFixedWidth.value &&
        !legacyApiPage.value
      ) {
        return {
          'margin-left': leftSidebarFixedWidth.value,
          'margin-right': 0,
          'min-width': width.value,
          'max-width': width.value,
        };
      }
      return {};
    });

    const fixedNavbarHeight = '75px';
    const spinnerHeight = '36px';

    const loadingSpinnerStyle = computed(() => {
      return {
        right: `calc(calc(100% - ${leftSidebarFixedWidth.value}) / 2)`,
        top: `calc(calc(calc(100vh - ${fixedNavbarHeight}) / 2) + calc(${spinnerHeight} / 2))`,
      };
    });

    const sectionStyle = computed(() => {
      if (!isPdf.value) {
        return {
          width: scrollbarWidth.value !== 0 ? `calc(100vw - ${scrollbarWidth.value}px);` : '100vw',
          'margin-top': fixedNavbarHeight,
        };
      }
      return {};
    });

    const isMainContentFluid = computed(() => route.value.meta?.isMainContentContainerFluid ?? false);

    const { loadingItems } = useSystemLoading();
    const isSystemLoading = useIsSystemLoading();

    const currentRouteMatches = computed(() => route.value.matched.map((r) => r.name).filter(notNull));

    const shouldShowToolbar = computed(() => {
      const routesWithToolbar = [
        RouteName.CONSTITUENT_RISK_PORTFOLIO,
        RouteName.CONSTITUENT_RISK_STRATEGY,
        RouteName.FACTOR_DECOMPOSITION_PORTFOLIO,
        RouteName.FACTOR_DECOMPOSITION_STRATEGY,
        RouteName.PERFORMANCE_ATTRIBUTION,
        RouteName.PORTFOLIO_CONSTRUCTION,
        RouteName.POSITION,
        RouteName.NO_CODE_DASHBOARD,
      ];

      const matches = route.value.matched.map((r) => r.name).filter(notNull);
      return intersection(matches, routesWithToolbar).length > 0;
    });

    /**
     * For pages using the legacy layout
     */

    const isLegacyLayout = computed(() => {
      const legacyLayoutPages = [
        RouteName.CLUSTERING_ANALYSIS,
        RouteName.PORTFOLIO_FACTSHEET,
        RouteName.STRATEGY_FACTSHEET,
        RouteName.PERFORMANCE_ATTRIBUTION,
        RouteName.POSITION,
        RouteName.NO_CODE_DASHBOARD,
      ];

      const matches = route.value.matched.map((r) => r.name).filter(notNull);
      return intersection(matches, legacyLayoutPages).length > 0;
    });

    const { isSmallScreen } = useMobileResponsiveUtilities();

    const shouldShowPadding = computed(() => {
      return !isSmallScreen.value && !legacyApiPage.value;
    });

    const hasAdminConsoleToolbar = computed(() => {
      return route.value.meta?.hasAdminConsoleToolbar ?? false;
    });

    return {
      sectionStyle,
      isMainContentFluid,
      mainPageStyle,
      isSystemLoading,
      loadingSpinnerStyle,
      loadingItems,
      legacyApiPage,
      leftSidebarFixedWidth,
      width,
      shouldNavigationPanelBeShown,
      shouldApplicationSidebarBeShown,
      route,
      shouldShowToolbar,
      currentRouteMatches,
      isLegacyLayout,
      fixedNavbarHeight,
      isPdf,
      shouldShowPadding,
      hasAdminConsoleToolbar,
    };
  },
});
</script>
