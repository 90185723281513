import { BofAAppMain } from '@/layout/components/index';
import { UserPermission } from '@/constants/UserPermission';
import { RouteConfig } from 'vue-router';
import { NavItemNames } from '@/constants/NavbarConstants';
import { RouteName } from '@/constants/RouteName';

export const DataRouter: RouteConfig[] = [
  {
    path: '/data',
    redirect: {
      name: RouteName.DATA,
    },
  },
  {
    path: '/discover',
    components: {
      default: BofAAppMain,
    },
    children: [
      {
        name: RouteName.DATA,
        // Specifying path instead of '' here.
        // If we use '', this generated route will be `/discover/` with tailing slash.
        // That breaks auth0. So working around that by specify a root path here.
        path: '/discover',
        component: () => import('@/views/discover/DataUniverseDeprecated.vue'),
        meta: {
          title: NavItemNames.DATA,
          permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM],
          shouldMainContentHaveSidePadding: true,
        },
      },
    ],
  },
];
