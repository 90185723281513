import request from '@/api-v2/request';
import { IUserInfoResponse } from './types/IUserInfoResponse';
import { IUserProviderResponse } from './types/IUserProviderResponse';
import { IUpdateUser } from './types/IUpdateUser';
import { ITeamResponse } from './types/ITeamResponse';
import { IUpdateTeamParameter } from './types/IUpdateTeamParameter';
import { ITeamParameter } from './types/ITeamParameter';
import { UserSubTeamResponseDTO } from './types/UserSubTeamResponseDTO';

export const getUserInfo = (): Promise<IUserInfoResponse> =>
  request({
    url: `/user`,
    method: 'get',
  }).then((o) => o.data);

export const getUserProvider = (signal?: AbortSignal): Promise<IUserProviderResponse> =>
  request({
    url: `/user/provider`,
    method: 'get',
    signal,
  }).then((o) => o.data);

export const updateUserInfo = (user: IUpdateUser): Promise<IUserInfoResponse> =>
  request({
    url: `/user`,
    method: 'put',
    data: user,
  }).then((o) => o.data);

export const getTeamInfo = (signal?: AbortSignal): Promise<ITeamResponse> =>
  request({
    url: `/user/team`,
    method: 'get',
    signal,
  }).then((o) => o.data);

export const getTeamParameters = (signal?: AbortSignal): Promise<ITeamParameter> =>
  request({
    url: `/user/team/parameters`,
    method: 'get',
    signal,
  }).then((o) => o.data);

export const updateTeamParameters = (body: IUpdateTeamParameter, signal?: AbortSignal): Promise<ITeamParameter> =>
  request({
    url: `/user/team/parameters`,
    method: 'post',
    data: body,
    signal,
  }).then((o) => o.data);

export const getSubTeams = (): Promise<UserSubTeamResponseDTO> =>
  request({
    url: `/user/sub-team`,
    method: 'get',
  }).then((o) => o.data);
