import { set, watch } from 'vue-demi';
import {
  ExposureConstants,
  FilterConstants,
  TableLayerConstants,
  UnitConstants,
  VarNotional,
} from '@/types/analytics/ConstituentRisk';
import { useLocalStorage } from '@vueuse/core';
import { LocalStorageEntities } from '@/constants/LocalStorageEntities';

export type PositionVarPreference = {
  varMetric: VarNotional;
  display: ExposureConstants;
  varMetricGroupBy: FilterConstants;
};

const defaultVarPreference1: PositionVarPreference = {
  varMetric: VarNotional.NOTIONAL,
  display: ExposureConstants.NET,
  varMetricGroupBy: FilterConstants.SECTOR,
};

const defaultVarPreference2: PositionVarPreference = {
  varMetric: VarNotional.NOTIONAL,
  display: ExposureConstants.NET,
  varMetricGroupBy: FilterConstants.CURRENCY,
};

export type PreferenceOptions = {
  unit: UnitConstants;
  customCols: string[];
  tableLayers: TableLayerConstants[];
  var1: PositionVarPreference;
  var2: PositionVarPreference;
};

const defaultPreferences: PreferenceOptions = {
  unit: UnitConstants.CURRENCY,
  customCols: [],
  tableLayers: [TableLayerConstants.SECTOR, TableLayerConstants.INSTRUMENT],
  var1: defaultVarPreference1,
  var2: defaultVarPreference2,
};

export function usePositionPreferences() {
  /**
   * Sets the new preferences
   * @param param paramPreferences object property to change
   * @param option Value to change it to
   */
  const setTablePreference = (param: string, option: unknown) => {
    set(preferences.value, param, option);
  };

  const preferences = useLocalStorage<PreferenceOptions>(LocalStorageEntities.POSITION_PREFERENCES, defaultPreferences);

  /**
   * On Chrome, the var1 and var2 preferences are not initialized correctly and are undefined.
   * This watcher ensures that the preferences are always initialized correctly.
   * So the charts on the position page do not break.
   * This behavior is not observed on Firefox.
   */
  watch(preferences, () => {
    if (!preferences.value.var1) {
      preferences.value.var1 = defaultVarPreference1;
    }

    if (!preferences.value.var2) {
      preferences.value.var2 = defaultVarPreference2;
    }
  });

  return { preferences, setTablePreference };
}
