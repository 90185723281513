import { getColors, ISettingRecord } from '@/api-v2/web/settings';
import { EntityTypeColors } from '@/types/EntityTypeColors';
import { useQuery } from '@tanstack/vue-query';
import { chain, zipObject } from 'lodash';

const keys = {
  all: () => [{ scope: 'settings' }] as const,
  colors: () => [{ ...keys.all()[0], entity: 'colors' }],
};

const select = {
  colors: (colors: ISettingRecord[]): EntityTypeColors => {
    return chain(colors)
      .groupBy((o) => o.aggregationField)
      .mapValues((o) =>
        zipObject(
          o.map((x) => x.key),
          o.map((x) => x.value),
        ),
      )
      .value() as EntityTypeColors;
  },
};

export function useColors() {
  return useQuery({
    queryKey: keys.colors(),
    queryFn: () => getColors(),
    select: select.colors,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
  });
}
