import request, { checkBlockPopup } from '@/api-v2/request';
import { FileSignedUrlDTO } from '@/types/dto/FileSignedUrlDTO';
import { PerformanceContributionRequestDTO } from './types/PerformanceContributionRequestDTO';
import { FileService } from '@/api-v2/web/file';
import { RouteName } from '@/constants/RouteName';
import { useDefaultPdfOptions } from '@/composables/usePdf';
import { Locales } from '@/constants/Locales';
import { PerformanceContributionResponseDTO } from './types/PerformanceContributionResponseDTO';
import { PerfContributionOptionsResponseDTO } from '@/api-v2/admin/perf-contribution/types';

const defaultPdfOptions = useDefaultPdfOptions();

/**
 * Use this function in order to get a boolean value which represents
 * whether performance attribution is available for a particular index
 */
export const getPerformanceContributionAvailable = (slug: string): Promise<boolean> =>
  request({
    url: `/performance-attribution/${slug}`,
    method: 'GET',
  }).then((o): boolean => o.data);

/**
 * Use this function in order to get the available dates
 * for performance attribution for a particular index
 */
export const getPerformanceContributionDates = (slug: string): Promise<string[]> =>
  request({
    url: `/performance-attribution/${slug}/dates`,
    method: 'GET',
  }).then((o): string[] => o.data);

/**
 * Use this function to get all the performance attribution data
 */
export const getPerformanceContributionData = (
  data: PerformanceContributionRequestDTO,
): Promise<PerformanceContributionResponseDTO> =>
  request({
    url: `/performance-attribution`,
    method: 'POST',
    data,
  }).then((o): PerformanceContributionResponseDTO => o.data);

export const getPdf = async (
  code: string,
  url: string,
  params: {
    startDate: string;
    endDate: string;
    groupFilter?: string;
    rankingFilter?: string;
    locale?: Locales;
  },
  indexName: string,
): Promise<void> => {
  const dto = await request({
    url: `/performance-attribution/${code}/pdf`,
    method: 'post',
    data: {
      url,
      params,
      options: defaultPdfOptions,
    },
  }).then((o): FileSignedUrlDTO => o.data);

  if (!dto.signedURL) return;
  checkBlockPopup();

  await FileService.downloadWithCloudFrontUrl(dto.signedURL, 'pdf', {
    tool: RouteName.PERFORMANCE_ATTRIBUTION,
    indexName,
    date: params.endDate,
  });
};

/**
 * Returns the performance contribution configuration for a given index
 */
export const getPerformanceContributionConfig = (slug: string): Promise<PerfContributionOptionsResponseDTO> =>
  request({
    url: `/performance-attribution/${slug}/config`,
    method: 'GET',
  }).then((o): PerfContributionOptionsResponseDTO => o.data);

export const getPerformanceContributionAvailableIndices = (): Promise<string[]> => {
  return request({
    url: `/performance-attribution/available-indices`,
    method: 'GET',
  }).then((o): string[] => o.data);
};
