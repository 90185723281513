import { ComputedRef, Ref, computed, onMounted, ref, watch } from 'vue';
import { useRouteRef, useRouter } from './useRouter';
import useAppMetrics from '@/composables/useAppMetrics';
import { TRACKING_BUTTON_NAME } from '@/types/analytics';
import { RouteName } from '@/constants/RouteName';
import {
  Article,
  Category,
  EditingStatus,
  KnowledgeBaseCategory,
  KnowledgeBaseMode,
  PublishingStatus,
} from '@/types/KnowledgeBase';
import { useScroll } from '@vueuse/core';
import { flatten } from 'lodash';
import { notNull } from '@/utils/notnull';
import useEnv from './useEnv';

const { isBofAEnvironment } = useEnv();
/**
 * This is the metadata of the knowledge base articles
 * which described the category, publishing status, editing status
 * and the article metadata including id, url and title and status
 *
 * Note that the metadata depends on the notion articles files
 * which are located in the /public/notion folder
 *
 * The process of generating the below metadata should be automated
 * from the csv file below (TBD)
 *
 * Core:
 * @see /public/notion/core/Knowledge%20Base%20Articles%2042d8cc26251c4d05ad6f292576caec62.csv
 *
 * BofA:
 * @see /public/notion/bofa/Knowledge%20Base%20NOVA%201b9c98b10a97808aac75eeda507cad91.csv
 */

const bofaMetadata: Category[] = [
  {
    category: KnowledgeBaseCategory.GETTING_STARTED,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'get-started-with-nova',
        url: '/notion/bofa/Knowledge%20Base%20NOVA%201b9c98b10a97808aac75eeda507cad91/Get%20Started%20with%20N%20O%20V%20A%201b9c98b10a97810780d6dd78b6b0cfdd.html',
        title: 'Get Started with N.O.V.A',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.POSITION,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'visualize-allocation',
        url: '/notion/bofa/Knowledge%20Base%20NOVA%201b9c98b10a97808aac75eeda507cad91/Visualize%20Allocation%201c1c98b10a9780d9ac67ccddf906c34e.html',
        title: 'Visualize Allocation',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.PORTFOLIO_CONSTRUCTION,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'construct-a-multi-level-portfolio',
        url: '/notion/bofa/Knowledge%20Base%20NOVA%201b9c98b10a97808aac75eeda507cad91/Construct%20a%20Multi-Level%20Portfolio%201b9c98b10a9781d3bb68e4dd312b2ff5.html',
        title: 'Construct a Multi-Level Portfolio',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.MY_LAB,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'integrate-data-into-the-platform',
        url: '/notion/bofa/Knowledge%20Base%20NOVA%201b9c98b10a97808aac75eeda507cad91/Integrate%20Data%20into%20the%20platform%201b9c98b10a9781a4a882f143baa6807e.html',
        title: 'Integrate Data into the platform',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.FACTSHEET,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'generate-reports-with-factsheets',
        url: '/notion/bofa/Knowledge%20Base%20NOVA%201b9c98b10a97808aac75eeda507cad91/Generate%20Reports%20with%20Factsheets%201b9c98b10a9781c1a2b9e81dcb06313e.html',
        title: 'Generate Reports with Factsheets',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
];

const coreMetadata: Category[] = [
  {
    category: KnowledgeBaseCategory.GETTING_STARTED,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'get-started-with-premialab',
        url: '/notion/core/Knowledge%20Base%20Articles%2042d8cc26251c4d05ad6f292576caec62/Get%20started%20with%20Premialab%205220550b312c43b6940fdf079c8c1fc1.html',
        title: 'Get Started with Premialab',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.PURE_FACTORS,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'premialab-pure-factors',
        url: '/notion/core/Knowledge%20Base%20Articles%2042d8cc26251c4d05ad6f292576caec62/Premialab%20Pure%20Factors%207904713ac9994faca0be0d5e1238f42f.html',
        title: 'Premialab Pure Factors',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.MY_LAB,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'integrate-data-onto-the-platform',
        url: '/notion/core/Knowledge%20Base%20Articles%2042d8cc26251c4d05ad6f292576caec62/Integrate%20Data%20Into%20the%20platform%20416acdb0c8454d1d927590868c173e38.html',
        title: 'Integrate Data onto the Platform',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.PORTFOLIO_CONSTRUCTION,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'construct-a-multi-level-portfolio',
        url: '/notion/core/Knowledge%20Base%20Articles%2042d8cc26251c4d05ad6f292576caec62/Construct%20a%20Multi-Level%20Portfolio%2086ae88df992541aeb393a14713e809dc.html',
        title: 'Construct a Multi-Level Portfolio',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.PRINCIPAL_COMPONENT,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'identify-risk-concentration-with-pca',
        url: '/notion/core/Knowledge%20Base%20Articles%2042d8cc26251c4d05ad6f292576caec62/Identify%20Risk%20Concentration%20with%20PCA%206fedadfe41a440dca27cbe6e6dcccdd6.html',
        title: 'Identify Risk Concentration with PCA',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.FACTOR_DECOMPOSITION,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'perform-a-regression-with-factor-decomposition',
        url: '/notion/core/Knowledge%20Base%20Articles%2042d8cc26251c4d05ad6f292576caec62/Perform%20a%20Regression%20with%20Factor%20Decomposition%20014c936de9784b92b39ef0d2253847c0.html',
        title: 'Perform a Regression with Factor Decomposition',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.CONSTITUENT_RISK,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: 'visualise-risk-metrics',
        url: '/notion/core/Knowledge%20Base%20Articles%2042d8cc26251c4d05ad6f292576caec62/Visualise%20Risk%20Metrics%208b34c5d60e2d4e25b855bfbceafc7128.html',
        title: 'Visualise Risk Metrics',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.FACTSHEET,
    publishingStatus: PublishingStatus.PUBLISHED,
    editingStatus: EditingStatus.DRAFT,
    articles: [
      {
        id: ' generate-reports-with-factsheets',
        url: '/notion/core/Knowledge%20Base%20Articles%2042d8cc26251c4d05ad6f292576caec62/Generate%20Reports%20with%20Factsheets%205d98accd8f5f46738450f8737e34ac55.html',
        title: 'Generate Reports with Factsheets',
        publishingStatus: PublishingStatus.PUBLISHED,
        editingStatus: EditingStatus.DRAFT,
      },
    ],
  },
  {
    category: KnowledgeBaseCategory.NOTION,
    publishingStatus: PublishingStatus.ADMIN_ONLY,
    editingStatus: EditingStatus.NOT_STARTED,
    articles: [
      {
        id: 'notion-test-article',
        url: '/notion/core/Knowledge%20Base%20Articles%2042d8cc26251c4d05ad6f292576caec62/Notion%20Test%20Article%20edbb062b2c804d35ab86365eb280418a.html',
        title: 'Notion Test Article',
        publishingStatus: PublishingStatus.ADMIN_ONLY,
        editingStatus: EditingStatus.NOT_STARTED,
      },
    ],
  },
];

const Metadata: Category[] = isBofAEnvironment ? bofaMetadata : coreMetadata;

const category = ref<KnowledgeBaseCategory>(KnowledgeBaseCategory.HELP_CENTER);

const sidebarShown = ref(false);

const headerHtmlElement = ref<HTMLElement | null>(null);

const sidebarHtmlElement = ref<HTMLElement | null>(null);

export function useKnowledgeBase(): {
  mode: ComputedRef<KnowledgeBaseMode>;
  href: ComputedRef<string>;
  article: ComputedRef<Article | null>;
  articles: ComputedRef<(Article & { category: KnowledgeBaseCategory })[]>;
  category: Ref<KnowledgeBaseCategory>;
  sidebarShown: Ref<boolean>;
  headerHtmlElement: Ref<HTMLElement | null>;
  sidebarHtmlElement: Ref<HTMLElement | null>;
  navigateTo: (value: KnowledgeBaseCategory) => void;
  toggleSidebar: () => void;
} {
  const route = useRouteRef();

  const router = useRouter();

  const { y } = useScroll(sidebarHtmlElement, { behavior: 'smooth' });

  const { trackButtonClick, trackTabView } = useAppMetrics();

  /**
   * The mode of the knowledge base page based on the current route
   */
  const mode = computed(() =>
    route.value.name === RouteName.KNOWLEDGE_BASE ? KnowledgeBaseMode.PAGE : KnowledgeBaseMode.SIDEBAR,
  );

  /**
   * The href of the page with the given category in 'page' mode
   */
  const href = computed(
    () =>
      router.resolve({
        name: RouteName.KNOWLEDGE_BASE,
        hash: `#${category.value}`,
      }).href,
  );

  /**
   * The article to be displayed on the page with the given category
   * contains id, url, and title properties
   */
  const article = computed(() => {
    const categoryMetadata = Metadata.find((o) => o.category === category.value);
    if (!categoryMetadata) return null;

    return categoryMetadata.articles[0];
  });

  /**
   * The articles to be displayed on the popular articles section
   * in the landing page which contains id, url, and title properties
   */
  const articles = computed(() =>
    flatten(Metadata.map((o) => o.articles.map((a) => ({ ...a, category: o.category }))).filter(notNull)),
  );

  /**
   * Navigate to the given category
   */
  const navigateTo = (newCategory: KnowledgeBaseCategory) => {
    // only use the router if we're in page mode, to preserve the user's current page
    if (mode.value === KnowledgeBaseMode.PAGE) {
      router.push({ hash: `#${newCategory}` });
    } else {
      // otherwise just switch the category manually
      category.value = newCategory;
    }

    // tracking is done on this level to account for some pages reusing modules
    trackTabView(
      mode.value === KnowledgeBaseMode.PAGE ? 'Knowledge Base Page' : 'Knowledge Base Sidebar',
      category.value === KnowledgeBaseCategory.HELP_CENTER ? RouteName.KNOWLEDGE_BASE : category.value,
      category.value,
    );
  };

  /**
   * Toggle the knowledge base sidebar
   * When closed, we would open the related article if applicable
   */
  const toggleSidebar = () => {
    if (!sidebarShown.value) {
      // redirect to related category
      redirectToCategoryByCurrentPage();

      // track usage
      trackButtonClick(TRACKING_BUTTON_NAME.KNOWLEDGE_BAR_OPEN, {
        pageName: 'Knowledge Base Sidebar',
        subpageName: route.value.name,
      });
    }

    sidebarShown.value = !sidebarShown.value;
  };

  /**
   * Redirected to the related article based on the current route
   */
  const redirectToCategoryByCurrentPage = () => {
    // Open related article based on current route
    switch (route.value.name) {
      case RouteName.DATA:
        category.value = KnowledgeBaseCategory.GETTING_STARTED;
        break;
      case RouteName.PURE_FACTORS:
        category.value = KnowledgeBaseCategory.PURE_FACTORS;
        break;
      case RouteName.MY_LAB:
      case RouteName.DROP_ZONE:
      case RouteName.MORNINGSTAR:
      case RouteName.MY_LAB_PROXY_TABLE:
        category.value = KnowledgeBaseCategory.MY_LAB;
        break;
      case RouteName.ANALYTICS:
      case RouteName.PORTFOLIO_CONSTRUCTION:
        category.value = KnowledgeBaseCategory.PORTFOLIO_CONSTRUCTION;
        break;
      case RouteName.CLUSTERING_ANALYSIS:
        category.value = KnowledgeBaseCategory.PRINCIPAL_COMPONENT;
        break;
      case RouteName.FACTOR_DECOMPOSITION_PORTFOLIO:
      case RouteName.FACTOR_DECOMPOSITION_STRATEGY:
        category.value = KnowledgeBaseCategory.FACTOR_DECOMPOSITION;
        break;
      case RouteName.CONSTITUENT_RISK_PORTFOLIO:
      case RouteName.CONSTITUENT_RISK_STRATEGY:
        category.value = KnowledgeBaseCategory.CONSTITUENT_RISK;
        break;
      case RouteName.STRATEGY_FACTSHEET:
      case RouteName.PORTFOLIO_FACTSHEET:
        category.value = KnowledgeBaseCategory.FACTSHEET;
        break;
      case RouteName.POSITION:
        category.value = KnowledgeBaseCategory.POSITION;
        break;
      default:
        category.value = KnowledgeBaseCategory.HELP_CENTER;
    }
  };

  /**
   * Clear the URL hash when the category is changed
   */
  watch(category, (newVal) => {
    if (newVal === KnowledgeBaseCategory.HELP_CENTER) router.replace({ hash: '' });
  });

  /**
   * Set the category based on the url hash and unset afterwards
   *
   * This is intended to be used on the case where our client request
   * team sends knowledge base page links directly to the users
   *
   * Finally, reset the scroll position to the top with 100ms delay
   */
  onMounted(() => {
    const hash = route.value.hash.slice(1);

    if (hash && hash in KnowledgeBaseCategory) {
      category.value = hash as KnowledgeBaseCategory;
    }

    setTimeout(() => {
      if (mode.value === KnowledgeBaseMode.SIDEBAR) y.value = 0;
      else window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  });

  return {
    mode,
    href,
    article,
    articles,
    category,
    sidebarShown,
    headerHtmlElement,
    sidebarHtmlElement,
    navigateTo,
    toggleSidebar,
  };
}
