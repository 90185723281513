import { PortfolioVersionConstants } from '@/constants/PortfolioVersionConstants';
import { computed, ref, set } from 'vue';
import usePortfolioTree from './usePortfolioTree';
import { isBasketTypePortfolioFn, isConstituentPortfolioFn } from '@/utils/portfolioTree';
import { createGlobalState } from '@vueuse/shared';

export type PortfolioVersionPreferenceStorage = {
  constituent: PortfolioVersionConstants;
  basket: PortfolioVersionConstants;
};

const defaultPreferences = {
  constituent: PortfolioVersionConstants.LIVE,
  basket: PortfolioVersionConstants.LIVE,
};

// We don't use useLocalStorage here because we want to reset to default values after a refresh
const useVersionState = createGlobalState(() => {
  return ref<PortfolioVersionPreferenceStorage>(defaultPreferences);
});

/**
 * Composable to manage the preferences for the portfolio version
 *
 * @param initialParams Initial parameters to set
 * @returns PortfolioVersionPreferenceStorage
 */
export function usePortfolioVersionPreferences(initialParams?: Partial<PortfolioVersionPreferenceStorage>) {
  const sharedPreferences = useVersionState();
  if (initialParams) {
    sharedPreferences.value = { ...defaultPreferences, ...initialParams };
  }

  const { masterPortfolioTree } = usePortfolioTree();
  const isConstituent = isConstituentPortfolioFn(masterPortfolioTree);
  const isBasket = isBasketTypePortfolioFn(masterPortfolioTree);

  /**
   * The version of the portfolio based on the current portfolio type
   * By default, it will be the simulation version
   */
  const version = computed(() => {
    if (isConstituent.value) {
      return sharedPreferences.value.constituent;
    }

    if (isBasket.value) {
      return sharedPreferences.value.basket;
    }

    return PortfolioVersionConstants.SIMULATION;
  });

  /**
   * Set the preference for the portfolio version based on the current portfolio type
   * @param value The value to set the preference to
   */
  const setPortfolioVersionPreference = (value: PortfolioVersionConstants) => {
    if (version.value === value) return;

    if (isConstituent.value) {
      set(sharedPreferences.value, 'constituent', value);
    }

    if (isBasket.value) {
      set(sharedPreferences.value, 'basket', value);
    }
  };

  const isLiveVersion = computed(() => version.value === PortfolioVersionConstants.LIVE);

  const isSimulationVersion = computed(() => version.value === PortfolioVersionConstants.SIMULATION);

  const isProvisionalVersion = computed(() => version.value === PortfolioVersionConstants.PROVISIONAL);

  return {
    version,
    isLiveVersion,
    setPortfolioVersionPreference,
    isSimulationVersion,
    isProvisionalVersion,
  };
}
