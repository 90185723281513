<!-- eslint-disable vue/no-lone-template -->
<template>
  <StandardModal
    id="analysis-tool-modal"
    v-model="isShown"
    size="xl"
    hide-footer
    :on-cancel="onCancel"
    @hidden="resetSelectedToolToCurrentAnalyticsRoute"
  >
    <template>
      <b-row>
        <b-col class="text-center text-ellipsis">
          <h4 class="mb-0 text-primary text-ellipsis">
            <span v-if="selectedIndex">
              {{ translate({ path: 'MODALS.ANALYSIS_TOOL_MODAL.SELECTED' }) }}:
              <span>
                {{ selectedIndexTitle }}
              </span>
            </span>
            <span v-else>
              {{ translate({ path: 'MODALS.ANALYSIS_TOOL_MODAL.SELECT' }) }}
            </span>
          </h4>
        </b-col>
        <b-col class="text-center">
          <h4 class="d-inline-block mb-0 text-primary">
            {{
              translate({
                path: 'MODALS.ANALYSIS_TOOL_MODAL.ANALYSIS_TOOL',
              })
            }}
          </h4>
        </b-col>
      </b-row>
      <hr class="mimic-modal-header-border" />
      <b-row>
        <b-col>
          <b-overlay :show="shouldShowLoading">
            <b-row
              class="mb-1"
              style="height: 74px"
            >
              <b-col
                v-for="indexType in indexTypes"
                :key="indexType.value"
              >
                <b-button
                  block
                  size="sm"
                  :variant="selectedIndexType === indexType.value ? 'info' : 'outline-info'"
                  :disabled="!availableIndexTypes.includes(indexType.value)"
                  @click="changeSelectedIndexType(indexType.value)"
                >
                  {{ indexType.text }}
                </b-button>
              </b-col>
              <b-col
                cols="12"
                class="position-relative"
              >
                <ClearableFormInput
                  ref="searchBox"
                  :search-query.sync="searchQuery"
                  :class-to-use="'mt-2'"
                  :placeholder="translate({ path: 'GLOBAL.PLACEHOLDERS.KEYWORD_SEARCH' })"
                />
              </b-col>
            </b-row>
            <b-row
              no-gutters
              class="mt-2"
            >
              <b-col
                class="bg-light border-grey border-left border-bottom border-top"
                :class="filteredIndices && !filteredIndices.length ? 'border-right' : ''"
              >
                <!-- custom height to match height of other column -->
                <RecycleScroller
                  v-show="filteredIndices && filteredIndices.length"
                  class="border-light border-bottom border-left scrollable"
                  :style="{
                    height: PANEL_HEIGHT,
                    'overflow-x': 'hidden',
                    'overflow-y': 'scroll',
                  }"
                  :items="filteredIndices"
                  :item-size="rowHeight"
                  :key-field="selectedIndexType === RecordType.PORTFOLIOS ? 'slug' : 'id'"
                >
                  <template #default="{ item }">
                    <AnalysisToolModalRowItem
                      :source="item"
                      :selected-index-code="selectedIndexCode"
                      :row-height="rowHeight"
                    />
                  </template>
                </RecycleScroller>
                <div
                  v-show="filteredIndices && !filteredIndices.length"
                  class="text-center py-4"
                  :style="{
                    height: PANEL_HEIGHT,
                  }"
                >
                  <em>{{ translate({ path: 'VIRTUAL_LIST.NO_RESULTS' }) }}</em>
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </b-col>
        <b-col class="d-flex flex-column justify-content-between">
          <div
            v-for="tool of analysisTools"
            :key="tool.routeName"
          >
            <b-button
              :variant="selectedTool !== null && selectedTool.routeName === tool.routeName ? 'info' : 'outline-info'"
              class="px-0"
              block
              @click="changeSelectedTool(tool)"
            >
              <b-row
                no-gutters
                class="text-left"
              >
                <b-col
                  cols="2"
                  class="text-center d-flex align-items-center justify-content-center"
                >
                  <font-awesome-layers functional>
                    <font-awesome-icon
                      icon="fa-thin fa-file"
                      transform="left-4"
                      style="font-size: 50px"
                    />
                    <font-awesome-icon
                      style="font-size: 20px"
                      transform="left-5 down-4"
                      fixed-width
                      :icon="`fa-solid fa-${tool.icon}`"
                    />
                  </font-awesome-layers>
                </b-col>
                <b-col cols="10">
                  <h6 class="pt-2 mb-0">
                    {{ tool.title }}
                  </h6>
                  <span class="pr-2 py-2 d-inline-block">
                    {{ tool.description }}
                  </span>
                </b-col>
              </b-row>
            </b-button>
          </div>
          <div class="d-flex justify-content-center">
            <div class="w-70">
              <b-button
                block
                variant="info"
                size="lg"
                :disabled="shouldShowLoading || !selectedIndex"
                @click="tryToNavigateAway()"
              >
                {{
                  translate({
                    path: 'MODALS.ANALYSIS_TOOL_MODAL.BUTTONS.ANALYSE_NOW',
                  })
                }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </template>
  </StandardModal>
</template>

<script lang="ts">
import { PortfolioItemResponseDTO, StrategyItemResponseDTO } from '@/api-v2/web/discover';
import { PortfolioType } from '@/types/portfolio';
import { UserPermission } from '@/constants/UserPermission';
import { SaveChangesModalResponse, SaveChangesStore } from '@/store/modules/SaveChangesStore';
import { RouteName } from '@/constants/RouteName';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import useUser from '@/composables/useUser';
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import useAnalysisSteps, { useAnalysisStepItems } from '@/composables/useAnalysisSteps';
import { useAllPortfolios, useAllStrategies, useStrategyByType } from '@/composables/queries/useDataDiscovery';
import useTranslation from '@/composables/useTranslation';
import useGlobalEventBus from '@/composables/useGlobalEventBus';
import { searchTextFactory } from '@/utils/search';
import { RecycleScroller } from 'vue-virtual-scroller';
import AnalysisToolModalRowItem from './AnalysisToolModalRowItem.vue';
import { debounce } from 'lodash';
import { useRouteRef, useRouter } from '@/composables/useRouter';
import ClearableFormInput from '@/components/standard-components/ClearableFormInput.vue';
import { DataTypes } from '@/types/setting';
import { hasAnalyticsPermission, isAnalyticsRoute } from '@/composables/useRouteChecks';
import { PORTFOLIO_CONSTRUCTION } from '@/types/analytics/PortfolioConstruction';
import { CLUSTERING_ANALYSIS, CONSTITUENT_RISK } from '@/types/analytics/AnalysisStep';
import { FACTOR_DECOMPOSITION } from '@/types/analytics/FactorDecomposition';
import { sortStrategies } from '@/utils/strategy';
import { sortPortfolios } from '@/utils/portfolio';
import { UserModule } from '@/store/modules/user';
import { useHasPermission } from '@/composables/usePermission';
import { useFeatureFlag } from '@/composables/useFeatureFlag';

interface IAnalysisToolItem {
  title: string;
  routeName: string;
  description: string;
  icon: string;
  permission?: UserPermission[];
  shown: boolean;
}

enum RecordType {
  PORTFOLIOS = 'Portfolios',
  STRATEGIES = 'Strategies',
  OTHERS = 'Others',
}

const PANEL_HEIGHT = '457px';

export default defineComponent({
  name: 'AnalysisToolModal',
  components: {
    FontAwesomeIcon,
    FontAwesomeLayers,
    ClearableFormInput,
    RecycleScroller,
    AnalysisToolModalRowItem,
  },
  setup() {
    const { user } = useUser();
    const { translate } = useTranslation();
    const { eventBus } = useGlobalEventBus();

    const indexTypes = Object.freeze([
      {
        text: translate({
          path: 'GLOBAL.DATABASE_TRANSLATOR.PORTFOLIO',
          pluralIndex: 2,
        }),
        value: RecordType.PORTFOLIOS,
      },
      {
        text: translate({
          path: 'GLOBAL.DATABASE_TRANSLATOR.STRATEGY',
          pluralIndex: 2,
        }),
        value: RecordType.STRATEGIES,
      },
      {
        text: translate({ path: 'GLOBAL.OTHER', pluralIndex: 2 }),
        value: RecordType.OTHERS,
      },
    ]);
    const { activeAnalysisStep } = useAnalysisSteps();

    const itemLimit = 50;

    const searchBox = ref<HTMLInputElement | null>(null);

    const router = useRouter();

    const isShown = ref(false);
    const selectedIndexType = ref<RecordType>(RecordType.PORTFOLIOS);

    const plainStrategies = useStrategyByType(DataTypes.STRATEGY, { enabled: isShown });
    const privateTracks = useStrategyByType(DataTypes.PRIVATE_TRACK, { enabled: isShown });
    const privateFunds = useStrategyByType(DataTypes.FUND, { enabled: isShown });
    const thematics = useStrategyByType(DataTypes.THEMATIC, { enabled: isShown });
    const benchmarks = useStrategyByType(DataTypes.BENCHMARK, { enabled: isShown });
    const marketData = useStrategyByType(DataTypes.MARKET_DATA, { enabled: isShown });

    // no need to sort, only used for loading indicator
    const allStrategies = useAllStrategies({ enabled: isShown });
    const portfolios = useAllPortfolios({ enabled: isShown });

    const otherIndices = computed(() => {
      return sortStrategies([
        ...(privateTracks.data.value ?? []),
        ...(privateFunds.data.value ?? []),
        ...(thematics.data.value ?? []),
        ...(benchmarks.data.value ?? []),
        ...(marketData.data.value ?? []),
      ]);
    });

    const shouldShowLoading = computed(() => allStrategies.isLoading.value || portfolios.isLoading.value);

    const route = useRouteRef();
    const { availableAnalysisStepItems } = useAnalysisStepItems(route, true);

    const portfolioConstructionTool = computed(() => {
      return {
        title: translate({
          path: 'ANALYSIS_STEPS.PORTFOLIO_CONSTRUCTION.NAME',
        }),
        routeName: RouteName.PORTFOLIO_CONSTRUCTION,
        description: translate({
          path: 'ANALYSIS_STEPS.PORTFOLIO_CONSTRUCTION.DESCRIPTION',
        }),
        icon: 'chart-line-up',
        permission: [UserPermission.PORTFOLIO],
        shown: availableAnalysisStepItems.value.includes(PORTFOLIO_CONSTRUCTION),
      };
    });

    const pcaTool = computed(() => {
      return {
        title: translate({
          path: 'ANALYSIS_STEPS.CLUSTERING_ANALYSIS_LEGACY.NAME',
        }),
        routeName: RouteName.CLUSTERING_ANALYSIS,
        description: translate({
          path: 'ANALYSIS_STEPS.CLUSTERING_ANALYSIS_LEGACY.DESCRIPTION',
        }),
        icon: 'chart-column',
        permission: [UserPermission.PCA],
        shown: availableAnalysisStepItems.value.includes(CLUSTERING_ANALYSIS),
      };
    });

    const regressionTool = computed(() => {
      return {
        title: translate({
          path: 'ANALYSIS_STEPS.FACTOR_DECOMPOSITION_LEGACY.NAME',
        }),
        routeName:
          selectedIndexType.value === RecordType.PORTFOLIOS
            ? RouteName.FACTOR_DECOMPOSITION_PORTFOLIO
            : RouteName.FACTOR_DECOMPOSITION_STRATEGY,
        description: translate({
          path: 'ANALYSIS_STEPS.FACTOR_DECOMPOSITION_LEGACY.DESCRIPTION',
        }),
        icon: 'chart-area',
        permission: [UserPermission.REGRESSION],
        shown: availableAnalysisStepItems.value.includes(FACTOR_DECOMPOSITION),
      };
    });

    const constituentTool = computed(() => {
      return {
        title: translate({ path: 'ANALYSIS_STEPS.CONSTITUENT_RISK.NAME' }),
        routeName: RouteName.CONSTITUENT_RISK_PORTFOLIO,
        description: translate({
          path: 'ANALYSIS_STEPS.CONSTITUENT_RISK.DESCRIPTION',
        }),
        icon: 'chart-pie',
        permission: [UserPermission.CONSTITUENT],
        shown: availableAnalysisStepItems.value.includes(CONSTITUENT_RISK),
      };
    });

    const selectedTool = ref<IAnalysisToolItem | null>(null);
    const tools = computed((): IAnalysisToolItem[] =>
      [portfolioConstructionTool.value, pcaTool.value, regressionTool.value, constituentTool.value].filter(
        (t) => t.shown,
      ),
    );

    const firstToolWPermission = computed((): IAnalysisToolItem | null => {
      if (!UserModule.user) return null;

      const tool = hasAnalyticsPermission({ user: UserModule.user, currentRoute: route.value });
      if (tool) {
        switch (tool) {
          case RouteName.PORTFOLIO_CONSTRUCTION:
            return portfolioConstructionTool.value;
          case RouteName.FACTOR_DECOMPOSITION_PORTFOLIO:
            return regressionTool.value;
          case RouteName.CLUSTERING_ANALYSIS:
            return pcaTool.value;
          case RouteName.CONSTITUENT_RISK_PORTFOLIO:
            return constituentTool.value;
          default:
            return null;
        }
      }

      return null;
    });

    /**
     * Changes the selected tool in the modal to the current analysis substep
     *
     * Returns true if the tool was reset, otherwise it returns false
     */
    const resetSelectedToolToCurrentAnalyticsRoute = () => {
      if (route.value.name && isAnalyticsRoute(route.value.name)) {
        const tool = tools.value.find((t) => t.routeName === route.value.name);
        if (tool) {
          selectedTool.value = tool;
          return true;
        }
      }
      return false;
    };

    watch(route, resetSelectedToolToCurrentAnalyticsRoute);

    watch(
      firstToolWPermission,
      () => {
        const wasSetToCurrentRoute = resetSelectedToolToCurrentAnalyticsRoute();
        if (wasSetToCurrentRoute) {
          return;
        }

        // if it wasn't set to the current route, set it to the first tool with permission
        selectedTool.value = firstToolWPermission.value;
      },
      { immediate: true },
    );

    const availableIndexTypes = computed<RecordType[]>(() => {
      if (!selectedTool.value) return [];
      if (
        selectedTool.value.routeName === RouteName.FACTOR_DECOMPOSITION_PORTFOLIO ||
        selectedTool.value.routeName === RouteName.FACTOR_DECOMPOSITION_STRATEGY
      ) {
        return [RecordType.PORTFOLIOS, RecordType.STRATEGIES, RecordType.OTHERS];
      }
      return [RecordType.PORTFOLIOS];
    });
    watch(availableIndexTypes, (newVal: RecordType[]) => {
      if (!newVal.includes(selectedIndexType.value)) {
        selectedIndex.value = null;
        selectedIndexType.value = newVal[0];
      }
    });

    const possibleIndices = computed(() => {
      if (selectedIndexType.value === RecordType.PORTFOLIOS) {
        return sortPortfolios(availablePortfolios.value ?? []);
      }
      if (selectedIndexType.value === RecordType.STRATEGIES) {
        return sortStrategies(plainStrategies.data.value ?? []);
      }
      if (selectedIndexType.value === RecordType.OTHERS) {
        // already sorted
        return otherIndices.value;
      }
      return [];
    });

    const filteredIndices = computed(() => {
      if (selectedIndexType.value === RecordType.PORTFOLIOS) {
        return (possibleIndices.value as PortfolioItemResponseDTO[]).filter(
          (o) => o.name && o.name.toLowerCase().includes(_searchQuery.value.toLowerCase()),
        );
      }
      return (possibleIndices.value as StrategyItemResponseDTO[]).filter((o) =>
        [o.code, o.shortName, o.longName].some(searchTextFactory(_searchQuery.value.toLowerCase())),
      );
    });

    const searchQuery = ref('');
    const _searchQuery = ref('');

    const updateSearchQueryToFilterResults = debounce((newVal: string) => (_searchQuery.value = newVal), 200, {
      trailing: true,
    });
    watch(searchQuery, updateSearchQueryToFilterResults);

    const selectedIndex = ref<StrategyItemResponseDTO | PortfolioItemResponseDTO | null>(null);
    const selectedIndexCode = computed<string | null>(() => {
      if (selectedIndex.value === null) return null;

      if ('slug' in selectedIndex.value) return selectedIndex.value.slug;
      return selectedIndex.value.code;
    });
    const selectedIndexTitle = computed(() => {
      if (selectedIndex.value === null) return '';

      if ('name' in selectedIndex.value) return selectedIndex.value.name;
      return selectedIndex.value.shortName;
    });

    const analysisTools = computed(() => {
      const { permission = [] } = user.value || {};
      return tools.value.filter((o) => !o.permission || o.permission.some((p) => permission.includes(p)));
    });

    watch(activeAnalysisStep, (newVal) => {
      const newTool = tools.value.find((t) => t.routeName === newVal.baseRoute || t.routeName === newVal.legacyRoute);

      if (newTool) {
        selectedTool.value = newTool;
      }
    });

    const demoPortfolioName = 'demo_portfolio';

    const hasConstituentPermission = useHasPermission(UserPermission.CONSTITUENT);
    const { hasEquityBasketAccess, hasQISBasketAccess, hasConstituentRiskSimulationAccess } = useFeatureFlag();

    const availablePortfolios = computed(() => {
      if (!selectedTool.value) return [];

      // Only show constituent portfolios when the user selects constituent tool but doesn't hasConstituentRiskSimulationAccess
      if (
        selectedTool.value.routeName === constituentTool.value.routeName &&
        !hasConstituentRiskSimulationAccess.value
      ) {
        return sortPortfolios((portfolios.data.value ?? []).filter((p) => p.type === PortfolioType.CONSTITUENT));
      }

      return sortPortfolios(
        (portfolios.data.value ?? []).filter((p) => {
          const allowedTypes = [PortfolioType.PORTFOLIO, PortfolioType.START_FRESH];
          if (hasEquityBasketAccess.value) allowedTypes.push(PortfolioType.EQUITY_BASKET);
          if (hasQISBasketAccess.value) allowedTypes.push(PortfolioType.QIS_BASKET);
          if (hasConstituentPermission.value) allowedTypes.push(PortfolioType.CONSTITUENT);

          return allowedTypes.includes(p.type) && !p.slug.includes(demoPortfolioName);
        }),
      );
    });

    const tryToNavigateAway = async () => {
      const result = await SaveChangesStore.WaitForSaveChangesModalResponse();
      if (result === SaveChangesModalResponse.CANCEL) {
        return;
      }
      goToAnalysisStep();
    };

    const goToAnalysisStep = () => {
      if (!selectedTool.value) return;
      if (!selectedIndex.value) return;

      const recordType = selectedIndexType.value;
      const slugOrCode = 'code' in selectedIndex.value ? selectedIndex.value.code : selectedIndex.value.slug;

      if (
        selectedTool.value.routeName === RouteName.FACTOR_DECOMPOSITION_PORTFOLIO ||
        selectedTool.value.routeName === RouteName.FACTOR_DECOMPOSITION_STRATEGY
      ) {
        goToFactorRegression(recordType as RecordType, slugOrCode);
      } else {
        router
          .push({
            name: selectedTool.value.routeName,
            params: { indexUniqueIdentifier: slugOrCode },
          })
          .catch((e) => console.error(e));
      }

      isShown.value = false;
    };

    const goToFactorRegression = (recordType: RecordType, slugOrCode: string) => {
      const isPortfolioRegression = recordType === RecordType.PORTFOLIOS;
      router.push({
        name: isPortfolioRegression
          ? RouteName.FACTOR_DECOMPOSITION_PORTFOLIO
          : RouteName.FACTOR_DECOMPOSITION_STRATEGY,
        params: {
          indexUniqueIdentifier: slugOrCode,
        },
      });
    };

    const selectIndex = (index: StrategyItemResponseDTO | PortfolioItemResponseDTO) => {
      selectedIndex.value = index;
    };

    const showModal = (toolToSelect = RouteName.CONSTITUENT_RISK_PORTFOLIO) => {
      switch (toolToSelect) {
        case RouteName.PORTFOLIO_CONSTRUCTION: {
          selectedTool.value = portfolioConstructionTool.value;
          break;
        }
        case RouteName.FACTOR_DECOMPOSITION_PORTFOLIO: {
          selectedTool.value = regressionTool.value;
          break;
        }
        case RouteName.CLUSTERING_ANALYSIS: {
          selectedTool.value = pcaTool.value;
          break;
        }
        default: {
          selectedTool.value = constituentTool.value;
        }
      }
      isShown.value = true;

      setTimeout(() => {
        searchBox.value?.focus();
      });
    };

    const onNewIndexSelection = (newSelection: StrategyItemResponseDTO | PortfolioItemResponseDTO) => {
      selectedIndex.value = newSelection;
    };

    onMounted(() => {
      eventBus.on('show-analysis-tool-modal', showModal);
      eventBus.on('analysis-tool-modal-new-selection', onNewIndexSelection);
    });

    onBeforeUnmount(() => {
      eventBus.off('show-analysis-tool-modal', showModal);
      eventBus.off('analysis-tool-modal-new-selection', onNewIndexSelection);
    });

    const changeSelectedTool = (tool: IAnalysisToolItem) => {
      selectedTool.value = tool;
    };

    const changeSelectedIndexType = (indexType: RecordType) => {
      selectedIndexType.value = indexType;
      changeSelectedTool(regressionTool.value);
    };

    const onCancel = () => {};

    return {
      tryToNavigateAway,
      itemLimit,
      isShown,
      shouldShowLoading,
      indexTypes,
      availableIndexTypes,
      selectedIndexType,
      searchQuery,
      translate,
      selectedIndex,
      analysisTools,
      filteredIndices,
      selectIndex,
      activeAnalysisStep,
      selectedTool,
      changeSelectedTool,
      changeSelectedIndexType,
      selectedIndexCode,
      selectedIndexTitle,
      onCancel,
      RecordType,
      searchBox,
      PANEL_HEIGHT,
      rowHeight: 58, // px
      resetSelectedToolToCurrentAnalyticsRoute,
    };
  },
});
</script>
