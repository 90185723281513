import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { AnalysisStep } from '@/types/analytics/AnalysisStep';
import { AnalyticsGroupBy } from '@/constants/AnalyticsGroupBy';
import { PORTFOLIO_CONSTRUCTION } from '@/types/analytics/PortfolioConstruction';
import { IItemUnderAnalysis } from '@/types/IItemUnderAnalysis';
import { PeriodModule } from './PeriodStore';
import { DateFormat } from '@/constants/DateFormat';
import { convertToBusiness, createDate } from '@/utils/dateUtils';
import { PortfolioModule } from './PortfolioStore';
import {
  IPortfolioTreeSubportfolio,
  IPortfolioTreeStrategy,
  isPortfolioTreeStrategy,
  IPortfolioTree,
} from '@/types/IPortfolioTree';
import { isConstituentPortfolioFn } from '@/utils/portfolioTree';
import { WeightingTypeConstants } from '@/types/portfolio/AllocationWeightingConstants';
import { IStrategy } from '@/types/strategy';
import { isStrategy } from '@/utils/strategy';
import useTranslation from '@/composables/useTranslation';
import useFxConversion from '@/composables/useFxConversion';
import useSystemLoading from '@/composables/useSystemLoading';
import { LoadingItems } from '@/constants/LoadingItems';
import { FxConversion } from '@/constants/FxConversion';
import { clone } from 'lodash';
import { useToasts } from '@/composables/useToasts';
import usePeriod from '@/composables/usePeriod';
import { PortfolioControllerConfig } from '@/types/PortfolioControllerConfig';
import { Route } from 'vue-router';
import usePortfolioTree from '@/composables/usePortfolioTree';

const getLiveDateForStrategy = (item: IStrategy | IPortfolioTreeStrategy): string | null => {
  if (isStrategy(item)) {
    return item.inceptionDate;
  }

  if (item.strategy) {
    return item.strategy.inceptionDate;
  }

  return null;
};

const getDisabledBeforeDateForStrategy = (item: IStrategy | IPortfolioTreeStrategy): string | null => {
  // We want to pick the nearest date
  if (isStrategy(item)) {
    return item.effectiveHistoryStartDate &&
      createDate(item.effectiveHistoryStartDate) > createDate(item.historyStartDate)
      ? item.effectiveHistoryStartDate
      : item.historyStartDate;
  }

  if (item.strategy) {
    return item.strategy.effectiveHistoryStartDate &&
      createDate(item.strategy.effectiveHistoryStartDate) > createDate(item.strategy.historyStartDate)
      ? item.strategy.effectiveHistoryStartDate
      : item.strategy.historyStartDate;
  }

  return null;
};

/**
 * The logic of this function has changed significantly as of 17 Feb 2023.
 *
 * As of that date, the disabledBeforeDate is calculated from the member strategies'
 * effectiveHistoryStartDate (i.e., dynamically from the member strategies).
 *
 * In this case, the disabledBeforeDate refers to the first common date that all the strategies in the portfolio have.
 *
 * The logic of the disabledBeforeDate will change slightly in the future, when users will be able to define it with
 * uploaded snapshots, as opposed to using the effectiveHistoryStartDate.
 * But by that time, the API should be changed so we aren't handling all this logic on the front end :)
 *
 * Before 17 Feb 2023, portfolios with backtest dates would have a disabledBeforeDate of the first backtest date
 * available, and the Live Date would be the user defined one on the master portfolio tree.
 */
const getDisabledBeforeDateForSubportfolio = (subportfolio: IPortfolioTreeSubportfolio) => {
  let disabledBeforeDate = subportfolio.historyStartDate;

  // We want to pick the nearest date
  if (createDate(subportfolio.effectiveHistoryStartDate) > createDate(disabledBeforeDate)) {
    disabledBeforeDate = subportfolio.effectiveHistoryStartDate;
  }

  return disabledBeforeDate;
};

/**
 * Given a portfolio with weighting type 'Custom', check if the given portfolio has backtest dates and has no customInceptionDate,
 * and if so sets the current Live Date to the first available one.
 *
 * In this case, the Live Date refers to the first 'real' date that the portfolio has been invested in.
 *
 * New custom portfolios now have the customInceptionDate by default, this function is to handle older custom portfolios.
 */
const handleLiveDateForCustomPortfolios = async (
  subportfolio: IPortfolioTreeSubportfolio,
  draft: IPortfolioTree,
  disabledBeforeDate: string,
  suppressErrors: boolean,
) => {
  if (
    draft.allocation[subportfolio.portfolioTreeId].weighting.type !== WeightingTypeConstants.CUSTOM ||
    subportfolio.customInceptionDate ||
    !subportfolio.backtestDates
  ) {
    return;
  }

  // We want to get the earliest backtest date available
  const firstBacktestDate = clone(subportfolio.backtestDates).sort()[0];
  const firstAvailableBacktestDate = convertToBusiness(createDate(firstBacktestDate), true).toFormat(
    DateFormat.YYYY_MM_DD,
  );

  if (!(createDate(firstBacktestDate) < createDate(disabledBeforeDate))) {
    const liveDate = firstAvailableBacktestDate;
    await PeriodModule.SetLivePeriod({ date: liveDate, suppressErrors });
  }
};

@Module({ dynamic: true, store, name: 'AnalyticsStore' })
class AnalyticsStore extends VuexModule {
  public activeAnalysisStep: AnalysisStep = PORTFOLIO_CONSTRUCTION;
  public activeGroupBy: AnalyticsGroupBy = AnalyticsGroupBy.STRATEGY;
  public isLegendModeActive = false;

  public hasDriftedBeenSelected = false;
  public navigationPanelConfig: PortfolioControllerConfig = {
    shouldColorChildren: true,
    shouldColorChildrenRecursively: false,
    canMakeStrategyActiveItem: false,
    onFirstRouteChange: (_route: Route) => {},
  };

  public allocationModalOpened = false;
  public allocationModalPortfolioId: string | null = null;

  public itemUnderAnalysis: IItemUnderAnalysis | null = null;

  public numDecimalPoints = 2;

  @Mutation
  private SET_NAVIGATION_PANEL_CONFIG(newVal: PortfolioControllerConfig): void {
    this.navigationPanelConfig = newVal;
  }
  @Action({ rawError: true })
  public async SetNavigationPanelConfig(newVal: PortfolioControllerConfig): Promise<void> {
    this.SET_NAVIGATION_PANEL_CONFIG(newVal);
  }

  @Mutation
  private SET_ITEM_UNDER_ANALYSIS({ newVal }: { newVal: IItemUnderAnalysis | null }): void {
    this.itemUnderAnalysis = newVal;
  }

  @Action({ rawError: true })
  public async SetAvailablePeriodsForItemUnderAnalysis({
    newVal,
    draft,
    triggerWarningIfPeriodChanges,
    suppressErrors,
  }: {
    newVal: IItemUnderAnalysis;
    draft?: IPortfolioTree;
    triggerWarningIfPeriodChanges: boolean;
    suppressErrors: boolean;
  }): Promise<void> {
    if (isStrategy(newVal) || isPortfolioTreeStrategy(newVal)) {
      await this.SetAvailablePeriodsForStrategy({ newVal, triggerWarningIfPeriodChanges, suppressErrors });
      return;
    }

    await this.SetAvailablePeriodsForPortfolio({
      subportfolio: newVal,
      draft,
      triggerWarningIfPeriodChanges,
      suppressErrors,
    });
  }

  /**
   * setPeriod parameter should be removed once the Navigation Panel
   * has been integrated into the factsheets
   */
  @Action({ rawError: true })
  public async SetItemUnderAnalysis({
    newVal,
    draft,
    triggerWarningIfPeriodChanges = false,
    suppressErrors = false,
    setPeriod = true,
    showLoading = true,
  }: {
    newVal: IItemUnderAnalysis;
    draft?: IPortfolioTree;
    triggerWarningIfPeriodChanges?: boolean;
    suppressErrors?: boolean;
    setPeriod?: boolean;
    showLoading?: boolean;
  }): Promise<void> {
    const { addLoadingItem, removeLoadingItem } = useSystemLoading();
    const { setFxType, setToCurrency, changeFxConversionParam } = useFxConversion();

    if (setPeriod) {
      if (showLoading) addLoadingItem(LoadingItems.ITEM_UNDER_ANALYSIS_DATE);
      await this.SetAvailablePeriodsForItemUnderAnalysis({
        newVal,
        draft,
        triggerWarningIfPeriodChanges,
        suppressErrors,
      });
      if (showLoading) removeLoadingItem(LoadingItems.ITEM_UNDER_ANALYSIS_DATE);
    }

    this.SET_ITEM_UNDER_ANALYSIS({ newVal });

    if (isStrategy(newVal)) {
      await PortfolioModule.SetPortfolioTreeUnderAnalysis({ portfolioTree: null });
      setToCurrency(newVal.currency);
      setFxType(FxConversion.UNHEDGED);
      return;
    }

    if (!draft) return;
    const isConstituentPortfolio = isConstituentPortfolioFn(draft);

    // For a constituent portfolio, ensure that the fx type is not converted
    if (isConstituentPortfolio.value) {
      draft.portfolioTree.fxType = FxConversion.NOT_CONVERTED;
    }

    const toCurrency = draft.portfolioTree.toCurrency;
    const fxType = draft.portfolioTree.fxType;

    // For portfolios that have their own portfolio currency, make sure that all children are converted to the new root currency and fx type
    changeFxConversionParam(draft.portfolioTree, {
      fxType: fxType,
      toCurrency,
    });

    if (toCurrency) setToCurrency(toCurrency);
    if (fxType) setFxType(fxType);
  }

  @Mutation
  public OPEN_ALLOCATION_MODAL(portfolioId: string): void {
    this.allocationModalPortfolioId = portfolioId;
    this.allocationModalOpened = true;
  }

  @Mutation
  public CLOSE_ALLOCATION_MODAL() {
    this.allocationModalOpened = false;
  }

  @Mutation
  private SET_IS_LEGEND_MODE_ACTIVE(newVal: boolean): void {
    this.isLegendModeActive = newVal;
  }
  @Action({ rawError: true })
  public async SetIsLegendModeActive(newVal: boolean): Promise<void> {
    this.SET_IS_LEGEND_MODE_ACTIVE(newVal);
  }

  @Mutation
  private SET_HAS_DRIFTED_BEEN_SELECTED({ newVal }: { newVal: boolean }): void {
    this.hasDriftedBeenSelected = newVal;
  }
  @Action({ rawError: true })
  public async SetHasDriftedBeenSelected({ newVal }: { newVal: boolean }): Promise<void> {
    this.SET_HAS_DRIFTED_BEEN_SELECTED({ newVal });
  }

  @Mutation
  private SET_ACTIVE_ANALYSIS_STEP(name: AnalysisStep): void {
    this.activeAnalysisStep = name;
  }
  @Action({ rawError: true })
  public async SetActiveAnalysisStep(name: AnalysisStep): Promise<void> {
    this.SET_ACTIVE_ANALYSIS_STEP(name);
  }

  @Mutation
  private SET_GROUP_BY(name: AnalyticsGroupBy): void {
    this.activeGroupBy = name;
  }
  @Action({ rawError: true })
  public async SetGroupBy(name: AnalyticsGroupBy): Promise<void> {
    this.SET_GROUP_BY(name);
  }

  /**
   * This function does a few things:
   * 1. It sets the Live Date for the Portfolio. This happens BEFORE
   *    setting the disabledBeforeDate because if the disabledBeforeDate
   *    invalidates the currentPeriod (i.e., the disabledBeforeDate is
   *    after the current analysis fromDate) then a new period must be set.
   *    We must know what the Live Date is at that point so that we can choose it if applicable.
   * 2. Then it sets the disabledBeforeDate (which can also change the current
   *    period, as described in Step 1)
   * 3. Then it sets the disabledAfterDate
   */
  @Action({ rawError: true })
  public async SetAvailablePeriodsForPortfolio({
    subportfolio,
    draft,
    triggerWarningIfPeriodChanges,
    suppressErrors,
  }: {
    subportfolio: IPortfolioTreeSubportfolio;
    draft?: IPortfolioTree;
    triggerWarningIfPeriodChanges: boolean;
    suppressErrors: boolean;
  }): Promise<void> {
    const { previousBusinessDay } = usePeriod();
    const { errorToast } = useToasts();
    const { translate } = useTranslation();
    const { isPortfolioYoung } = usePortfolioTree();

    const shouldSuppressErrors = suppressErrors || isPortfolioYoung(draft);

    if (subportfolio.flattenedActiveCodes && !subportfolio.flattenedActiveCodes.length && !shouldSuppressErrors) {
      errorToast(translate({ path: 'ERROR.NO_ACTIVE_STRATEGIES' }));
      suppressErrors = true;
    }

    await PeriodModule.SetLivePeriod({ date: subportfolio.inceptionDate ?? null, suppressErrors });

    // The draft is safely passed in to this function for all portfolio cases, so this should never occur
    if (!draft) {
      console.error('MISSING DRAFT...');
      return;
    }

    const disabledBeforeDate = getDisabledBeforeDateForSubportfolio(subportfolio);
    if (!disabledBeforeDate) {
      console.error(translate({ path: 'ERROR.DISABLED_BEFORE_DATE_UNDEFINED' }));
      return;
    }

    handleLiveDateForCustomPortfolios(subportfolio, draft, disabledBeforeDate, suppressErrors);

    await PeriodModule.SetDisabledBeforeDate({
      date: disabledBeforeDate,
      triggerWarning: triggerWarningIfPeriodChanges,
    });
    await PeriodModule.SetDisabledAfterDate(previousBusinessDay.value);
  }

  @Action({ rawError: true })
  public async SetAvailablePeriodsForStrategy({
    newVal,
    triggerWarningIfPeriodChanges,
    suppressErrors,
  }: {
    newVal: IStrategy | IPortfolioTreeStrategy;
    triggerWarningIfPeriodChanges: boolean;
    suppressErrors: boolean;
  }): Promise<void> {
    const { previousBusinessDay } = usePeriod();
    const { translate } = useTranslation();

    const liveDate = getLiveDateForStrategy(newVal);
    const disabledBeforeDate = getDisabledBeforeDateForStrategy(newVal);

    await PeriodModule.SetLivePeriod({ date: liveDate, suppressErrors });

    if (!disabledBeforeDate) {
      console.error(translate({ path: 'ERROR.DISABLED_BEFORE_DATE_UNDEFINED' }));
      return;
    }

    await PeriodModule.SetDisabledBeforeDate({
      date: disabledBeforeDate,
      triggerWarning: triggerWarningIfPeriodChanges,
    });
    await PeriodModule.SetDisabledAfterDate(previousBusinessDay.value);
  }
}

export default getModule(AnalyticsStore);
