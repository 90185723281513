import Vue, { nextTick } from 'vue';
import Router, { Route } from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Bowser from 'bowser';
import Cookies from 'js-cookie';
import { PositionResult } from 'vue-router/types/router';
import useRightSidebar from '@/composables/useRightSidebar';
import { FACTOR_DECOMPOSITION_ROLLING } from '@/types/analytics/FactorDecomposition';
import { RouteName } from '@/constants/RouteName';
import { getRoutes } from './routes';
import { UserPermission } from '@/constants/UserPermission';
import { checkRoutePermission, collectRoutePermission } from '@/utils/permission';
import { useIsPdf } from '@/composables/usePdf';
import { useFeatureFlag } from '@/composables/useFeatureFlag';
import { SKIP_AUTH_ROUTES } from '@/auth';
import { closeApplicationSidebar } from '@/composables/useApplicationSidebar';
import useGlobalEventBus from '@/composables/useGlobalEventBus';
import { UserModule } from '@/store/modules/user';
import { ClientConstants } from '@/constants/ClientConstants';

NProgress.configure({ showSpinner: false });

/**
 * Update to true to debug routing
 */
const DEBUG = false;

Vue.use(Router);

/*
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    hidden: true                 if true, this route will not show in the sidebar (default is false)
  }
*/

const router = new Router({
  mode: 'history', // Enable this if you need.
  scrollBehavior: (to, from, savedPosition): PositionResult | Promise<PositionResult> | null | undefined => {
    const onlyHashChanged = to.path === from.path && to.hash !== from.hash;
    if (onlyHashChanged) {
      // don't scroll
      return null;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return savedPosition || { x: 0, y: 0 };
  },
  base: '/',
  routes: getRoutes(),
});

const isPdf = useIsPdf();

const bowser: Bowser.Parser.ParsedResult = Bowser.parse(window.navigator.userAgent);

const browser: Bowser.Parser.Details = bowser.browser;

const os: Bowser.Parser.OSDetails = bowser.os;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getUserInfoForAnalytics = (): { [key: string]: string | number | undefined } | undefined => {
  const user = UserModule.user;

  if (user) {
    const userInfo = {
      userId: user.id,
      userName: user.firstname + ' ' + user.lastname,
      userTeamId: user.teamId,
      userEmail: user.email,
      userCountry: user.country,
      userTeamName: user.company,
    };

    const sessionInfo = {
      userEnvironment: window.location.hostname,
      userBrowser: browser.name,
      userBrowserVersion: browser.version,
      userOs: os.name,
      userOsVersion: os.version,
    };

    return Object.assign(userInfo, sessionInfo);
  }
};

const createPerformanceMark = (name: string): void => {
  if (performance.measure === undefined) {
    return;
  }
  performance.mark(name);
};

const discoverLoadMetricName = 'load-Scatter';

const { setShouldShowSidebar } = useRightSidebar();

const trackPortfolioIds = async (cookieName: string): Promise<void> => {
  createPerformanceMark(`${discoverLoadMetricName}-initial-start`);
  // TODO: Move this to the discover setup function when the discover page gets moved to CAPI
  // const portfolioIds = PortfolioModule.allPortfolios.map(
  //   (portfolio): number => portfolio.id
  // );
  // track(ACCEPTED_TRACKED_TITLES.USER_INFORMATION, {
  //   pageName: 'Discover',
  //   portfolioIds: portfolioIds,
  // });
  Cookies.set(cookieName, 'true');
};

// Start progress bar
router.beforeEach((_to, from, next) => {
  if (isPdf.value) {
    next();
    return;
  }

  NProgress.start();
  next();
});

// Get user info, if required
router.beforeEach(async (to, from, next) => {
  if (isPdf.value) {
    await UserModule.GetUserInfo();
    next();
    return;
  }

  if (DEBUG) console.log({ to, from });

  if (SKIP_AUTH_ROUTES.has(to.name)) {
    next();
    return;
  }

  if (UserModule.user != null) {
    next();
    return;
  }

  try {
    await UserModule.GetUserInfo();
  } catch {
    // We are probably unauthenticated (not logged in, not unauthorized)
    // Halt routing here. AuthRoot should be handling this case from here
  }
  UserModule.IdentifyFullstory();
  const cookieName = 'premialab-has-logged-in';
  const hasAccessedPageBefore = Cookies.get(cookieName);

  const { homePageRoute } = useFeatureFlag();

  if (to.name === homePageRoute.value) {
    if (!hasAccessedPageBefore) {
      trackPortfolioIds(cookieName);
    } else createPerformanceMark(`${discoverLoadMetricName}-start`);
  }

  next();

  /**
   * if user is navigating away from analytics page,
   * notify Navigation Panel to reset the itemUnderAnalysis back to the root portfolio
   */
  const { eventBus } = useGlobalEventBus();

  if (from.path.startsWith('/analytics') && !to.path.startsWith('/analytics')) {
    eventBus.emit('reset-item-under-analysis');
  }
});

// Setup shouldShowRightSidebar
router.beforeEach((to, from, next) => {
  if (isPdf.value) {
    next();
    return;
  }

  const shouldShowRightSidebar =
    (to.name === RouteName.FACTOR_DECOMPOSITION_PORTFOLIO || to.name === RouteName.FACTOR_DECOMPOSITION_STRATEGY) &&
    to.params &&
    to.params.substep === FACTOR_DECOMPOSITION_ROLLING.path;

  setShouldShowSidebar(shouldShowRightSidebar);
  next();
});

// Check for permission
router.beforeEach(async (to, from, next) => {
  if (isPdf.value) {
    next();
    return;
  }

  const permissions = collectRoutePermission(to);
  if (permissions.length === 0) {
    // Empty array = no permission check required
    // Check before calling `checkRoutePermission` to handle some case where
    // User is not available
    next();
    return;
  }

  const { user } = UserModule;
  if (user == null) {
    // Haven't load user object. Not proceeding with the routing.
    // This will be null if either:
    // - We are going to a auth-related route
    // - GET /user failed to load
    //
    // Both cases should be handled by auth root
    console.log('Router middleware: User info not loaded. Auth root will handle this');
    return;
  }

  const userPermission = new Set(user.permission);
  const granted = checkRoutePermission(userPermission, permissions);

  if (granted) {
    next();
    return;
  }

  // Unauthorized
  const isAdminPage = permissions.includes(UserPermission.ADMIN);
  next({
    // Pretend the page does not exist for admin pages.
    //
    // The unauthorized text (contact us to upgrade your account) does not make
    // sense for admin pages.
    name: isAdminPage ? RouteName.NOT_FOUND : RouteName.UNAUTHORIZED,
  });
});

router.afterEach((to: Route, from: Route): void => {
  if (DEBUG) console.log('afterEach', { to, from });

  // close application sidebar
  nextTick((): void => {
    closeApplicationSidebar();
  });

  // Finish progress bar
  NProgress.done();

  // set page title
  switch (import.meta.env.VITE_CLIENT) {
    case 'BofA':
      document.title = `${to.meta ? '| ' + to.meta.title : ''}`;
      break;
    default:
      document.title = `${import.meta.env.VITE_CLIENT} ${to.meta ? '| ' + to.meta.title : ''}`;
  }

  // Post current URL to parent window if embedded in BofA iframe
  if (import.meta.env.VITE_CLIENT === ClientConstants.BOFA) {
    const url = window.location.href.replace(window.location.origin, '');
    if (import.meta.env.VITE_STAGE === 'prod') {
      window.parent?.postMessage({ action: 'navigate', url }, 'https://markets.ml.com/nova');
    }
    if (import.meta.env.VITE_STAGE === 'uat') {
      window.parent?.postMessage({ action: 'navigate', url }, 'https://markets-pp.ml.com/nova');
    }
  }
});

export default router;
