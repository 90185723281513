import { ISidebarRow, ISidebarRowTree } from '@/types/ISidebarRow';
import { findSidebarRowById } from '@/utils/portfolioTree';
import { cloneDeep } from 'lodash';
import { computed, ComputedRef, Ref, ref } from 'vue';

const sidebarRows = ref<ISidebarRow[]>([]);

/**
 * Stores a map of the flattened portfolio tree that is used to store the variables
 * used to define if a treeId should be accessed using the up/down arrows
 */
const sidebarRowsTree = ref<ISidebarRowTree[]>([]);

/**
 * String array of the tree Ids of the shown row items that are in manual
 * The unit column does not include subportfolios in the list, they do not have the input field
 */
const sidebarRowsTreeIdsMap = computed(() => {
  const colMap = new Map<string, string[]>();

  const weightCol = sidebarRowsTree.value
    .filter((x) => x.isParentManual && !x.isParentCollapsed && !x.isSuspended)
    .map((x) => x.portfolioTreeId);

  const unitCol = sidebarRowsTree.value
    .filter((x) => x.isParentManual && !x.isParentCollapsed && !x.isSuspended && !x.isSubportfolio)
    .map((x) => x.portfolioTreeId);

  colMap.set('weight', weightCol);
  colMap.set('unit', unitCol);

  return colMap;
});

const areAllSubportfoliosCollapsed = ref(true);

const pushSidebarRowConfiguration = ({ rows, parentTreeId }: { rows: ISidebarRow[]; parentTreeId: string }) => {
  // if have parentTree Id means push rows under that
  const sidebarRowsVal = cloneDeep(sidebarRows.value);
  const row = findSidebarRowById({
    portfolioTreeId: parentTreeId,
    arr: sidebarRowsVal,
  });
  if (row) {
    row.children.unshift(...rows);
  } else {
    // otherwise we directly add to root floor
    sidebarRowsVal.unshift(...rows);
  }
  sidebarRows.value = sidebarRowsVal;
};

export function usePortfolioSidebar(): {
  sidebarRows: Ref<ISidebarRow[]>;
  sidebarRowsTree: Ref<ISidebarRowTree[]>;
  sidebarRowsTreeIdsMap: Ref<Map<string, string[]>>;
  areAllSubportfoliosCollapsed: Ref<boolean>;
  pushSidebarRowConfiguration: ({ rows, parentTreeId }: { rows: ISidebarRow[]; parentTreeId: string }) => void;
} {
  return {
    sidebarRows,
    sidebarRowsTree,
    sidebarRowsTreeIdsMap,
    areAllSubportfoliosCollapsed,
    pushSidebarRowConfiguration,
  };
}

const errorMap = ref<{ [id: string]: string[] }>({});

const hasError = computed(() => {
  return Object.keys(errorMap.value).length > 0;
});

const errorMessage = computed(() => {
  const errors = Object.values(errorMap.value);
  if (errors.length) {
    const [portfolioTreeIdWithErrors] = errors;
    if (portfolioTreeIdWithErrors.length) {
      const [error] = portfolioTreeIdWithErrors;
      return error;
    }
  }
  return '';
});

function setErrorMapItem({ portfolioTreeId, error }: { portfolioTreeId: string; error: string }) {
  const newErrorMap = cloneDeep(errorMap.value);
  newErrorMap[portfolioTreeId] = [error];
  errorMap.value = newErrorMap;
}

function removeErrorMapItem({ portfolioTreeId, error }: { portfolioTreeId: string; error: string }) {
  const newErrorMap = cloneDeep(errorMap.value);
  const errorIdx = newErrorMap[portfolioTreeId].findIndex((x) => x === error);
  newErrorMap[portfolioTreeId].splice(errorIdx, 1);
  if (!newErrorMap[portfolioTreeId].length) {
    delete newErrorMap[portfolioTreeId];
  }
  errorMap.value = newErrorMap;
}

function resetErrorMap() {
  errorMap.value = {};
}

export function useErrorMap(): {
  errorMap: Ref<{ [id: string]: string[] }>;
  hasError: ComputedRef<boolean>;
  errorMessage: ComputedRef<string>;
  setErrorMapItem: ({ portfolioTreeId, error }: { portfolioTreeId: string; error: string }) => void;
  removeErrorMapItem: ({ portfolioTreeId, error }: { portfolioTreeId: string; error: string }) => void;
  resetErrorMap: () => void;
} {
  return {
    errorMap,
    hasError,
    errorMessage,
    setErrorMapItem,
    removeErrorMapItem,
    resetErrorMap,
  };
}
