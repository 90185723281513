import { BofAAppMain } from '@/layout/components/index';
import { PortfolioConstructionRouter } from './PortfolioConstructionRouter';
import { RouteConfig } from 'vue-router';
import { FactorDecompositionRouter } from './FactorDecompositionRouter';
import { ConstituentRiskRouter } from './ConstituentRiskRouter';
import { ClusteringAnalysisRouter } from './ClusteringAnalysisRouter';
import { RouteName } from '@/constants/RouteName';
import { useLastUsedPortfolioSlug } from '@/composables/useCachedAnalysisParameters';
import { hasAnalyticsPermission, isAnalyticsRoute } from '@/composables/useRouteChecks';
import { ConstituentPositionRouter } from '../../analytics/ConstituentPositionRouter';
import { UserModule } from '@/store/modules/user';

const DEBUG = false;

export const AnalyticsRouter: RouteConfig[] = [
  {
    name: RouteName.ANALYTICS,
    path: '/analytics',
    components: {
      default: BofAAppMain,
    },
    beforeEnter: (to, from, next): void => {
      const { portfolio: lastPortfolioSlugUsed } = useLastUsedPortfolioSlug();
      // if to router does not have a name, this means we are visiting the parent path
      // like /analytics , /analytics/x
      // need to redirect to latest child route
      const { defaultSlug = '', defaultRiskSlug = '' } = UserModule.user ?? {};

      if (DEBUG)
        console.log('before', {
          lastPortfolioSlugUsed,
          defaultSlug,
        });

      if (to.name && isAnalyticsRoute(to.name) && UserModule.user) {
        const hasPermissionToRoute = hasAnalyticsPermission({
          user: UserModule.user,
          testRoute: to.name,
          currentRoute: to,
        });

        // if user has permission, just go to the route
        if (hasPermissionToRoute === to.name) {
          next();
          return;
        }

        // else find the next route the user has permission to and go to it
        const getIndexUniqueIdentifier = () => {
          // TODO: Remove defaultRiskSlug when portfolio simulation is validated
          return (
            lastPortfolioSlugUsed.value ||
            (to.name === RouteName.CONSTITUENT_RISK_PORTFOLIO ? defaultRiskSlug : defaultSlug) ||
            ''
          );
        };

        if (hasPermissionToRoute) {
          next({
            name: hasPermissionToRoute,
            params: {
              indexUniqueIdentifier: getIndexUniqueIdentifier(),
            },
          });
          return;
        }

        // else show not found
        next({
          name: RouteName.NOT_FOUND,
        });
        return;
      }

      next();
    },
    children: [
      ...PortfolioConstructionRouter,
      ...FactorDecompositionRouter,
      ...ConstituentRiskRouter,
      ...ClusteringAnalysisRouter,
      ...ConstituentPositionRouter,
    ],
  },
];
