import { StrategyItemResponseDTO, PortfolioItemResponseDTO } from '@/api-v2/web/discover';
import { METRICS_DATABASE_NAME } from '@/utils/metrics';
import { PeriodAbbrevEnum } from '@/constants/PeriodAbbrevEnum';
import { convertToBusiness, createDate } from '@/utils/dateUtils';
import { DateTime } from 'luxon';
import { StyleConstants } from '@/constants/StyleConstants';

export enum DiscoverChartTypes {
  SPIDER = 'Spider',
  LINE = 'Line',
  MST = 'MST',
}

export enum VolatilityAdjustmentOptions {
  NONE = 'None',
  VOLATILITY_PERCENT = 'Volatility',
}

export enum FilterType {
  Inclusive = 'Inclusive',
  Exclusive = 'Exclusive',
}

export interface Filter {
  /**
   * What data property for the tracks will this item filter on? e.g, Asset Class, Factor...
   */
  category: string;

  /**
   * What value are we looking to match in the `category` property above?
   */
  value: string;

  /**
   * either Inclusive or Exclusive
   */
  type: FilterType;

  /**
   * Sort order for the filter in the list of filters
   */
  order: number;

  key?: string;
  customPropertyId?: string;
  customPropertyValueId?: string;
  autoIdentified?: boolean;
}

export enum StaticColumnName {
  NAME = 'NAME',
  LIVE_DATE = 'LIVE_DATE',
}

export interface ColumnFilter {
  type: AttributeColumnFilterTypes | MetricColumnFilterTypes | null;
  value: string | number | null;
}

export interface ColumnFilterOption {
  text: string;
  type: MetricColumnFilterTypes | AttributeColumnFilterTypes;
  columnType: 'metric' | 'attribute';
}

export enum MetricColumnFilterTypes {
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  EQUALS = 'EQUALS',
  GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
}

export enum AttributeColumnFilterTypes {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export interface MetricColumn {
  title: METRICS_DATABASE_NAME;
  timePeriod: DiscoveryPeriod;
  filter: ColumnFilter | null;
}

export interface AttributeColumn {
  title: StaticColumnName.LIVE_DATE;
  filter: ColumnFilter | null;
}

export const isMetricColumn = (column: MetricColumn | AttributeColumn): column is MetricColumn => {
  return (column as MetricColumn).timePeriod !== undefined;
};

export type SortMetric = METRICS_DATABASE_NAME | StaticColumnName.NAME | StaticColumnName.LIVE_DATE;

export interface SortRanks {
  [code: string]: number | '-';
}

export interface DiscoverItemsVisibilityMap {
  [key: string]: {
    /**
     * Items currently hidden by filters applied to the table
     */
    hiddenByTable: boolean;

    /**
     * Items currently hidden by zoom
     */
    hiddenByZoom: boolean;

    /**
     * if the item is unplottable, then we cannot draw it in the scatter chart
     */
    unplottable: boolean;

    /**
     * Items currently hidden by search filters
     */
    hiddenBySearchFilters?: boolean;
  };
}

export type PinnedStrategy = { type: 'strategy'; code: string; entity: StrategyItemResponseDTO };
export type PinnedPortfolio = {
  type: 'portfolio';
  portfolioId: string;
  slug: string;
  entity: PortfolioItemResponseDTO;
};
export type PinnedItem = PinnedStrategy | PinnedPortfolio;

export type DiscoverItem = StrategyItemResponseDTO | PortfolioItemResponseDTO;

export const isPinnedStrategy = (o: PinnedItem): o is PinnedStrategy => o.type === 'strategy';
export const isPinnedPortfolio = (o: PinnedItem): o is PinnedPortfolio => o.type === 'portfolio';

/**
 * Supported Time Periods for Data Discovery
 */
export type DiscoveryPeriod =
  | PeriodAbbrevEnum.TEN_Y
  | PeriodAbbrevEnum.FIVE_Y
  | PeriodAbbrevEnum.THREE_Y
  | PeriodAbbrevEnum.ONE_Y
  | PeriodAbbrevEnum.SIX_M
  | PeriodAbbrevEnum.ONE_M
  | PeriodAbbrevEnum.YTD;

export type UniverseType = StyleConstants.ALPHA | StyleConstants.BETA | StyleConstants.SMART_BETA;

export type QuantSpacePeriod = PeriodAbbrevEnum.FIVE_Y | PeriodAbbrevEnum.THREE_Y;

const prevBusinessDay = convertToBusiness(createDate().minus({ days: 1 }));

export const DiscoveryPeriodStartDateMap: Record<DiscoveryPeriod, DateTime> = {
  [PeriodAbbrevEnum.TEN_Y]: convertToBusiness(prevBusinessDay.minus({ years: 10 })),
  [PeriodAbbrevEnum.FIVE_Y]: convertToBusiness(prevBusinessDay.minus({ years: 5 })),
  [PeriodAbbrevEnum.THREE_Y]: convertToBusiness(prevBusinessDay.minus({ years: 3 })),
  [PeriodAbbrevEnum.ONE_Y]: convertToBusiness(prevBusinessDay.minus({ years: 1 })),
  [PeriodAbbrevEnum.SIX_M]: convertToBusiness(prevBusinessDay.minus({ months: 6 })),
  [PeriodAbbrevEnum.ONE_M]: convertToBusiness(prevBusinessDay.minus({ months: 1 })),
  [PeriodAbbrevEnum.YTD]: convertToBusiness(prevBusinessDay.startOf('year').minus({ days: 1 })),
};

export const DiscoveryPeriodEndDate = prevBusinessDay;

export const SortedDiscoveryPeriods: readonly DiscoveryPeriod[] = Object.entries(DiscoveryPeriodStartDateMap)
  .sort((a, b) => (a[1] < b[1] ? -1 : 1))
  .map((o) => o[0] as DiscoveryPeriod);

export const isDiscoveryPeriod = (period: unknown): period is DiscoveryPeriod => {
  return typeof period === 'string' && period in DiscoveryPeriodStartDateMap;
};
