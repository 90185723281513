/**
 * This composable is used to manage the position table columns and layers.
 * It follows the same pattern as useConstituentRiskTableUtilities.ts
 */
import { computed, ComputedRef } from 'vue';
import { set } from 'vue-demi';
import { usePositionPreferences } from './usePositionPreferences';
import { getPositionCustomCols, getPositionSegmentLayers } from './useConstituentPositionParams';
import { IConstituentRiskOptions } from '@/types/analytics/ConstituentRisk';

export const DEFAULT_POSITION_MODE_COLUMNS = [
  'Product Type',
  'Ticker',
  'Sector',
  'Weight',
  'Count',
  'Quantity',
  'Quantity Type',
  'Price',
];

export function useConstituentPositionTableUtilities(
  positionOptions: ComputedRef<IConstituentRiskOptions | undefined>,
) {
  const { preferences } = usePositionPreferences();

  const customCols = getPositionCustomCols();

  const onPositionSelectorModalConfirm = (selectedColumns: string[]) => {
    set(preferences.value, 'customCols', selectedColumns);
  };

  const positionAssets = computed(() => positionOptions.value?.asset);
  const positionRiskMetrics = computed(() => positionOptions.value?.riskMetrics);
  const positionCustomProperties = computed(() => Object.keys(positionOptions.value?.segmentCustomOptions ?? {}));

  const allColumnNames = computed(() => {
    if (!positionRiskMetrics.value || !positionAssets.value || !positionCustomProperties.value) return [];
    // For position mode, the API returns fixed fields
    // Only asset, Exposure, customProperties are returned
    return [...positionAssets.value, ...positionRiskMetrics.value.VaR.Exposure, ...positionCustomProperties.value];
  });

  const positionColumnNames = computed(() => {
    const preferenceCustomCols = preferences.value.customCols;

    if (preferenceCustomCols.length) {
      customCols.value = preferenceCustomCols;
    }

    return customCols.value;
  });

  const positionSegmentLayersRef = getPositionSegmentLayers();

  const positionSegmentLayers = computed(() => {
    const preferenceTableLayers = preferences.value.tableLayers;

    if (preferenceTableLayers.length) {
      positionSegmentLayersRef.value = preferenceTableLayers;
    }

    return positionSegmentLayersRef.value;
  });

  return { onPositionSelectorModalConfirm, positionSegmentLayers, positionColumnNames, allColumnNames };
}
