import { ATTRIBUTE_DATABASE_NAME, ATTRIBUTE_PROPER_NAME, IStrategy } from '../types/strategy';
import { IItemUnderAnalysis } from '@/types/IItemUnderAnalysis';
import { TimeSeriesEntityTypeConstants } from '@/constants/TimeSeriesEntityTypeConstants';
import useTranslation from '@/composables/useTranslation';
import useFxConversion from '@/composables/useFxConversion';
import { FxConversion } from '@/constants/FxConversion';
import { PortfolioItemResponseDTO, StrategyItemResponseDTO } from '@/api-v2/web/discover';
import { TrackTypeConstants } from '@/constants/TrackTypeConstants';
import useStrategyMask from '@/composables/useStrategyMask';
import { SignalListItemResponseDTO } from '@/api-v2/admin/strategies';

const { translate } = useTranslation();
const { toCurrency } = useFxConversion();
const { getStrategyMask } = useStrategyMask();

export interface StrategyStaticInfo {
  code: string;
  trackType: TrackTypeConstants;
  type: TimeSeriesEntityTypeConstants;
  internalReference?: string | null;
  isin?: string[];
  morningStarId?: string[];
  ticker?: string[];
  ric?: string[];
  sedol?: string[];
}

/**
 * Get the identifier entry for given strategy.
 *
 * See WAA-2779 for the rules for selecting identifier.
 */
export const getIdentifier = (strategy: StrategyStaticInfo) => {
  if (strategy.trackType === TrackTypeConstants.PRIVATE_TRACK || strategy.trackType === TrackTypeConstants.PROXY) {
    // Prefer Internal Identifier > Index Code (ticker) > ISIN > MStar ID
    if (strategy.internalReference) {
      return { name: 'Internal Identifier', value: strategy.internalReference };
    }

    if (strategy.ticker?.length) {
      // Yes the label is "Index Code" because the platform already mixed
      // up bloomberg ticker/mstar ID/etc as "Index Code" in various place.
      // Trying to be consistently wrong here.
      return { name: 'Index Code', value: strategy.ticker[0] };
    }

    if (strategy.isin?.length) {
      return { name: 'ISIN', value: strategy.isin[0] };
    }

    if (strategy.morningStarId?.length) {
      return { name: 'Morningstar ID', value: strategy.morningStarId[0] };
    }

    return { name: 'Code', value: strategy.code };
  }

  switch (strategy.type) {
    case TimeSeriesEntityTypeConstants.FUND: {
      // Prefer ISIN, fallback to MStar ID
      if (strategy.isin?.length) {
        return { name: 'ISIN', value: strategy.isin[0] };
      }

      if (strategy.morningStarId?.length) {
        return { name: 'Morningstar ID', value: strategy.morningStarId[0] };
      }

      break;
    }
  }

  // Fallback to use index code, which should be the one to use in most case
  return { name: 'Index Code', value: strategy.code };
};

export const isStrategy = (datum: IItemUnderAnalysis): datum is IStrategy => {
  return 'code' in datum;
};

export const getSubtext = (
  strategy: IStrategy | StrategyItemResponseDTO,
  isPortfolioCompositionSection = false,
): string => {
  const { factor = '', assetClass = '' } = strategy;

  const assetClassTranslated =
    assetClass && !isPortfolioCompositionSection
      ? translate({
          path: 'GLOBAL.ASSET_CLASS_CONSTANTS',
          item: assetClass,
        })
      : '';

  const factorTranslated =
    factor && !isPortfolioCompositionSection
      ? ` | ${translate({
          path: 'GLOBAL.FACTOR_CONSTANTS_SUBTEXT',
          item: factor,
        })}`
      : '';

  let subtext = '';
  switch (strategy.type) {
    case TimeSeriesEntityTypeConstants.PUBLIC_THEMATIC:
    case TimeSeriesEntityTypeConstants.THEMATIC_BENCHMARK:
    case TimeSeriesEntityTypeConstants.THEMATIC:
      if (strategy.theme) {
        subtext = `${strategy.theme}`;
      }
      if (strategy.esg === 'Yes') {
        subtext = `${subtext} | ESG`;
      }
      break;
    default: {
      subtext = `${assetClassTranslated}${factorTranslated}`;
      break;
    }
  }
  return subtext;
};

export const getAdditionalSubtext = ({
  strategy,
  regressorFxType,
  shouldUseGlobalFxCurrency,
}: {
  strategy: IStrategy | StrategyItemResponseDTO;
  regressorFxType?: FxConversion;
  /**
   * This should be false for cases in which we always want to show the underlying strategy's currency
   * e.g., on the Discover Table, the AnalysisToolModal or the DSB, etc
   */
  shouldUseGlobalFxCurrency?: boolean;
}): string => {
  const { returnType = null, returnCategory = null, currency = '' } = strategy;

  const returnTypeTranslated = returnType
    ? translate({
        path: 'GLOBAL.RETURN_TYPE_CONSTANTS_SUBTEXT',
        item: returnType,
      })
    : '';

  const returnCategoryTranslated = returnCategory
    ? translate({
        path: 'GLOBAL.RETURN_CATEGORY_CONSTANTS_SUBTEXT',
        item: returnCategory,
      })
    : '';

  let subtext = '';
  subtext = `${shouldUseGlobalFxCurrency ? toCurrency.value : currency} `;
  // On the regression page, when fx conversion is applied and it is of type Monthly Hedged, we want to show it
  if (regressorFxType && toCurrency.value !== currency && regressorFxType === FxConversion.MONTHLY_HEDGED) {
    subtext = `${subtext} ${translate({
      path: 'GLOBAL.FX_CONVERSION.MONTHLY_HEDGED_SHORT',
    })} `;
  }
  if (returnCategoryTranslated) {
    subtext = `${subtext}${returnCategoryTranslated}`;
  }
  if (returnTypeTranslated) {
    subtext = `${subtext}${returnTypeTranslated}`;
  }
  return subtext;
};

export const formatStrategyName = (strategy: IStrategy | StrategyItemResponseDTO): string => {
  const pureFactor = 'PLB Pure Factor ';

  const name = getStrategyMask(strategy);
  if (name.includes(pureFactor)) {
    return name.replace(pureFactor, '');
  }
  return name;
};

type SupportedStrategyProperties =
  | ATTRIBUTE_DATABASE_NAME.AssetClass
  | ATTRIBUTE_DATABASE_NAME.Factor
  | ATTRIBUTE_DATABASE_NAME.Region
  | ATTRIBUTE_DATABASE_NAME.Style
  | ATTRIBUTE_DATABASE_NAME.IntradayType
  | ATTRIBUTE_DATABASE_NAME.ReturnCategory
  | ATTRIBUTE_DATABASE_NAME.ReturnType
  | ATTRIBUTE_PROPER_NAME.AssetClass
  | ATTRIBUTE_PROPER_NAME.Factor
  | ATTRIBUTE_PROPER_NAME.Region
  | ATTRIBUTE_PROPER_NAME.Style
  | ATTRIBUTE_PROPER_NAME.ReturnCategory
  | ATTRIBUTE_PROPER_NAME.ReturnType;

/**
 * Translates the values of a subset of strategy properties.
 *
 * @param property
 *   Currently supported properties are `assetClass`, `factor`, `region`, `style`, `intradayType`, `returnCategory`, and `returnType`.
 *
 * @param value
 *   The value of the property to be translated. If `null` or `undefined`, behavior depends
 *   on the `isReturnOriginalValIfNull` parameter.
 *
 * @param isReturnOriginalValIfNull
 *   Optional. If `true`, the function returns the original `value` when it is `null` or `undefined`.
 *   Otherwise, it returns `undefined` (default behavior for backward compatibility).
 *
 * @returns
 *   - Translated label for the property value if `value` is non-null and the property is supported.
 *   - If `value` is `null` or `undefined`:
 *     - Returns the original `value` if `isReturnOriginalValIfNull` is `true`.
 *     - Returns `undefined` if `isReturnOriginalValIfNull` is `false` or omitted.
 *   - Returns `undefined` for unsupported properties.
 */
export const translateKnownStrategyProperty = (
  property: string,
  value: string | null,
  isReturnOriginalValIfUnknown?: boolean,
): string | undefined | null => {
  const translationMap: Record<SupportedStrategyProperties, string> = {
    [ATTRIBUTE_DATABASE_NAME.AssetClass]: 'GLOBAL.ASSET_CLASS_CONSTANTS',
    [ATTRIBUTE_DATABASE_NAME.Factor]: 'GLOBAL.FACTOR_CONSTANTS',
    [ATTRIBUTE_DATABASE_NAME.Region]: 'GLOBAL.REGION_CONSTANTS',
    [ATTRIBUTE_DATABASE_NAME.Style]: 'GLOBAL.STYLE_CONSTANTS',
    [ATTRIBUTE_DATABASE_NAME.IntradayType]: 'GLOBAL.INTRADAY_TYPE_CONSTANTS',
    [ATTRIBUTE_DATABASE_NAME.ReturnCategory]: 'GLOBAL.RETURN_CATEGORY_CONSTANTS',
    [ATTRIBUTE_DATABASE_NAME.ReturnType]: 'GLOBAL.RETURN_TYPE_CONSTANTS',

    [ATTRIBUTE_PROPER_NAME.AssetClass]: 'GLOBAL.ASSET_CLASS_CONSTANTS',
    [ATTRIBUTE_PROPER_NAME.Factor]: 'GLOBAL.FACTOR_CONSTANTS',
    [ATTRIBUTE_PROPER_NAME.Region]: 'GLOBAL.REGION_CONSTANTS',
    [ATTRIBUTE_PROPER_NAME.Style]: 'GLOBAL.STYLE_CONSTANTS',
    [ATTRIBUTE_PROPER_NAME.ReturnCategory]: 'GLOBAL.RETURN_CATEGORY_CONSTANTS',
    [ATTRIBUTE_PROPER_NAME.ReturnType]: 'GLOBAL.RETURN_TYPE_CONSTANTS',
  };

  if (property in translationMap) {
    return value
      ? translate({
          path: translationMap[property as keyof typeof translationMap],
          item: value,
        })
      : '';
  }

  // Return behavior based on the isReturnOriginalValIfUnknown flag
  return isReturnOriginalValIfUnknown ? value : undefined;
};

export type TranslateResult<T extends string> = { value: T; label: string };
export type Translator<T extends string> = (key: T) => TranslateResult<T>;

/**
 * Fetches a Translator for a given property.
 *
 * A translator maps a given property value into its label.
 *
 * For a given strategy property `T`, a translator can be passed into `(T[]).map()`
 * to convert an array of its values to a `TranslateResult` (`{ value: T; label: string }`).
 *
 * Example:
 * ````ts
 * const availableAssetClassFilters =
 *   Object.values(AssetClassConstants).map(translatorForProperty(ATTRIBUTE_DATABASE_NAME.AssetClass));
 * // [{ value: AssetClassConstants.MULTI_ASSETS, label: 'Multi Assets' }, ... ]
 * ````
 *
 * @param property
 *   Currently supported properties are `assetClass`, `factor`, `region`, `style`, `intradayType`, `returnCategory` and `returnType`.
 *
 * @returns
 *   Translator for given property.
 *   Unknown properties return a passthrough Translator which leaves the label same as the value.
 *
 * Todo: Move to other class or convert to composable when we support portfolio properties.
 */
export const translatorForProperty = <T extends string>(property: ATTRIBUTE_DATABASE_NAME): Translator<T> => {
  return (key: T) => {
    const label = translateKnownStrategyProperty(property, key);
    return { value: key, label: label ?? key };
  };
};

export const sortStrategies = (strategies: StrategyItemResponseDTO[]): StrategyItemResponseDTO[] => {
  return strategies
    .map((strategy) => [strategy.shortName.toLowerCase(), strategy] as const)
    .sort(([aShortName], [bShortName]) => (aShortName > bShortName ? 1 : -1))
    .map(([, strategy]) => strategy);
};

export const sortBenchmarks = (
  benchmarks: (StrategyItemResponseDTO | PortfolioItemResponseDTO)[],
): (StrategyItemResponseDTO | PortfolioItemResponseDTO)[] => {
  return benchmarks
    .map((benchmark) => {
      const identifier = 'slug' in benchmark ? benchmark.name : benchmark.shortName;
      return [identifier.toLowerCase(), benchmark] as const;
    })
    .sort(([aIdentifier], [bIdentifier]) => (aIdentifier > bIdentifier ? 1 : -1))
    .map(([, benchmark]) => benchmark);
};

export const sortSignals = (signals: SignalListItemResponseDTO[]): SignalListItemResponseDTO[] => {
  return signals
    .map((signal) => [signal.shortName.toLowerCase(), signal] as const)
    .sort(([aShortName], [bShortName]) => (aShortName > bShortName ? 1 : -1))
    .map(([, signal]) => signal);
};
