import { LoadingWhenEnabledQRT } from '@/types/VueQueryTypes';
import { UseQueryReturnType } from '@tanstack/vue-query';
import { Ref, computed } from 'vue';

/**
 * IMPORTANT NOTE: vue-query only considers `isLoading` to be true in the initial state when there is no data.
 * It uses `isFetching` for any other time it is querying for data, including when the query is invalidated
 * (e.g., after the portfolio tree draft has been updated)
 *
 * For 99% of our use cases, we want to show a loading indicator in both of these states.
 * However, vue-query ALSO will mark `isLoading` as true even if the query is not enabled.
 *
 * This is NOT a case under which we want to show a loading indicator because it could be an error state that the user
 * has entered and we do not want to imply that data is loading when nothing is happening.
 */
export default function useLoadingWhenEnabledQuery<TData, TError = Error>(
  query: UseQueryReturnType<TData, TError>,
  enabled: Ref<boolean>,
): LoadingWhenEnabledQRT<TData, TError> {
  return {
    query,
    data: query.data,
    isFetching: query.isFetching,
    isError: query.isError,
    error: query.error,
    enabled,
    isLoading: computed(() => enabled.value && query.isLoading.value),
    isLoadingOrFetching: computed(() => enabled.value && (query.isLoading.value || query.isFetching.value)),
  };
}

export function isLoadingWhenEnabledQRT<TData, TError = Error>(
  query: UseQueryReturnType<TData, TError> | LoadingWhenEnabledQRT<TData, TError>,
): query is LoadingWhenEnabledQRT<TData, TError> {
  return (query as LoadingWhenEnabledQRT<TData, TError>).isLoadingOrFetching !== undefined;
}
