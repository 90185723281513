import request from '@/api-v2/request';
import { IDataDiscoveryTrackResponse } from './types/IDataDiscoveryTrackResponse';
import { IDataDiscoveryMstRequest } from './types/IDataDiscoveryMstRequest';
import { DataDiscoveryResponseDTO } from './types/DataDiscoveryResponseDTO';
import {
  DataDiscoveryMetricsRequestDTO,
  DataDiscoveryMetricsResponseDTO,
  DataDiscoveryVolScaledPortfolioMetricsRequestDTO,
  DataDiscoveryVolScaledStrategyMetricsRequestDTO,
} from './types/DataDiscoveryMetricsDTO';
import { PortfolioSummaryResponseDTO } from './types/PortfolioSummaryResponseDTO';
import { IMetric } from '@/types/strategy';
import { IMstResponse } from '@/types/IMstResponse';
import { DataDiscoveryTrackRequestDTO } from './types/DataDiscoveryTrackRequestDTO';
import {
  DataDiscoveryUniverseQuantSpaceRequestDTO,
  DataDiscoveryUniverseQuantSpaceResponseDTO,
} from './types/DataDiscoveryQuantSpaceDTO';

export const getDataUniverse = ({ signal }: { signal: AbortSignal }): Promise<DataDiscoveryResponseDTO> =>
  request({
    method: 'get',
    url: `/discover`,
    signal,
  }).then((o): DataDiscoveryResponseDTO => o.data);

export const getDataUniverseMetrics = ({
  params,
  signal,
}: {
  params: DataDiscoveryMetricsRequestDTO;
  signal?: AbortSignal;
}): Promise<DataDiscoveryMetricsResponseDTO> =>
  request({
    method: 'get',
    url: `/discover/metrics`,
    params,
    signal,
  }).then((o): DataDiscoveryMetricsResponseDTO => o.data);

export const getQuantSpacePositions = ({
  params,
  signal,
}: {
  params: DataDiscoveryUniverseQuantSpaceRequestDTO;
  signal?: AbortSignal;
}): Promise<DataDiscoveryUniverseQuantSpaceResponseDTO> =>
  request({
    method: 'get',
    url: `/discover/universe-metrics`,
    params,
    signal,
  }).then((o): DataDiscoveryUniverseQuantSpaceResponseDTO => o.data);

/**
 * Fetches metrics for a strategy for a given period
 * @param code Strategy code to fetch metrics for
 * @param volScaling Should volatility scaling be applied
 * @param volScalePeriod Period for which track needs to be Volatility scaled
 * @param analyticsPeriod Analytics period for which metrics needs to be calculated
 */
export const getStrategyMetrics = ({
  params,
  signal,
}: {
  params: DataDiscoveryVolScaledStrategyMetricsRequestDTO;
  signal?: AbortSignal;
}): Promise<{ result: IMetric }> => {
  return request({
    method: 'get',
    url: `/discover/strategy/${params.code}/metrics`,
    params,
    signal,
  }).then((o) => o.data);
};

/**
 * Fetches metrics for a portfolio for a given period
 * @param slug Portfolio slug to fetch metrics for
 * @param volScaling Should volatility scaling be applied
 * @param volScalePeriod Period for which track needs to be Volatility scaled
 * @param analyticsPeriod Analytics period for which metrics needs to be calculated
 */
export const getPortfolioMetrics = ({
  params,
  signal,
}: {
  params: DataDiscoveryVolScaledPortfolioMetricsRequestDTO;
  signal?: AbortSignal;
}): Promise<{ result: IMetric }> => {
  return request({
    method: 'get',
    url: `/discover/portfolio/${params.slug}/metrics`,
    params,
    signal,
  }).then((o) => o.data);
};

export const getStrategyTrackWithParams = ({
  code,
  params,
  signal,
}: {
  code: string;
  params: DataDiscoveryTrackRequestDTO;
  signal?: AbortSignal;
}): Promise<IDataDiscoveryTrackResponse> =>
  request({
    method: 'get',
    url: `/discover/strategy/${code}/track`,
    params,
    signal,
  }).then((o) => o.data);

export const getPortfolioTrackWithParams = ({
  slug,
  params,
  signal,
}: {
  slug: string;
  params: DataDiscoveryTrackRequestDTO;
  signal?: AbortSignal;
}): Promise<IDataDiscoveryTrackResponse> =>
  request({
    method: 'get',
    url: `/discover/portfolio/${slug}/track`,
    params,
    signal,
  }).then((o) => o.data);

export const getMst = ({
  data,
  signal,
}: {
  data: IDataDiscoveryMstRequest;
  signal?: AbortSignal;
}): Promise<IMstResponse> =>
  request({
    method: 'post',
    url: `/discover/mst`,
    data,
    signal,
  }).then((o) => o.data);

export const getPortfolioSummary = ({ signal }: { signal: AbortSignal }): Promise<PortfolioSummaryResponseDTO[]> =>
  request({
    method: 'get',
    url: `/discover/portfolios/summary`,
    signal,
  }).then((o) => o.data);
