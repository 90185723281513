import { AppMain } from '@/layout/components/index';
import { UserPermission } from '@/constants/UserPermission';
import { RouteConfig } from 'vue-router';
import { NavItemNames } from '@/constants/NavbarConstants';
import { RouteName } from '@/constants/RouteName';
import { useFeatureFlag } from '@/composables/useFeatureFlag';

export const DataRouter: RouteConfig[] = [
  {
    path: '/data',
    redirect: {
      name: RouteName.DATA,
    },
  },
  {
    path: '/discover',
    components: {
      default: AppMain,
    },
    children: [
      {
        name: RouteName.DATA,
        // Specifying path instead of '' here.
        // If we use '', this generated route will be `/discover/` with tailing slash.
        // That breaks auth0. So working around that by specify a root path here.
        path: '/discover',
        component: () => import('@/views/discover/DataUniverseDeprecated.vue'),
        beforeEnter: (to, from, next) => {
          const { previewDataUniverseV3Whitelabel } = useFeatureFlag();

          if (previewDataUniverseV3Whitelabel.value) {
            // Redirect to DataUniverseV3 if user has permission and has enabled beta features
            next({ name: RouteName.DATA_V3 });

            return;
          }

          next();
        },
        meta: {
          title: NavItemNames.DATA,
          permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM],
        },
      },
      {
        name: RouteName.DATA_V3,
        path: '/discover-v3',
        component: () => import('@/views/discover/DataUniverseV3.vue'),
        beforeEnter: (to, from, next) => {
          const { previewDataUniverseV3Whitelabel } = useFeatureFlag();

          if (!previewDataUniverseV3Whitelabel.value) {
            // Redirect to base route if user doesn't have permission
            next({ name: RouteName.DATA });

            return;
          }

          next();
        },
        meta: {
          title: NavItemNames.DATA_V3,
          permission: [UserPermission.UNBLOCK, UserPermission.PLATFORM],
          isMainContentContainerFluid: true,
        },
      },
    ],
  },
];
