import { VQQueryOptions } from '@/types/VueQueryTypes';
import useLoadingWhenEnabledQuery from './useLoadingWhenEnabledQuery';
import { useQuery } from '@tanstack/vue-query';
import { chainEnabled, unwrap } from '@/utils/queries';
import {
  getConstituentRiskData,
  getConstituentRiskDates,
  getConstituentRiskOptions,
  getConstituentRiskRibbon,
  getConstituentRiskTable,
} from '@/api-v2/web/risk-dashboard';
import {
  IConstituentRibbonQuery,
  IConstituentRisk,
  IConstituentRiskQuery,
  IConstituentRiskTableQuery,
} from '@/types/IConstituentRisk';
import { Ref } from 'vue';
import { IConstituentRiskOptions } from '@/types/analytics/ConstituentRisk';
import { IConstituentRiskRibbonResponse } from '@/types/IConstituentRiskRibbon';

const keys = {
  all: () => [{ scope: 'constituent-risk' }] as const,
  dates: (codeOrSlug: Ref<string | null>) => [{ ...keys.all()[0], entity: 'dates', codeOrSlug }] as const,
  ribbon: (slug: Ref<string | null>, query: Ref<IConstituentRibbonQuery | undefined>) =>
    [{ ...keys.all()[0], entity: 'ribbon', slug, query }] as const,
  ribbonRolling: (slug: Ref<string | null>, query: Ref<IConstituentRibbonQuery | undefined>) =>
    [{ ...keys.all()[0], entity: 'ribbon-rolling', slug, query }] as const,
  dashboardLeft: (slug: Ref<string | null>, query: Ref<IConstituentRiskQuery | undefined>) =>
    [{ ...keys.all()[0], entity: 'dashboard_left', slug, query }] as const,
  dashboard: (slug: Ref<string | null>, query: Ref<IConstituentRiskQuery | undefined>) =>
    [{ ...keys.all()[0], entity: 'dashboard', slug, query }] as const,
  table: (slug: Ref<string | null>, query: Ref<IConstituentRiskTableQuery | undefined>) =>
    [{ ...keys.all()[0], entity: 'table', slug, query }] as const,
  options: () => [{ ...keys.all()[0], entity: 'options' }] as const,
};

/**
 * Get the constituent risk dates for the given index unique identifier
 * @param codeOrSlug portfolio slug or strategy code
 * @param options Vue Query options
 * @returns string[] of dates
 */
export function useConstituentRiskDates(codeOrSlug: Ref<string | null>, options: VQQueryOptions<string[]> = {}) {
  const enabled = chainEnabled(options.enabled);
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.dates(codeOrSlug),
      queryFn: () => getConstituentRiskDates(unwrap(codeOrSlug)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

/**
 * Get the constituent risk ribbon data for the given query
 * This will only be used for the position data on ribbon
 * @param query Risk Ribbon Query
 * @param options Vue Query options
 * @returns IConstituentRiskRibbonResponse - Constituent Risk Ribbon Data
 */
export function useConstituentRiskRibbonData({
  draftSlug,
  query,
  options = {},
}: {
  draftSlug: Ref<string | null>;
  query: Ref<IConstituentRibbonQuery | undefined>;
  options?: VQQueryOptions<IConstituentRiskRibbonResponse[]>;
}) {
  const enabled = chainEnabled(options.enabled);
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.ribbon(draftSlug, query),
      queryFn: () => getConstituentRiskRibbon(unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

/**
 * Get the constituent risk ribbon rolling data for the given query
 * This will only be used for the rolling data on ribbon
 * @param query Risk Ribbon Query
 * @param options Vue Query options
 * @returns IConstituentRiskRibbonResponse - Constituent Risk Ribbon Data
 */
export function useConstituentRiskRibbonRollingData({
  draftSlug,
  query,
  options = {},
}: {
  draftSlug: Ref<string | null>;
  query: Ref<IConstituentRibbonQuery | undefined>;
  options?: VQQueryOptions<IConstituentRiskRibbonResponse[]>;
}) {
  const enabled = chainEnabled(options.enabled);
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.ribbonRolling(draftSlug, query),
      queryFn: () => getConstituentRiskRibbon(unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

/**
 * Get the constituent risk dashboard data for the given query
 *
 * This is used for pages where there are two graphs (VaR only for now)
 * To ensure the loading happens only on the required graph
 * @param query Risk Dashboard Query
 * @param options Vue Query options
 * @returns IConstituentRisk - Constituent Risk Dashboard Data
 */
export function useConstituentRiskDashboardLeftData({
  draftSlug,
  query,
  options = {},
}: {
  draftSlug: Ref<string | null>;
  query: Ref<IConstituentRiskQuery | undefined>;
  options?: VQQueryOptions<IConstituentRisk>;
}) {
  const enabled = chainEnabled(options.enabled);
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.dashboardLeft(draftSlug, query),
      queryFn: () => getConstituentRiskData(unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

/**
 * Get the constituent risk dashboard data for the given query
 * @param query Risk Dashboard Query
 * @param options Vue Query options
 * @returns IConstituentRisk - Constituent Risk Dashboard Data
 */
export function useConstituentRiskDashboardData({
  draftSlug,
  query,
  options = {},
}: {
  draftSlug: Ref<string | null>;
  query: Ref<IConstituentRiskQuery | undefined>;
  options?: VQQueryOptions<IConstituentRisk>;
}) {
  const enabled = chainEnabled(options.enabled);
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.dashboard(draftSlug, query),
      queryFn: () => getConstituentRiskData(unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

/**
 * Get the constituent risk table data for the given query
 * @param query Risk Table Query
 * @param options Vue Query options
 * @returns IConstituentRisk - Constituent Risk Table Data
 */
export function useConstituentRiskTableData({
  draftSlug,
  query,
  options = {},
}: {
  draftSlug: Ref<string | null>;
  query: Ref<IConstituentRiskTableQuery | undefined>;
  options?: VQQueryOptions<IConstituentRisk>;
}) {
  const enabled = chainEnabled(options.enabled);
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.table(draftSlug, query),
      queryFn: () => getConstituentRiskTable(unwrap(query)),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}

/**
 * Get the constituent Risk options
 * @param options Vue Query options
 * @returns IConstituentRiskOptions - Options for the Constituent Risk Page
 */
export function useConstituentRiskOptions(options: VQQueryOptions<IConstituentRiskOptions> = {}) {
  const enabled = chainEnabled(options.enabled);
  return useLoadingWhenEnabledQuery(
    useQuery({
      queryKey: keys.options(),
      queryFn: () => getConstituentRiskOptions(),
      ...options,
      enabled,
      staleTime: Number.POSITIVE_INFINITY,
    }),
    enabled,
  );
}
