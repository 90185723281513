import { BasketCommentTypeConstants } from '@/constants/BasketCommentTypeConstants';
import { BasketStatusConstants } from '@/constants/BasketStatusConstants';

export enum AuditLogEvent {
  PORTFOLIO_CONSTRUCTION = 'Portfolio Construction',
  CHECK_COMPLIANCE = 'Check Compliance',
  RUN_OPTIMIZER = 'Run Optimizer',
  PRODUCT_CREATION = 'Product Creation',
  REQUEST_PRICING_OR_FUNDING = 'Request Pricing/Funding',
  SEND_TO_CALC_AGENT = 'Send to Calc Agent',
  REQUEST_REBALANCE = 'Request Rebalance',
}

// TODO: WAA-10104 - Rename APPROVAL_REQUESTED to PENDING on API side
export enum AuditLogStatus {
  DRAFT = 'Draft',
  COMPLIANT = 'Compliant',
  NON_COMPLIANT = 'Non Compliant',
  APPROVAL_REQUESTED = 'Approval Requested',
  REJECTED = 'Rejected',
  APPROVED = 'Approved',
  LIVE = 'Live',
  ARCHIVED = 'Archived',
}

interface BasketStatusStyle {
  statusColor: string;
}

interface ComplianceStatusStyle {
  icon: string;
  iconColor: string;
  variant: string;
}

//  TODO: Confirm the styling with John and Ryan.
const GREY_COLOR = '#b8b8b9';
const TRAFFIC_LIGHT_GREEN = '#00847E';
const PACIFIC_BLUE = '#00A3E0';
const RED_COLOR = '#FF3838';
const TRAFFIC_LIGHT_AMBER = '#E8A215';

// TODO: WAA-11430 Clean up the iconColor below and its usage, use variant instead.
export const ComplianceStatusStyleConfigMap: Record<BasketCommentTypeConstants, ComplianceStatusStyle> = {
  // Compliant is equivalent to having null as basketComplianceStatus
  compliant: {
    icon: 'fa solid fa-circle-check',
    iconColor: TRAFFIC_LIGHT_GREEN,
    variant: 'info',
  },
  error: {
    icon: 'fa solid fa-circle-xmark',
    iconColor: RED_COLOR,
    variant: 'danger',
  },
  warning: {
    icon: 'fa solid fa-circle-exclamation',
    iconColor: TRAFFIC_LIGHT_AMBER,
    variant: 'warning',
  },
  info: {
    icon: 'fa solid fa-circle-question',
    iconColor: GREY_COLOR,
    variant: 'gray',
  },
};

export const BasketStatusStyleConfigMap: Record<BasketStatusConstants, BasketStatusStyle> = {
  DRAFT: {
    statusColor: GREY_COLOR,
  },
  PENDING: {
    statusColor: TRAFFIC_LIGHT_AMBER,
  },
  REJECTED: {
    statusColor: RED_COLOR,
  },
  APPROVED: {
    statusColor: TRAFFIC_LIGHT_GREEN,
  },
  LIVE: {
    statusColor: PACIFIC_BLUE,
  },
  ACKNOWLEDGED: {
    statusColor: TRAFFIC_LIGHT_GREEN,
  },
};
