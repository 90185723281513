<!-- eslint-disable vue/no-lone-template -->
<template>
  <div>
    <b-row v-if="isPdf">
      <b-col>
        <span
          class="w-100 d-flex justify-content-between align-items-center"
          :style="sectionHeaderTextColor"
        >
          <slot name="pdf-title-content">
            <component
              :is="pdfHeaderTagToUse"
              class="mb-0"
            >
              {{ title }}
            </component>
          </slot>
          <slot name="pdf-right-side-content">
            <span
              v-if="rightSideText"
              style="font-variant: small-caps"
            >
              {{ rightSideText }}
            </span>
          </slot>
        </span>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <slot name="title-area-content">
          <div class="d-flex justify-content-between align-items-center settings-button-container">
            <component
              :is="headerTag"
              class="d-inline-block mb-0 font-size-bigger font-weight-500"
            >
              {{ title }}
            </component>
            <div
              class="d-flex justify-content-end align-items-center"
              :style="shouldHaveRightPadding ? 'padding-right: 45px' : ''"
            >
              <slot name="right-side-content">
                <span
                  v-if="rightSideText"
                  style="font-variant: small-caps"
                >
                  {{ rightSideText }}
                </span>
              </slot>
              <SettingsButton
                v-if="allCols.length > 0 || $slots['settings-menu']"
                :all-options="allCols"
                :selected-options.sync="displayedColsModel"
                class="ml-3"
                menu-class="scroll-lg shadow"
              >
                <template #header>
                  <slot name="dropdown-header" />
                </template>
                <template>
                  <slot name="settings-menu" />
                </template>
              </SettingsButton>
            </div>
          </div>
        </slot>
      </b-col>
    </b-row>
    <hr
      v-if="shouldShowHr"
      :class="hrClasses"
      :style="hrStyle"
    />
  </div>
</template>

<script lang="ts">
import { useIsPdf, usePdfPreferences } from '@/composables/usePdf';
import { computed, defineComponent, PropType } from 'vue';
import SettingsButton from '@/components/standard-components/StandardSettingsButton.vue';
import { useVModel } from '@vueuse/core';
import useEnv from '@/composables/useEnv';

export default defineComponent({
  name: 'SectionTitle',
  components: {
    SettingsButton,
  },
  props: {
    /**
     * `title` should always be supplied unless you are using the #title-area-content slot
     * Even if you DO use #title-area-content slot, you must supply a title for PDF!!!
     */
    title: {
      type: String,
      required: true,
    },
    headerTag: {
      type: String,
      required: false,
      default: 'label',
    },
    pdfHeaderTag: {
      type: String,
      required: false,
      default: 'h4',
    },
    rightSideText: {
      type: String,
      required: false,
      default: '',
    },
    hrClass: {
      type: String,
      required: false,
      default: '',
    },
    shouldShowHr: {
      type: Boolean,
      required: false,
      default: true,
    },
    allCols: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    displayedCols: {
      type: Array,
      required: false,
      default: () => [],
    },
    includeMarginBottom: {
      type: Boolean,
      required: false,
      default: true,
    },
    /**
     * Indicate if right padding should be added for export graph button
     */
    shouldHaveRightPadding: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * This prop is needed for common components shared by HSBC which require disclaimerText to be passed to SectionTitle.vue
     */
    disclaimerText: {
      type: [String, Array] as PropType<string | string[]>,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const displayedColsModel = useVModel(props, 'displayedCols', emit);

    const pdfHeaderTagToUse = computed(() => {
      if (props.pdfHeaderTag) return props.pdfHeaderTag;
      return props.headerTag;
    });

    const { isBarclaysEnvironment, isBofAEnvironment } = useEnv();

    const hrClasses = computed(() => {
      let base = `mt-1 ${props.includeMarginBottom ? 'mb-4' : 'mb-0'}`;

      // no HSBC because it uses its own SectionTitle component
      if (isBarclaysEnvironment) {
        base += ' hr-accent';
      }

      if (isBofAEnvironment) {
        base += ' hr-info';
      }

      if (props.hrClass) {
        return `${base} ${props.hrClass}`;
      }
      return base;
    });

    const isPdf = useIsPdf();
    const { sectionDividerColor, sectionHeaderTextColor } = usePdfPreferences();

    const hrStyle = computed(() => {
      const base = {
        'border-width': '2px',
      };

      if (props.hrClass) {
        return base;
      }

      return {
        ...base,
        'border-color': isBofAEnvironment ? undefined : sectionDividerColor.value,
      };
    });

    return {
      isPdf,
      sectionHeaderTextColor,
      sectionDividerColor,
      displayedColsModel,
      pdfHeaderTagToUse,
      hrClasses,
      hrStyle,
    };
  },
});
</script>
