import { ref, computed, watch, Ref } from 'vue';
import { Serializer, useStorage } from '@vueuse/core';
import { LocalStorageEntities } from '@/constants/LocalStorageEntities';
import { IFilter } from '@/types/IFilter';
import { IStrategySubset, IPortfolioSubset, ColumnByTab, SupportedColumn, ISignal } from '@/types/IndexOptionsTable';
import { ATTRIBUTE_DATABASE_NAME } from '@/types/strategy';
import { DataTypes } from '@/types/setting';

const defaultSelectedColumns = (): ColumnByTab => ({
  [DataTypes.BENCHMARK]: [
    ATTRIBUTE_DATABASE_NAME.AssetClass,
    ATTRIBUTE_DATABASE_NAME.Region,
    ATTRIBUTE_DATABASE_NAME.ReturnType,
  ],
  [DataTypes.PURE_FACTOR]: [
    ATTRIBUTE_DATABASE_NAME.AssetClass,
    ATTRIBUTE_DATABASE_NAME.Region,
    ATTRIBUTE_DATABASE_NAME.Factor,
  ],
  [DataTypes.STRATEGY]: [
    ATTRIBUTE_DATABASE_NAME.AssetClass,
    ATTRIBUTE_DATABASE_NAME.Factor,
    ATTRIBUTE_DATABASE_NAME.Style,
  ],
  [DataTypes.PRIVATE_STRATEGY]: [
    ATTRIBUTE_DATABASE_NAME.AssetClass,
    ATTRIBUTE_DATABASE_NAME.Factor,
    ATTRIBUTE_DATABASE_NAME.Style,
  ],

  [DataTypes.PRIVATE_TRACK]: [
    ATTRIBUTE_DATABASE_NAME.AssetClass,
    ATTRIBUTE_DATABASE_NAME.Region,
    ATTRIBUTE_DATABASE_NAME.Factor,
  ],
  [DataTypes.FUND]: [
    ATTRIBUTE_DATABASE_NAME.AssetClass,
    ATTRIBUTE_DATABASE_NAME.Region,
    ATTRIBUTE_DATABASE_NAME.Currency,
  ],
  [DataTypes.PORTFOLIO]: [
    ATTRIBUTE_DATABASE_NAME.AssetClass,
    ATTRIBUTE_DATABASE_NAME.Region,
    ATTRIBUTE_DATABASE_NAME.Factor,
  ],
  [DataTypes.THEMATIC]: [
    ATTRIBUTE_DATABASE_NAME.AssetClass,
    ATTRIBUTE_DATABASE_NAME.Factor,
    ATTRIBUTE_DATABASE_NAME.Style,
  ],
  [DataTypes.PRIVATE_THEMATIC]: [
    ATTRIBUTE_DATABASE_NAME.AssetClass,
    ATTRIBUTE_DATABASE_NAME.Factor,
    ATTRIBUTE_DATABASE_NAME.Style,
  ],
  [DataTypes.MARKET_DATA]: [
    ATTRIBUTE_DATABASE_NAME.AssetClass,
    ATTRIBUTE_DATABASE_NAME.Factor,
    ATTRIBUTE_DATABASE_NAME.Style,
  ],
  [DataTypes.STOCK]: [ATTRIBUTE_DATABASE_NAME.Sector, ATTRIBUTE_DATABASE_NAME.Ticker, ATTRIBUTE_DATABASE_NAME.Region],
  // There is no columns to display for signals. We can safely use an empty
  // array here.
  [DataTypes.SIGNAL]: [],
  // There is no columns to display for basket (for now). We can safely use an empty array here.
  [DataTypes.EQUITY_BASKET]: [],

  [DataTypes.QIS_BASKET]: [],
});

const serializer: Serializer<ColumnByTab> = {
  read(raw: string): ColumnByTab {
    return {
      // Ensure we use default for new tabs, if we add any.
      ...defaultSelectedColumns(),
      ...JSON.parse(raw),
    };
  },
  write(value: ColumnByTab): string {
    return JSON.stringify(value);
  },
};

function useColumnStorage() {
  return useStorage<ColumnByTab>(LocalStorageEntities.DSBColumns, defaultSelectedColumns(), undefined, {
    serializer,
  });
}

export default function useDSBColumns(tabModel: Ref<DataTypes>, activeFilters: Ref<Array<IFilter<string>>>) {
  const filteredOptions = ref<readonly (IStrategySubset | IPortfolioSubset | ISignal)[]>([]);

  const userSelectedColumns = useColumnStorage();

  const columnsToShow = computed((): SupportedColumn[] => {
    return userSelectedColumns.value[tabModel.value];
  });

  // Remove the filter columns when user deselect a column.
  // Watch on userSelectedColumns instead of columnsToShow so that we don't react to tab change.
  watch(userSelectedColumns, (nextVal) => {
    const shownColumns = new Set<string>(Object.values(nextVal).flatMap((col) => col));
    activeFilters.value = activeFilters.value.filter((f) => shownColumns.has(f.property));
  });

  return {
    userSelectedColumns,
    columnsToShow,
    filteredOptions,
  };
}
