import useEnv from '@/composables/useEnv';
import { AssetClassConstants } from '@/constants/AssetClassConstants';
import { TimeSeriesEntityTypeConstants } from '@/constants/TimeSeriesEntityTypeConstants';
import { TrackTypeConstants } from '@/constants/TrackTypeConstants';

export enum DataTypes {
  BENCHMARK = 'BENCHMARK',
  PURE_FACTOR = 'PURE_FACTOR',
  STRATEGY = 'STRATEGY',
  PRIVATE_STRATEGY = 'PRIVATE_STRATEGY',
  FUND = 'FUND',
  THEMATIC = 'THEMATIC',
  PRIVATE_THEMATIC = 'PRIVATE_THEMATIC',
  PRIVATE_TRACK = 'PRIVATE_TRACK',
  PORTFOLIO = 'PORTFOLIO',
  SIGNAL = 'SIGNAL',
  MARKET_DATA = 'MARKET_DATA',
  STOCK = 'STOCK',
  EQUITY_BASKET = 'EQUITY_BASKET',
  QIS_BASKET = 'QIS_BASKET',
}

export type StrategyLikeObject = {
  type: TimeSeriesEntityTypeConstants;
  trackType: TrackTypeConstants;
  assetClass: AssetClassConstants;
};

// The display type depends on both the type and the trackType property.
// We use the display type for client display only. For admin console, we display the original type.
// Users will be able to customize the display type for their own private tracks.
export const normalizeType = (o: StrategyLikeObject): DataTypes => {
  const { isBofAEnvironment } = useEnv();
  if (o.trackType === TrackTypeConstants.PRIVATE_TRACK || o.trackType === TrackTypeConstants.PROXY) {
    if (o.type === null || o.type === TimeSeriesEntityTypeConstants.PRIVATE_STRATEGY) {
      return DataTypes.PRIVATE_TRACK;
    }
  }

  // In BofA environment, we are separating the thematic and strategy types according to their visibility.
  if (isBofAEnvironment) {
    switch (o.type) {
      case TimeSeriesEntityTypeConstants.STRATEGY:
        return DataTypes.STRATEGY;
      case TimeSeriesEntityTypeConstants.PRIVATE_STRATEGY:
        return DataTypes.PRIVATE_STRATEGY;
      case TimeSeriesEntityTypeConstants.THEMATIC:
        return DataTypes.PRIVATE_THEMATIC;
      case TimeSeriesEntityTypeConstants.PUBLIC_THEMATIC:
        return DataTypes.THEMATIC;
      case TimeSeriesEntityTypeConstants.PURE_FACTOR:
        return DataTypes.PURE_FACTOR;
      case TimeSeriesEntityTypeConstants.BENCHMARK:
        return DataTypes.BENCHMARK;
      case TimeSeriesEntityTypeConstants.FUND:
        return DataTypes.FUND;
      case TimeSeriesEntityTypeConstants.SIGNAL:
        return DataTypes.SIGNAL;
      case TimeSeriesEntityTypeConstants.MARKET_DATA:
        return DataTypes.MARKET_DATA;
      case TimeSeriesEntityTypeConstants.STOCK:
        return DataTypes.STOCK;
      default:
        throw new Error('Unreachable: Unrecognized strategy');
    }
  }

  switch (o.type) {
    case TimeSeriesEntityTypeConstants.STRATEGY:
    case TimeSeriesEntityTypeConstants.PRIVATE_STRATEGY:
      return DataTypes.STRATEGY;
    case TimeSeriesEntityTypeConstants.THEMATIC:
    case TimeSeriesEntityTypeConstants.PUBLIC_THEMATIC:
    case TimeSeriesEntityTypeConstants.THEMATIC_BENCHMARK:
      return DataTypes.THEMATIC;
    case TimeSeriesEntityTypeConstants.PURE_FACTOR:
      return DataTypes.PURE_FACTOR;
    case TimeSeriesEntityTypeConstants.BENCHMARK:
      return DataTypes.BENCHMARK;
    case TimeSeriesEntityTypeConstants.FUND:
      return DataTypes.FUND;
    case TimeSeriesEntityTypeConstants.SIGNAL:
      return DataTypes.SIGNAL;
    case TimeSeriesEntityTypeConstants.MARKET_DATA:
      return DataTypes.MARKET_DATA;
    case TimeSeriesEntityTypeConstants.STOCK:
      return DataTypes.STOCK;
    default:
      throw new Error('Unreachable: Unrecognized strategy');
  }
};

// TODO: WAA-10222 - Refactor normalizePortfolioType so it support basket.
export const normalizePortfolioType = (o: { isBenchmark: boolean }): DataTypes => {
  return o.isBenchmark ? DataTypes.BENCHMARK : DataTypes.PORTFOLIO;
};

export interface IColors {
  [aggregationField: string]: { [key: string]: string } | undefined;
}

export interface ISettingRecord {
  id: number;
  key: string;
  value: string;
  aggregationField: string;
}
